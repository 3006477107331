<template>
  <div class="trackSource">
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="filter" label-width="80px" size="mini">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :span="6" style="height: 34px;">
              <el-form-item label="Date:" label-width="40px" class="mb5">
                <el-date-picker
                  v-model="filter.date"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                  class="w100"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" style="height: 34px;">
              <el-form-item label="Interval:" class="mb5">
                <el-checkbox-group v-model="filter.columns">
                  <el-checkbox label="date">Date</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Offer(s):" label-width="60px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input
                      v-model="filter.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="offer_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Affiliate(s):" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <group-select
                      v-model="filter.affiliateIds"
                      :data-source="affiliatesGroupList"
                      class="w100"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      collapse-tags
                      placeholder="Please select Affiliate"
                    />
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      style="color:#fff"
                      label="affiliate_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Source(s):" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <group-select
                      v-model="filter.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      reserve-keyword
                      clearable
                      filterable
                      collapse-tags
                      placeholder="Please select Source"
                      class="w100"
                    />
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="source_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Countries(s):" label-width="90px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input v-model="filter.countries" placeholder="eg. US,IT"></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox v-model="filterCheckedStatus" label="country" class="color-white"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="clickSourceApp(s):" label-width="120px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input
                      v-model.trim="filter.clickSourceApps"
                      placeholder="eg. US,IT"
                    ></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox
                      v-model="filterCheckedStatus"
                      label="click_source_app"
                      class="color-white"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Package(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input
                      v-model.trim="filter.pkgs"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="prod"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="appCategory" label-width="100px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-select
                      v-model="filter.appCategoryList"
                      filterable
                      clearable
                      multiple
                      collapse-tags
                      :reserve-keyword="true"
                      placeholder="Please select"
                      class="w100"
                      :filter-method="dataFilter"
                      @change="handleChange"
                    >
                      <el-checkbox
                        class="ml15"
                        v-model="appCategoryChecked"
                        @change="selectAll($event)"
                        >All</el-checkbox
                      >
                      <el-option
                        v-for="item in catOptions"
                        :key="item.id"
                        :value="item.cat"
                        :label="item.cat"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      label="app_category"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Platform(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-select v-model="filter.platform" placeholder="Please select" class="w100">
                      <el-option
                        v-for="item in options.platform"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="platform"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="adx(s):" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <group-select
                      v-model="filter.adxs"
                      :data-source="options.adxOptions"
                      multiple
                      reserve-keyword
                      collapse-tags
                      clearable
                      filterable
                      placeholder="Please select"
                      class="w100"
                    />
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="adx"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="osVersion" label-width="90px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input v-model="filter.osVersion" placeholder="eg. US,IT"></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox
                      v-model="filterCheckedStatus"
                      label="os_version"
                      class="color-white"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="sourceManagers(s):" label-width="130px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-select
                      v-model="filter.sourceManagers"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option
                        v-for="item in options.sourceManagers"
                        :key="item.username"
                        :label="item.username"
                        :value="item.username"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      label="source_manager"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Pid(s):" label-width="50px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="20">
                    <el-select
                      v-model="filter.pids"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option
                        v-for="item in options.pidList"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="pid"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="dataSource:" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="19" :lg="19" :xl="19">
                    <el-select
                      v-model="filter.dataSourceIds"
                      placeholder="Please select"
                      multiple
                      filterable
                      collapse-tags
                      clearable
                      class="w100"
                    >
                      <el-option
                        v-for="item in dataSourceList"
                        :key="item.data_source_id"
                        :label="item.data_source_name"
                        :value="item.data_source_id"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="4" align="center">
                    <el-popover
                      placement="right"
                      width="400"
                      v-model="dataSourceFilter"
                      trigger="click"
                    >
                      <svg-icon
                        icon-class="filter"
                        slot="reference"
                        class="mr10 mt10 font-16 cor999"
                      />
                      <el-form
                        ref="dataSourceFilterForm"
                        :model="dataSourceFilterForm"
                        label-width="70px"
                        size="mini"
                      >
                        <el-form-item label="Country" prop="country" class="mb10">
                          <el-select
                            v-model="dataSourceFilterForm.country"
                            size="small"
                            placeholder="Please select"
                            style="width: 100%"
                            filterable
                          >
                            <el-option
                              v-for="item in options.country"
                              :key="item.countryCode"
                              :label="item.countryCode"
                              :value="item.countryCode"
                            />
                          </el-select>
                        </el-form-item>
                        <el-form-item label="OS" prop="os" class="mb10">
                          <el-select
                            v-model="dataSourceFilterForm.os"
                            size="small"
                            placeholder="Please select"
                            style="width: 100%"
                          >
                            <el-option label="IOS" value="ios" />
                            <el-option label="Android" value="android" />
                          </el-select>
                        </el-form-item>
                        <el-form-item class="mb10" align="right">
                          <el-button
                            @click="
                              dataSourceFilterForm.country = '';
                              dataSourceFilterForm.os = '';
                              getDataSourceList();
                            "
                            >重置
                          </el-button>
                          <el-button type="primary" @click="dataSourceFilterSubmit">提交</el-button>
                        </el-form-item>
                      </el-form>
                    </el-popover>
                    <el-checkbox
                      v-model="filterCheckedStatus"
                      label="data_source_id"
                      class="color-white"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="24" align="left">
              <el-form-item label="Group by" label-width="90px" class="mb5">
                <el-col :lg="20" :md="20">
                  <el-checkbox v-model="filterCheckedStatus" label="event_name"></el-checkbox>
                </el-col>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right" v-if="showPkgsFlag">
              <el-form-item label="filter data:" label-width="190px" class="mb0">
                <el-card shadow="never" class="mb10">
                  <el-row :gutter="10" v-for="(item, index) in filter.rejectionBeans" :key="index">
                    <el-col :span="5">
                      <el-form-item label="" label-width="0px" class="mb0">
                        <el-select
                          v-model="item.type"
                          placeholder="Please rejection Reason"
                          size="mini"
                          style="width: 100%;"
                        >
                          <el-option
                            v-for="item in options.dataChecksKey"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item label="" label-width="0px" class="mb0">
                        <el-select
                          v-model="item.operator"
                          placeholder="Please rejection Reason"
                          size="mini"
                          style="width: 100%;"
                        >
                          <el-option
                            v-for="item in options.dataCheckOperator"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item label="" label-width="0px" class="mb0">
                        <el-input
                          v-model="item.number"
                          @input="validateInput(item.number)"
                          placeholder="请输入数字"
                        />
                        <span v-if="errorMessage" style="color: red;">{{ errorMessage }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-form-item label="" label-width="0px" class="mb0">
                        <el-button
                          type="primary"
                          size="mini"
                          v-if="index == 0"
                          @click="dataCheckAdd"
                          >add
                        </el-button>
                        <el-button
                          type="danger"
                          size="mini"
                          v-if="index !== 0"
                          @click="dataCheckDel(index)"
                          >delete
                        </el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-card>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="0px" class="mb0">
                <el-button
                  type="primary"
                  :loading="loading.search"
                  @click="filterDataSet('Search', 1)"
                  >Search
                </el-button>
                <el-button type="primary" @click="filterDataSet('Download')">Download</el-button>
                <el-button type="primary" @click="showPkgsFlag = true">openFilterData</el-button>
                <el-button type="warning" @click="showPkgsFlag = false">closeFilterData</el-button>
                <el-button type="success" @click="dialogVisible = true" v-if="showPkgsFlag"
                  >showPkgs
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="trackSourceTableRef"
          style="width:100%;"
          size="mini"
          border
          highlight-current-row
          :row-class-name="tableRowClassName"
          @sort-change="sortChange"
        >
          <el-table-column
            label="date"
            prop="date"
            show-overflow-tooltip
            sortable="custom"
            v-if="checkDate('date') || checkDate('hour')"
          ></el-table-column>
          <el-table-column
            v-if="checkIn('offer_id')"
            label="offerId "
            prop="offerId"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('affiliate_id')"
            label="affiliateId "
            prop="affiliateId"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('affiliate_id')"
            label="affiliateName "
            prop="company"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="clicks"
            prop="clickCount"
            show-overflow-tooltip
            sortable="custom"
          ></el-table-column>
          <el-table-column label="cvr" prop="cvr" show-overflow-tooltip sortable="custom">
            <template #default="scope">
              {{ computeRate(scope.row.install, scope.row.clickCount) }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="checkIn('prod')"
            label="pkg"
            prop="pkg"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('app_category')"
            label="appCategory"
            prop="appCategory"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="install"
            prop="install"
            show-overflow-tooltip
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="events"
            prop="events"
            show-overflow-tooltip
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="rejectCount"
            prop="rejectionTotal"
            show-overflow-tooltip
            sortable="custom"
          ></el-table-column>
          <el-table-column label="evr" prop="evr" show-overflow-tooltip sortable="custom">
            <template #default="scope">
              {{ computeRate(scope.row.events, scope.row.install) }}
            </template>
          </el-table-column>
          <el-table-column label="cer" prop="cer" show-overflow-tooltip sortable="custom">
            <template #default="scope">
              {{ computeRate(scope.row.events, scope.row.clickCount) }}
            </template>
          </el-table-column>
          <el-table-column
            label="clickSourceApp"
            prop="clickSourceApp"
            show-overflow-tooltip
            v-if="checkIn('click_source_app')"
          ></el-table-column>
          <el-table-column
            v-if="checkIn('country')"
            label="country"
            prop="country"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('source_id')"
            label="source"
            prop="sourceId"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('event_name')"
            label="EventName"
            prop="eventName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('adx')"
            label="adx"
            prop="adx"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('os_version')"
            label="osVersion"
            prop="osVersion"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('platform')"
            label="Platform"
            prop="platform"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('source_manager')"
            label="sourceManager"
            prop="sourceManager"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('pid')"
            label="pid"
            prop="pid"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="checkIn('data_source_id')"
            label="dataSourceName"
            prop="dataSourceName"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="pages.total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pages.pageParam.pageSize"
          :currentPage="pages.pageParam.page"
        ></pagination>
        <el-dialog title="筛选出来的 pkgList" :visible.sync="dialogVisible" width="50%">
          <el-input id="selectPkgList" autosize v-model="pkgList" type="textarea"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="copyToClipboard">复制</el-button>
            <!--    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
          </span>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
  import TrackSourceCon from '../controllers/report/trackSource';

  export default {
    name: 'TrackSource',
    ...TrackSourceCon,
  };
</script>

<style lang="scss" scoped></style>
