import { fetchPage } from 'api/finance/summary';
import {
  getPermissionAllList,
  selectGroupBusiness,
  selectGroupAccount,
} from 'api/account/permission';
import { mapState, mapActions } from 'vuex';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { getPidsList } from 'api/product/rankSearch';
import { filterObject } from 'utils/utils';
import { selectContractType } from 'api/finance/contract';
import qs from 'querystring';
import { selectAdvLegalNameAll } from '@/api/finance/contract';

export default {
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      accounts: [],
      filterForm: {
        dateRange: [],
        page: 1,
        pageSize: 20,
      },
      contracts: [],
      total: 0,
      postData: {},
      list: [],
      loading: {
        list: false,
        submitBtn: false,
      },
      mmpRevenue: 0,
      operationFinance: {
        id: 0,
        sourceName: '',
        eventName: '',
        payout: '',
        eventCount: '',
      },
      dialog: {
        visible: false,
        title: '编辑',
        type: '',
      },
      addDialog: {
        visible: false,
        title: '新增',
        type: '',
      },
      eventCountVisible: false,
      eventCount: '',
      multipleSelection: [],
      copyArr: [],
      rules: {
        payout: [{ required: true, message: 'revenue 不能为空' }],
        eventName: [{ required: true, message: 'eventName不能为空' }],
        eventCount: [{ required: true, message: 'eventCount不能为空' }],
        sourceName: [{ required: true, message: 'sourceName不能为空' }],
        operation: [{ required: true, message: 'operation不能为空' }],
        business: [{ required: true, message: 'business不能为空' }],
        sourceId: [{ required: true, message: 'sourceId不能为空' }],
        prod: [{ required: true, message: 'prod不能为空' }],
        offerId: [{ required: true, message: 'offerId不能为空' }],
        offerName: [{ required: true, message: 'offerName不能为空' }],
        pid: [{ required: true, message: 'pid不能为空' }],
        offerType: [{ required: true, message: 'offerType不能为空' }],
        bidRevenue: [{ required: true, message: 'bidRevenue不能为空' }],
        mmpRevenue: [{ required: true, message: 'mmpRevenue不能为空' }],
        financeCycle: [{ required: true, message: 'financeCycle不能为空' }],
      },
      contractData: {
        operation: '',
        business: '',
        sourceId: '',
        sourceName: '',
        prod: '',
        offerId: '',
        offerName: '',
        eventName: '',
        payout: '',
        pid: '',
        offerType: '',
        bidRevenue: '',
        eventCount: '',
        mmpRevenue: '',
        financeCycle: '',
      },
      sumInfo: {
        bidRevenue: 0,
        mmpRevenue: 0,
        bidCount: 0,
        mmpCount: 0,
        revenueRatio: 0,
        countRatio: 0,
      },
      isConfirmed: false,
      statusList: [
        {
          label: 'created',
          value: '0',
        },
        {
          label: 'confirm',
          value: '1',
        },
        {
          label: 'AM confirm',
          value: '2',
        },
      ],
      businessTypes: [
        {
          label: 'overseas',
          value: 'overseas',
        },
        {
          label: 'domestic',
          value: 'domestic',
        },
      ],
      bidAccountList: [],
      pidList: [],
      busineies: [],
      advLegalNameList: [],
    };
  },
  mounted() {
    this.getDefaultTime();
    this.getList();
    this.getManagers();
    this.getSourceIdsList();
    this.getPids();
    this.getBusiness();
    this.getAccounts();
    this.getContractType();
    this.getAdvLegalNameList();
  },
  methods: {
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    getAccounts() {
      selectGroupAccount().then((res) => {
        this.accounts = res.result;
      });
    },
    getAdvLegalNameList() {
      selectAdvLegalNameAll().then((res) => {
        this.advLegalNameList = res.result;
      });
    },
    getContractType() {
      selectContractType().then((res) => {
        if (res.success === true) {
          this.contracts = res.result['Subject of contract'];
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getDefaultTime() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 2); // 设置日期为上上月
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const formattedMonth = month < 10 ? `0${month}` : month;
      let ddr = `${year}-${formattedMonth}`;
      this.filterForm.dateRange = [ddr, ddr]; // 设置默认起始日期
    },
    getPids() {
      getPidsList().then((res) => {
        this.pidList = res.result;
      });
    },
    getBusiness() {
      selectGroupBusiness().then((res) => {
        // let values = res.result.map((item) => item.username);
        this.busineies = res.result;
      });
    },
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      let param = { ...this.filterForm };
      let rangeTime = this.filterForm.dateRange;
      if (Array.isArray(rangeTime) && rangeTime.length > 0) {
        param.startTime = this.filterForm.dateRange[0];
        param.endTime = this.filterForm.dateRange[1];
        // 删除多余的属性
        delete param.dateRange;
      }
      if (this.filterForm.sourceIdList) {
        param.sourceIdList = this.filterForm.sourceIdList.toString();
      }
      if (this.filterForm.pids) {
        param.pids = this.filterForm.pids.toString();
      }
      if (this.filterForm.businessList) {
        param.businessList = this.filterForm.businessList.toString();
      }
      this.loading.list = true;
      fetchPage(param)
        .then((res) => {
          if (res.code === 200) {
            this.sumInfo = res.result;
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 下载excel表格
    downloadFie() {
      this.$confirm('最多下载10万条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let param = { ...this.filterForm };
          let rangeTime = this.filterForm.dateRange;
          if (Array.isArray(rangeTime) && rangeTime.length > 0) {
            param.startTime = this.filterForm.dateRange[0];
            param.endTime = this.filterForm.dateRange[1];
            // 删除多余的属性
            delete param.dateRange;
          }
          if (this.filterForm.sourceIdList) {
            param.sourceIdList = this.filterForm.sourceIdList.toString();
          }
          if (this.filterForm.businessList) {
            param.businessList = this.filterForm.businessList.toString();
          }
          param = filterObject(param);
          let strParam = qs.stringify(param);
          let url = '/api/finance/summary/download?' + strParam;
          console.log(url);
          window.open(url, '_blank');
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: error.message,
          });
        });
    },
    // 打开弹窗
    openDialog(row) {
      this.operationFinance.id = row.id;
      this.operationFinance.sourceName = row.sourceName;
      this.operationFinance.payout = row.payout;
      this.operationFinance.eventName = row.eventName;
      this.operationFinance.eventCount = row.eventCount;
      this.dialog.visible = true;
    },
    // 打开弹窗
    openAddDialog() {
      this.addDialog.visible = true;
      if (this.$refs['addForm'] !== undefined) {
        this.$refs['addForm'].resetFields();
      }
    },

    handleEventCountDialog(row) {
      this.operationFinance.id = row.id;
      this.operationFinance.sourceName = row.sourceName;
      this.operationFinance.payout = row.payout;
      this.operationFinance.eventName = row.eventName;
      this.eventCount = row.eventCount;
      this.eventCountVisible = true;
    },

    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },

    handleConfirmStatus(row) {
      let statusNum = row.status;
      if (statusNum === '0') {
        return 'warning';
      }
      if (statusNum === '1') {
        return 'success';
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.copyArr = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        this.copyArr.push(this.multipleSelection[i].id);
      }
      console.log(this.copyArr.toString());
    },
    handleSourceChange(val) {
      for (var i = 0; i < this.sourcesList.length; i++) {
        for (var j = 0; j < this.sourcesList[i].children.length; j++) {
          if (this.sourcesList[i].children[j].value == val) {
            this.contractData.sourceName = this.sourcesList[i].children[j].company;
          }
        }
      }
    },
    getManagers() {
      getPermissionAllList().then((res) => {
        let values = res.result.map((item) => item.username);
        this.bidAccountList = values;
      });
    },
    // 排序监听
    // 排序监听
    sortChange(column) {
      if (column.prop !== null && column.order !== null) {
        this.filterForm.sorting = column.prop;
        this.filterForm.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      }
      this.getList();
    },
    formatValue(val) {
      if (val === '0') {
        return 'create';
      } else if (val === '1') {
        return 'confirm';
      } else if (val === '2') {
        return 'AM confirm';
      } else {
        return val;
      }
    },
    formatOperationTime(row) {
      if (row.updateTime) {
        return row.updateTime;
      } else {
        return row.createTime;
      }
    },
    idDisableFun(val) {
      return !(val === '0');
    },
    computeRevenue() {
      if (this.sumInfo.bidRevenue === 0) {
        return 0;
      } else {
        let percent = Math.floor((this.sumInfo.mmpRevenue / this.sumInfo.bidRevenue) * 100);
        if (percent > 100) {
          percent = 100;
        }
        return percent;
      }
    },
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    formatDate() {
      return (date) => {
        if (!date) {
          return ''; // 如果date为null或undefined，则返回空字符串
        }
        const d = new Date(date);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d
          .getDate()
          .toString()
          .padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    },
  },
};
