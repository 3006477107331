<template>
  <div>
    <div id="add-tags-content" v-html="content"></div>
    <el-form :model="tags" label-width="90px" size="mini">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="Tags Label">
            <el-select
              v-model="tags.tagsLabel"
              @visible-change="showtype($event)"
              @change="tagsLabelChange($event)"
              class="w100"
            >
              <el-option
                v-for="item in tagsOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item v-if="tags.tagsLabel == 'numberOfJumps'" label-width="0px">
            <el-select v-model="numberOfJumps" class="w100">
              <el-option
                v-for="item in compateOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Tags Value">
            <el-select
              v-if="
                tags.tagsLabel == 'market' ||
                  tags.tagsLabel == 'offerNameIden' ||
                  tags.tagsLabel == 'platforms' ||
                  tags.tagsLabel == 'incentive' ||
                  tags.tagsLabel == 'prodType' ||
                  tags.tagsLabel == 'deviceIdNeeded'
              "
              class="w100"
              v-model="tags.tagsValue"
            >
              <el-option
                v-for="item in typeArray"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
            <el-input
              v-else
              v-model="tags.tagsValue"
              placeholder="offerName here,e.g:1,2,3"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item label-width="0px">
            <el-button type="primary" class="update" @click="updateTagsClick"
              >Update Tags</el-button
            >
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="Description">
            <el-input v-model="tags.description"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item label-width="0px">
            <el-button type="primary" class="add" @click="addTagsClick">Add</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      v-if="tagsWhiteList.length > 0"
      :data="tagsWhiteList"
      style="width:100%;"
      size="mini"
      border
      stripe
      highlight-current-row
    >
      <el-table-column label="Tags" prop="tags" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-html="tagHtml(scope.row.tags)"></div>
        </template>
      </el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="CreateTime" prop="createTime" align="center"></el-table-column>
      <el-table-column label="Action" width="300" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            :loading="scope.row.id == targetId ? true : false"
            @click="getTagsMatchs(scope.row)"
            size="mini"
            >Check Matchs</el-button
          >
          <el-button size="mini" type="primary" @click="deleteTags(scope.row)">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delTagsWhitelistVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delTagsWhitelistVisible = false">cancel</el-button>
        <el-button type="primary" @click="delTagsWhitelistbRow">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getTagsWhitelist,
    addTagsWhitelist,
    deleteTagsWhitelist,
    getTagsList,
    checkoutCount,
  } from 'api/affiliate/detail';
  import Pagination from '@/components/pagination';
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        content: '',
        tags: {
          tagsLabel: 'offerNames',
          tagsValue: '',
          description: null,
        },
        tagsOptions: [],
        tagsMarket: [
          {
            label: 'GP',
            value: 'GP',
          },
          {
            label: 'ITUNES',
            value: 'ITUNES',
          },
        ],
        tagsOfferNameIden: [
          {
            label: 'CPE',
            value: 'CPE',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
        ],
        tagsPlatforms: [
          {
            label: 'IOS',
            value: 'IOS',
          },
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
        ],
        tagsIncentive: [
          {
            label: 'incentive',
            value: '1',
          },
          {
            label: 'none incentive',
            value: '0',
          },
        ],
        tagsProdType: [
          {
            label: 'APP',
            value: 'APP',
          },
          {
            label: 'OPTION',
            value: 'OPTION',
          },
        ],
        tagsDeviceIdNeeded: [
          {
            label: 'YES',
            value: 'YES',
          },
          {
            label: 'NO',
            value: 'NO',
          },
        ],
        numberOfJumps: '',
        compateOption: [
          {
            label: '>',
            value: '>',
          },
          {
            label: '<=',
            value: '<=',
          },
          {
            label: 'between',
            value: 'between',
          },
        ],
        typeArray: [],
        tagsWhiteList: [],
        targetId: null,
        allArray: [],
        delTagsWhitelistVisible: false,
        total: 0,
        currentPage: 1,
        pageSize: 20,
      };
    },
    mounted() {
      this.getTagsList();
      this.getTagsWhitelist();
    },
    methods: {
      getTagsList() {
        getTagsList().then((response) => {
          if (response.code === 200) {
            this.tagsOptions = response.result || [];
          } else {
            this.tagsOptions = [];
          }
        });
      },
      getTagsWhitelist() {
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getTagsWhitelist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.tagsWhiteList = response.result || [];
            this.total = response.total;
          } else {
            this.tagsWhiteList = [];
            this.total = 0;
          }
        });
      },
      showtype() {
        if (this.tags.tagsLabel == 'market') {
          this.typeArray = this.tagsMarket;
        } else if (this.tags.tagsLabel == 'offerNameIden') {
          this.typeArray = this.tagsOfferNameIden;
        } else if (this.tags.tagsLabel == 'platforms') {
          this.typeArray = this.tagsPlatforms;
        } else if (this.tags.tagsLabel == 'incentive') {
          this.typeArray = this.tagsIncentive;
        } else if (this.tags.tagsLabel == 'prodType') {
          this.typeArray = this.tagsProdType;
        } else if (this.tags.tagsLabel == 'deviceIdNeeded') {
          this.typeArray = this.tagsDeviceIdNeeded;
        }
      },
      tagsLabelChange(callback) {
        this.callback = callback;
      },
      addTagsClick() {
        for (let i = 0; i < this.allArray.length; i++) {
          if (
            Object.prototype.hasOwnProperty.call(this.allArray, 'topOfferSize') == -1 &&
            Object.prototype.hasOwnProperty.call(this.allArray, 'offerNames') == -1 &&
            Object.prototype.hasOwnProperty.call(this.allArray, 'prodNames') == -1 &&
            Object.prototype.hasOwnProperty.call(this.allArray, 'sourceId') == -1 &&
            Object.prototype.hasOwnProperty.call(this.allArray, 'sourceTags') == -1
          ) {
            if (
              Object.prototype.hasOwnProperty.call(this.allArray, 'deviceIdNeeded') !== -1 &&
              this.allArray[i].deviceIdNeeded == 'YES'
            ) {
              this.$message.error(
                'the tags will be get many offer,should contains offerName or prodName or sourceId !!'
              );
              return false;
            } else if (
              Object.prototype.hasOwnProperty.call(this.allArray, 'deviceIdNeeded') == -1
            ) {
              this.$message.error('the tags must be  contains offerName or prodName or sourceId !');
              return false;
            }
          }
          if (
            this.allArray.length == 1 &&
            Object.prototype.hasOwnProperty.call(this.allArray, 'sourceId') !== -1
          ) {
            this.$message.error('only sourceId you can setting in Source Whitelist Access!');
            return false;
          }
          if (
            Object.prototype.hasOwnProperty.call(this.allArray, 'redirectCount') !== -1 &&
            this.allArray[i].redirectCount <= 1
          ) {
            this.$message.error('redirectCount must > 1!');
            return false;
          }

          if (
            Object.prototype.hasOwnProperty.call(this.allArray, 'topOfferSize') !== -1 &&
            this.allArray[i].topOfferSize > 2000
          ) {
            this.$message.error('top offer size must <2000!');
            return false;
          }

          if (
            Object.prototype.hasOwnProperty.call(this.allArray, 'maxOfferSize') !== -1 &&
            this.allArray[i].maxOfferSize > 2000
          ) {
            this.$message.error('max offer size must <2000!');
            return false;
          }
          if (
            this.allArray.length == 1 &&
            Object.prototype.hasOwnProperty.call(this.allArray, 'whiteAffSub') !== -1
          ) {
            this.$message.error('pls choose sourceId or others!');
            return false;
          }
        }

        const obj = {};
        this.allArray.forEach((item) => {
          obj[item.label] = item.name;
        });

        const param = {
          ...obj,
          description: this.tags.description,
        };
        addTagsWhitelist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getTagsWhitelist();
            this.content = '';
            this.allArray = [];
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      updateTagsClick() {
        let obj = {
          label: this.tags.tagsLabel,
          name: this.tags.tagsValue,
        };
        let flag = false;

        if (this.tags.tagsLabel == 'whiteAffSub') {
          this.allArray.push(obj);
        } else {
          obj.name = obj.name.split(',');
          if (!this.allArray.length) {
            this.allArray.push(obj);
          } else {
            this.allArray.map((item) => {
              if (item.label == obj.label) {
                flag = true;
                item.name = obj.name;
                return;
              }
            });
            if (!flag) {
              this.allArray.push(obj);
            }
          }
        }
        if (this.tags.tagsLabel == 'numberOfJumps') {
          if (
            (this.numberOfJumps == '>' || this.numberOfJumps == '<=') &&
            this.tags.tagsValue.indexOf(',') > -1
          ) {
            this.$message.error('error : 跳转数大于小于等于选项只能填写数字');
            return;
          }
          if (this.numberOfJumps == 'between' && this.tags.tagsValue.indexOf(',') < 0) {
            this.$message.error('error : 跳转数between需要两个参数，并用逗号分割');
            return;
          }

          obj.name = this.numberOfJumps + this.tags.tagsValue;
          this.allArray.push(obj);
        }
        this.content = '';
        this.allArray.forEach((item, i) => {
          if (this.allArray[i] != '') {
            this.content += "<p style='font-size:16px;'>" + item.label + ':' + item.name + '</p>';
          } else {
            delete this.allArray[i];
          }
        });
      },
      tagHtml(tags) {
        var html = '';
        Object.keys(JSON.parse(tags)).forEach(function(key) {
          var tagItem = JSON.parse(tags)[key];
          html += key + ':' + tagItem + '&nbsp;';
        });
        return html;
      },
      getTagsMatchs(row) {
        this.targetId = row.id;
        checkoutCount(row.affiliateId, row.id)
          .then((response) => {
            if (response.code === 200) {
              this.$alert('this tags matches ' + response.total + 'offers', 'Tags matches', {
                confirmButtonText: '确定',
              });
            } else {
              this.$message(response.message);
            }
          })
          .finally(() => {
            this.targetId = null;
          });
      },
      deleteTags(row) {
        this.delTagsWhitelistVisible = true;
        this.delId = row.id;
      },
      delTagsWhitelistbRow() {
        deleteTagsWhitelist(this.affiliateId, this.delId).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getTagsWhitelist();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delTagsWhitelistVisible = false;
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getTagsWhitelist();
      },
    },
  };
</script>

<style></style>
