import request from 'utils/request';
const api = {
  CC_PKG_CONFIG_ADD: '/api/admin/v3/alg/setting/ccPkgConfig/add',
  CC_PKG_CONFIG_PAGE: '/api/admin/v3/alg/setting/ccPkgConfig/list',
  CC_PKG_CONFIG_DELETES: '/api/admin/v3/alg/setting/ccPkgConfig/delete',
  CC_PKG_CONFIG_DELETES_V2: '/api/admin/v3/alg/setting/ccPkgConfig/deleteV2',

  ACTIVE_DAYS_ADD: '/api/admin/v3/alg/setting/activeDays/add',
  ACTIVE_DAYS_PAGE: '/api/admin/v3/alg/setting/activeDays/list',
  ACTIVE_DAYS_DELETES: '/api/admin/v3/alg/setting/activeDays/delete',

  ADX_WHITE_LIST_ADD: '/api/admin/v3/alg/setting/adxWhiteList/add',
  ADX_WHITE_LIST_PAGE: '/api/admin/v3/alg/setting/adxWhiteList/list',
  ADX_WHITE_LIST_DELETES: '/api/admin/v3/alg/setting/adxWhiteList/delete',

  ADX_BLACK_LIST_ADD: '/api/admin/v3/alg/setting/adxBlackList/add',
  ADX_BLACK_LIST_PAGE: '/api/admin/v3/alg/setting/adxBlackList/list',
  ADX_BLACK_LIST_DELETES: '/api/admin/v3/alg/setting/adxBlackList/delete',

  APP_BLACK_LIST_ADD: '/api/admin/v3/alg/setting/appBlackList/add',
  APP_BLACK_LIST_PAGE: '/api/admin/v3/alg/setting/appBlackList/list',
  APP_BLACK_LIST_DELETES: '/api/admin/v3/alg/setting/appBlackList/delete',

  MAX_RECORD_ADD: '/api/admin/v3/alg/setting/maxRecord/add',
  MAX_RECORD_PAGE: '/api/admin/v3/alg/setting/maxRecord/list',
  MAX_RECORD_DELETES: '/api/admin/v3/alg/setting/maxRecord/delete',

  ALG_PKG_ADD: '/api/admin/v3/alg/setting/pkg/add',
  ALG_PKG_PAGE: '/api/admin/v3/alg/setting/pkg/list',
  ALG_PKG_DELETES: '/api/admin/v3/alg/setting/pkg/delete',

  SF_PKG_UPLOAD_UPDATE: '/api/admin/v3/alg/strategy/sfPkgUpload/update',
  SF_PKG_UPLOAD_PAGE: '/api/admin/v3/alg/strategy/sfPkgUpload/list',
  SF_PKG_UPLOAD_ADD: '/api/admin/v3/alg/strategy/sfPkgUpload/add',
};
// ccPkgConfig
export function ccPkgConfigPage(params) {
  return request({
    url: api.CC_PKG_CONFIG_PAGE,
    method: 'get',
    params,
  });
}

export function ccPkgConfigDelete(id) {
  return request({
    url: api.CC_PKG_CONFIG_DELETES,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function ccPkgConfigDeleteV2(data) {
  return request({
    url: api.CC_PKG_CONFIG_DELETES_V2,
    method: 'post',
    data,
  });
}

export function ccPkgConfigAdd(data) {
  return request({
    url: api.CC_PKG_CONFIG_ADD,
    method: 'post',
    data,
  });
}
// activeDays
export function activeDaysPage(params) {
  return request({
    url: api.ACTIVE_DAYS_PAGE,
    method: 'get',
    params,
  });
}

export function activeDaysDelete(id) {
  return request({
    url: api.ACTIVE_DAYS_DELETES,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function activeDaysAdd(data) {
  return request({
    url: api.ACTIVE_DAYS_ADD,
    method: 'post',
    data,
  });
}
//adxWhiteList
export function adxWhiteListPage(params) {
  return request({
    url: api.ADX_WHITE_LIST_PAGE,
    method: 'get',
    params,
  });
}

export function adxWhiteListDelete(id) {
  return request({
    url: api.ADX_WHITE_LIST_DELETES,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function adxWhiteListAdd(data) {
  return request({
    url: api.ADX_WHITE_LIST_ADD,
    method: 'post',
    data,
  });
}
//adxBlackList
export function adxBlackListPage(params) {
  return request({
    url: api.ADX_BLACK_LIST_PAGE,
    method: 'get',
    params,
  });
}

export function adxBlackListDelete(id) {
  return request({
    url: api.ADX_BLACK_LIST_DELETES,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function adxBlackListAdd(data) {
  return request({
    url: api.ADX_BLACK_LIST_ADD,
    method: 'post',
    data,
  });
}
//appBlackList
export function appBlackListPage(params) {
  return request({
    url: api.APP_BLACK_LIST_PAGE,
    method: 'get',
    params,
  });
}

export function appBlackListDelete(id) {
  return request({
    url: api.APP_BLACK_LIST_DELETES,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function appBlackListAdd(data) {
  return request({
    url: api.APP_BLACK_LIST_ADD,
    method: 'post',
    data,
  });
}

//maxRecord
export function maxRecordPage(params) {
  return request({
    url: api.MAX_RECORD_PAGE,
    method: 'get',
    params,
  });
}

export function maxRecordDelete(id) {
  return request({
    url: api.MAX_RECORD_DELETES,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function maxRecordAdd(data) {
  return request({
    url: api.MAX_RECORD_ADD,
    method: 'post',
    data,
  });
}

// alg pkg
export function algPkgPage(params) {
  return request({
    url: api.ALG_PKG_PAGE,
    method: 'get',
    params,
  });
}

export function algPkgDelete(id) {
  return request({
    url: api.ALG_PKG_DELETES,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function algPkgAdd(data) {
  return request({
    url: api.ALG_PKG_ADD,
    method: 'post',
    data,
  });
}

// sfPkgUpload
export function sfPkgUploadPage(params) {
  return request({
    url: api.SF_PKG_UPLOAD_PAGE,
    method: 'get',
    params,
  });
}

export function sfPkgUploadUpdate(data) {
  return request({
    url: api.SF_PKG_UPLOAD_UPDATE,
    method: 'post',
    data,
  });
}

export function sfPkgUploadAdd(data) {
  return request({
    url: api.SF_PKG_UPLOAD_ADD,
    method: 'post',
    data,
  });
}
