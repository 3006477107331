import {
  handleSubIdDel,
  handleAddAutoMixOther,
  handleUpdataAutoMixOther,
  handleRemoveAutoMixOther,
  handleAddAutoMixBase,
  handleUpdateAutoMixBase,
  fetchAutoMixOther,
  fetchAutoMixBase,
  handleRemoveAutoMixBase,
  handleAutoMixDataPacketAdd,
  fetchAutoMixDataPacket,
  getOfferDetail,
  handleAutoMixAddAll,
  handleForecastCount,
  handleBatchMix,
  forecastVbaCvr,
  getAlgStrategeList,
  updateOfferManagerRule,
} from '@/api/offer/detail';
import { getReportSubruleList } from 'api/product/subrule';
import { mapState, mapActions } from 'vuex';
import GroupSelect from '@/components/GroupSelect';
import { fetchDataResultList, fetchDefaultDataResultList } from '@/api/DDJ/v3/data';
import EnabledAffiliates from '@/views/offer/components/EnabledAffiliates';
import {
  fetchReplaceAutoMixRulePage,
  getReplaceAutoMixAff,
  replaceAutoMixAffDelete,
} from 'api/setting';

const StrategyItem = { dataStrategyCode: '', percentage: 0 };
export default {
  components: { GroupSelect, EnabledAffiliates },
  props: {
    offerId: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
    openErrorDialog: {
      type: Function,
      default: null,
    },
    multipleSelection: {
      type: Array,
      default: [],
    },
    handleCloseDialog: {
      type: Function,
      default: null,
    },
    message: String,
    selectCountries: String,
  },
  data() {
    var validateExpectCvr = (rule, value, callback) => {
      if (value && value !== '') {
        if (value > 0 && value < 10.1) {
          callback();
        } else {
          callback(new Error('Please enter 0.01 ~ 10'));
        }
      }
      callback();
    };
    var validateExpectOtherPercent = (rule, value, callback) => {
      if (value && value !== '') {
        if (value > 9 && value < 91) {
          callback();
        } else {
          callback(new Error('Please enter 10 ~ 90'));
        }
      }
      callback();
    };

    var validateVbaPercent = (rule, value, callback) => {
      if (value && value !== '') {
        if (value >= 1 && value <= 100) {
          callback();
        } else {
          callback(new Error('Please enter 1 ~ 100'));
        }
      }
      callback();
    };
    var validateVbaClick = (rule, value, callback) => {
      if (value && value !== '') {
        if (value >= 1 && value <= 1000000000) {
          callback();
        } else {
          callback(new Error('Please enter 1 ~ 1000000000'));
        }
      }
      callback();
    };
    var validateOtherDailyInstallCap = (rule, value, callback) => {
      if (value && value !== '') {
        if (value >= 1) {
          callback();
        } else {
          callback(new Error('请输入大于1的'));
        }
      }
      callback();
    };

    return {
      eventCapDaily: [],
      postData1: {
        offerId: this.offerId,
        ofAffList: [{}],
      },
      replaceAffiliateData: {
        offerId: this.offerId,
        replaceAffList: [{}],
      },
      replaeceRules: [],
      postData2: {
        offerId: this.offerId,
        strategy: 'vba',
        vbaDailyClickCap: null,
        otherDailyInstallCap: null,
        otherMinInstallCap: null,
        expectCvr: null,
        expectOtherPercent: null,
        vbaPercent: null,
      },
      postData3: {
        offerId: this.offerId,
        dataSourceType: '0',
        dataSourceIds: [],
        dataStrategyVOList: [{}],
        globalAlgEnable: 'yes',
        defaultUploadEnabled: 'no',
      },
      offer: {},
      options: {
        dataSourceType: [
          { label: '定时更新', value: '0' },
          { label: '实时', value: '1' },
        ],
        dataList: [],
        dataListRealTime: [],
        strategyList: [],
        rule: [],
        defaultDataList: [],
      },
      upManagerAndSubRule: {
        accountId: '',
        ruleId: '',
      },
      upManagerAndSubRuleVisible: false,
      loading: {
        dataPacket: false,
        saveAll: false,
      },
      rules: {
        vbaDailyClickCap: [
          { required: true, message: 'Please enter Daily VBA Cap', trigger: 'blur' },
          { validator: validateVbaClick, trigger: 'blur' },
        ],
        expectCvr: [{ validator: validateExpectCvr, trigger: 'blur' }],
        expectOtherPercent: [{ validator: validateExpectOtherPercent, trigger: 'blur' }],
        vbaPercent: [{ validator: validateVbaPercent, trigger: 'blur' }],
        otherDailyInstallCap: [{ validator: validateOtherDailyInstallCap, trigger: 'blur' }],
        //dataSourceType: [{ required: true, message: '请选择数据包类型', trigger: 'blur' }],
        //dataSourceIds: [{ required: true, message: '请选择数据包', trigger: 'blur' }],
      },
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    /*vbaPercentLowCvr() {
      if (this.postData2.strategy === 'vbaPercent') {
        return 0.03; // 设置默认值为0.03
      }
      return null; // 非'vbaPercent'时不显示默认值
    },*/
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateList']),
    init() {
      this.getAffiliateList();
      this.getReplaceRuleList();
      this.getAlgStrategy();
      this.subruleList();
      if (this.type !== 'offerList') {
        this.getOfferDetail();
        this.getOther();
        this.getReplaceAutoMixAff();
        this.autoMixBaseDetails();
      }
      if (this.$refs.enabledAffiliates && this.$refs.enabledAffiliates.getList) {
        this.$refs.enabledAffiliates.getList();
      }
      if (this.selectCountries && this.selectCountries.trim() !== '') {
        this.getDefaultDataList();
      }
    },
    async getOfferDetail() {
      await getOfferDetail(this.offerId)
        .then((response) => {
          if (response.code === 200) {
            this.offer = response.result;
            this.getDataList(1);
            this.getDataList(0);
            this.getDataPacket();
            if (this.offer != null && this.offer.ruleId != null) {
              this.upManagerAndSubRule.ruleId = this.offer.ruleId;
            }
            if (response.result.eventCapDaily != null) {
              this.eventCapDaily = JSON.parse(response.result.eventCapDaily);
            }
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((e) => {
          this.$message.error('error' + e);
        });
    },
    getDataList(dataSourceType) {
      fetchDataResultList({
        country: this.offer.countries,
        os: this.offer.platforms,
        dataSourceType,
      }).then((res) => {
        if (res.result) {
          if (dataSourceType == 1) {
            this.options.dataListRealTime = res.result;
          } else {
            this.options.dataList = res.result;
          }
        }
      });
    },
    getDataPacket() {
      fetchAutoMixDataPacket({ offerId: this.postData3.offerId }).then((res) => {
        //console.log(res);
        if (res.result) {
          if (!res.result.dataStrategyVOList) {
            res.result.dataStrategyVOList = [{}];
          }
          this.postData3 = res.result;
          if (res.result.dataSourceIds) {
            this.postData3.dataSourceIds = res.result.dataSourceIds.split(',');
          }
        }
        //this.getDataList(this.postData3.dataSourceType);
      });
    },
    addItem(item) {
      this.postData1.ofAffList.push(item);
    },
    removeItem(index) {
      this.postData1.ofAffList.splice(index, 1);
    },
    removeAffItem(affIds) {
      affIds.forEach((affId) => {
        if (this.postData1.ofAffList.length > 0) {
          this.postData1.ofAffList.forEach((item, index) => {
            if (item.affiliateId === affId) {
              this.postData1.ofAffList.splice(index, 1);
            }
          });
        }
      });
    },
    addItemReplaceRule() {
      this.replaceAffiliateData.replaceAffList.push({});
    },
    removeItemReplaceRule(index) {
      this.replaceAffiliateData.replaceAffList.splice(index, 1);
    },
    del(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          var query = `?offerId=${this.filter.offerId}&id=${id}`;
          handleSubIdDel(query).then((res) => {
            if (res.code == 200) {
              this.getList();
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    getOther() {
      fetchAutoMixOther(this.postData1.offerId).then((res) => {
        if (res.result) {
          this.postData1 = res.result;
          this.postData1.ofAffList = JSON.parse(res.result.ofAffList);
        } else {
          this.postData1 = {
            offerId: this.offerId,
            ofAffList: [{}],
          };
        }
      });
    },
    getReplaceAutoMixAff() {
      getReplaceAutoMixAff(this.postData1.offerId).then((res) => {
        if (res.result) {
          this.replaceAffiliateData = res.result;
          if (res.result.replaceAffList && typeof res.result.replaceAffList === 'string') {
            this.replaceAffiliateData.replaceAffList = JSON.parse(res.result.replaceAffList);
          }
        } else {
          this.replaceAffiliateData = {
            offerId: this.offerId,
            replaceAffList: [{}],
          };
        }
      });
    },
    saveOther() {
      let result = this.postData1.ofAffList.find(
        (item) =>
          Object.keys(item).includes('ratio') === false || item.ratio == null || item.ratio == ''
      );
      if (result && Object.keys(result).length > 0) {
        this.$message.error('ratio不能为空');
        return;
      }

      if (this.postData1.id) {
        handleUpdataAutoMixOther(this.postData1).then((res) => {
          if (res.success) {
            this.$message.success('save successfiy！');
            return;
          }
          this.$message.error(res.message);
        });
      } else {
        handleAddAutoMixOther(this.postData1).then((res) => {
          if (res.success) {
            this.getOther();
            this.$message.success('save successfiy！');
            return;
          }
          this.$message.error(res.message);
        });
      }
    },
    removeOther() {
      if (!this.postData1.id) {
        this.$message.error('请先添加保存后再删除！');
        return;
      }
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          handleRemoveAutoMixOther(this.postData1.id).then((res) => {
            if (res.code == 200) {
              this.getOther();
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    removeReplaceAffilate() {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          replaceAutoMixAffDelete(this.postData1.offerId).then((res) => {
            if (res.code == 200) {
              this.getReplaceAutoMixAff();
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    saveStrategy(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            (this.postData2.expectCvr == null || this.postData2.expectCvr == '') &&
            (this.postData2.expectOtherPercent == null ||
              this.postData2.expectOtherPercent == '') &&
            (this.postData2.otherDailyInstallCap == null ||
              this.postData2.otherDailyInstallCap == '')
          ) {
            this.$message.error('Daily Other Cap、Expect CVR(%)、VBA Percentage(%)至少填写一个');
            return;
          }
          let query = '';
          if (this.postData2.strategy == 'other') {
            query = {
              id: this.postData2.id,
              offerId: this.postData2.offerId,
              strategy: this.postData2.strategy,
              otherDailyInstallCap: this.postData2.otherDailyInstallCap,
              expectCvr: this.postData2.expectCvr,
            };
          } else {
            query = Object.assign({}, this.postData2);
          }
          if (this.postData2.id) {
            handleUpdateAutoMixBase(query).then((res) => {
              this.autoMixBaseDetails();
              this.$message.success(res.message);
            });
          } else {
            handleAddAutoMixBase(query).then((res) => {
              if (res.code === 200) {
                this.autoMixBaseDetails();
                this.$message.success('save successfiy！');
              } else {
                this.$message.error(res.message);
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    autoMixBaseDetails() {
      fetchAutoMixBase(this.postData2.offerId).then((res) => {
        if (res.result) {
          this.postData2 = res.result;
        } else {
          this.$refs.postData2.resetFields();
          this.postData2 = {
            offerId: this.offerId,
            strategy: 'vba',
            vbaDailyClickCap: null,
            otherDailyInstallCap: null,
            otherMinInstallCap: null,
            expectCvr: null,
            expectOtherPercent: null,
            vbaPercent: null,
          };
        }
        if (res.result && res.result.strategy === 'curve') {
          this.handleMinCvr();
        }
      });
    },
    removeAutoMixBase() {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          handleRemoveAutoMixBase(this.postData2.id).then((res) => {
            if (res.code == 200) {
              this.autoMixBaseDetails();
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    saveDataPacket() {
      this.loading.dataPacket = true;
      handleAutoMixDataPacketAdd(this.postData3).then((res) => {
        this.loading.dataPacket = false;
        if (res.code === 200) {
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    saveAll() {
      this.loading.saveAll = true;
      // 第一部分
      let result = this.postData1.ofAffList.find(
        (item) =>
          Object.keys(item).includes('ratio') === false || item.ratio == null || item.ratio == ''
      );
      if (this.postData2.strategy !== 'onlyVBA') {
        if (result && Object.keys(result).length > 0) {
          this.$message.error('其他渠道比例分配 不能为空');
          this.loading.saveAll = false;
          return;
        }
      }
      // 混量替换渠道data
      if (this.$refs.postData2) {
        this.$refs.postData2.validate((valid) => {
          if (valid && this.$refs.postData3) {
            this.$refs.postData3.validate((valid3) => {
              if (valid3) {
                // 第二部分
                if (this.postData2.strategy == 'vba') {
                  if (
                    (this.postData2.expectCvr == null || this.postData2.expectCvr == '') &&
                    (this.postData2.expectOtherPercent == null ||
                      this.postData2.expectOtherPercent == '') &&
                    (this.postData2.otherDailyInstallCap == null ||
                      this.postData2.otherDailyInstallCap == '')
                  ) {
                    this.$message.error(
                      'Daily Other Cap、Expect CVR(%)、VBA Percentage(%)至少填写一个'
                    );
                    this.loading.saveAll = false;
                    return;
                  }
                }
                if (this.postData2.strategy == 'other') {
                  if (
                    this.postData2.otherDailyInstallCap == null ||
                    this.postData2.otherDailyInstallCap == '' ||
                    this.postData2.expectCvr == null ||
                    this.postData2.expectCvr == ''
                  ) {
                    this.$message.error('Daily Other Cap、Expect CVR(%)不能为空');
                    this.loading.saveAll = false;
                    return;
                  }
                }

                let query2 = '';
                if (this.postData2.strategy == 'other') {
                  query2 = {
                    id: this.postData2.id,
                    offerId: this.postData2.offerId,
                    strategy: this.postData2.strategy,
                    otherDailyInstallCap: this.postData2.otherDailyInstallCap,
                    expectCvr: this.postData2.expectCvr,
                    vbaPercent: this.postData2.vbaPercent,
                  };
                } else if (this.postData2.strategy == 'vbaPercent') {
                  query2 = {
                    id: this.postData2.id,
                    offerId: this.postData2.offerId,
                    strategy: this.postData2.strategy,
                    otherDailyInstallCap: this.postData2.otherDailyInstallCap,
                    vbaPercent: this.postData2.vbaPercent,
                    expectCvr: this.postData2.expectCvr,
                  };
                } else {
                  query2 = Object.assign({}, this.postData2);
                }
                if (
                  this.postData1 &&
                  this.postData1.ofAffList &&
                  this.postData1.ofAffList.length > 0
                ) {
                  this.postData1.ofAffList = this.postData1.ofAffList.map((item) => {
                    return Object.fromEntries(
                      Object.entries(item).filter(([key]) => key !== 'isNew')
                    );
                  });
                }
                const query = {
                  mix: this.postData1,
                  strategy: query2,
                  replaceAffiliateData: this.replaceAffiliateData,
                  ruleId: this.upManagerAndSubRule.ruleId,
                };
                if (this.type != 'offerList') {
                  const postData3Obj = Object.assign({}, this.postData3);
                  if (this.postData3.dataSourceIds) {
                    postData3Obj.dataSourceIds = this.postData3.dataSourceIds.toString();
                  }
                  handleAutoMixAddAll({ ...query, taskInfoPost: postData3Obj })
                    .then((res) => {
                      if (res.code === 200) {
                        this.getOfferDetail();
                        this.getOther();
                        this.autoMixBaseDetails();
                        if (this.$refs.enabledAffiliates && this.$refs.enabledAffiliates.getList) {
                          this.$refs.enabledAffiliates.getList();
                        }
                        this.$message.success('save successfiy！');
                      } else {
                        this.$message.error(res.message);
                      }
                      this.loading.saveAll = false;
                    })
                    .catch((e) => {
                      this.$message.error(e);
                      this.loading.saveAll = false;
                    });
                } else {
                  handleBatchMix({
                    ...query,
                    mix: { ...query.mix, offerId: 0 },
                    strategy: { ...query.strategy, offerId: 0 },
                    offerIds: this.multipleSelection.map((item) => item.offerId),
                    taskInfoPost: {
                      defaultUploadEnabled: this.postData3.defaultUploadEnabled,
                      globalAlgEnable: this.postData3.globalAlgEnable,
                    },
                  })
                    .then((res) => {
                      if (res.code === 200) {
                        this.openErrorDialog(res.result);
                        this.handleCloseDialog(this.upManagerAndSubRule.ruleId);
                      } else {
                        this.$message.error(res.message);
                      }
                      this.loading.saveAll = false;
                    })
                    .catch((e) => {
                      this.$message.error(e);
                      this.loading.saveAll = false;
                    });
                }
              } else {
                this.loading.saveAll = false;
              }
            });
          } else {
            this.loading.saveAll = false;
          }
        });
      }
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getList();
    },
    handleCount() {
      if (!(this.postData2.otherDailyInstallCap && this.postData2.expectCvr)) {
        return;
      }
      const param = {
        offerId:
          this.type === 'offerList' ? this.multipleSelection[0].offerId : this.postData2.offerId, //offerId
        strategy: this.postData2.strategy,
        otherDailyInstallCap: this.postData2.otherDailyInstallCap, //Daily Other Cap
        expectCvr: this.postData2.expectCvr, //Expect CVR(%)
        vbaPercent: this.postData2.vbaPercent,
      };
      handleForecastCount(param)
        .then((res) => {
          if (res.code == 200) {
            this.postData2.forecastDataSourceCount = res.result;
            this.$forceUpdate();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },
    handleMinCvr() {
      if (!(this.postData2.otherDailyInstallCap && this.postData2.expectCvr)) {
        return;
      }
      const param = {
        offerId:
          this.type === 'offerList' ? this.multipleSelection[0].offerId : this.postData2.offerId, //offerId
        strategy: 'curve',
        otherDailyInstallCap: this.postData2.otherDailyInstallCap, //Daily Other Cap
        expectCvr: this.postData2.expectCvr, //Expect CVR(%)
      };
      forecastVbaCvr(param)
        .then((res) => {
          if (res.code == 200) {
            this.postData2.vbaClickCap = res.result.vbaCvr;
            this.postData2.allCap = res.result.allInstall;
            this.$forceUpdate();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },
    getReplaceRuleList() {
      fetchReplaceAutoMixRulePage().then((res) => {
        this.replaeceRules = res.result;
      });
    },
    getAlgStrategy() {
      getAlgStrategeList().then((res) => {
        this.options.strategyList = res.result;
      });
    },
    addAlgItem() {
      let item = JSON.parse(JSON.stringify(StrategyItem));
      this.postData3.dataStrategyVOList.push(item);
    },
    removeAlgItem(index) {
      this.postData3.dataStrategyVOList.splice(index, 1);
    },
    removeAlgOther() {
      if (this.postData3.dataStrategyVOList.length > 0) {
        this.$message.error('请先添加保存后再删除！');
        return;
      }
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          this.postData3.dataStrategyVOList.splice(0, this.postData3.dataStrategyVOList.length);
          this.postData3.dataStrategyVOList.push({});
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    updateManagerAndSubRule() {
      this.upManagerAndSubRule.ruleId = this.offer.ruleId;
      this.upManagerAndSubRule.accountId = this.offer.accountId;
      this.upManagerAndSubRuleVisible = true;
    },
    // 保存manager-subrue
    upManagerAndSubRuleClick() {
      const query = {
        offerId: this.offer.offerId,
        accountId: this.upManagerAndSubRule.accountId,
        ruleId: this.upManagerAndSubRule.ruleId,
      };
      updateOfferManagerRule(query)
        .then((res) => {
          if (res.code !== 200 && res.success == false) {
            this.$message.error(res.message);
          } else {
            this.getOfferDetail();
            this.$message({
              message: '更新成功',
              type: 'success',
            });
          }
          this.upManagerAndSubRuleVisible = false;
        })
        .catch((error) => {
          this.$message.error('更新失败！' + error.toString());
          this.upManagerAndSubRuleVisible = false;
        });
    },
    // 获取subrule列表
    subruleList() {
      getReportSubruleList({ status: 1 }).then((res) => {
        this.options.rule = res.result;
      });
    },
    getDefaultDataList() {
      fetchDefaultDataResultList({
        countries: this.selectCountries,
      }).then((res) => {
        if (res.result) {
          this.options.defaultDataList = res.result;
        }
      });
    },
  },
  watch: {
    'postData2.strategy'(n) {
      this.$nextTick(() => {
        console.log(n);
        this.$refs.postData2.clearValidate();
      });
    },
    /* 'postData3.dataSourceType'(newValue, oldValue) {
      this.options.dataList = [];
      // this.postData3.dataSourceId = '';
      this.getDataList(newValue);
      console.log(oldValue);
    },*/
  },
};
