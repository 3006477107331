import request from '@/utils/request';
const api = {
  ACCOUNT_FOR_PAYMENT_ADD: '/api/finance/bidReportConversions/addAccountForPayment',
  ACCOUNT_FOR_PAYMENT_PAGE: '/api/finance/bidReportConversions/listAccountForPayment',
  ACCOUNT_FOR_PAYMENT_DELETES: '/api/finance/bidReportConversions/delete',
};

// activeDays
export function accountForPaymentPage(params) {
  return request({
    url: api.ACCOUNT_FOR_PAYMENT_PAGE,
    method: 'get',
    params,
  });
}

export function accountForPaymentDelete(id) {
  return request({
    url: api.ACCOUNT_FOR_PAYMENT_DELETES,
    method: 'post',
    params: {
      id,
    },
  });
}

export function accountForPaymentAdd(data) {
  return request({
    url: api.ACCOUNT_FOR_PAYMENT_ADD,
    method: 'post',
    data,
  });
}
