<template>
  <div>
    <el-form size="mini">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="Source Id" label-width="80px">
            <el-input v-model="filter.sourceIds"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Aff Sub" label-width="70px">
            <el-input v-model="filter.affSub"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="getAffSubSourceWhiteList(1)">Search</el-button>
            <!-- affSubVisible = true -->
            <el-button type="primary" @click="addAffSubClick">Add</el-button>
            <el-button type="primary" @click="deAffSubClick">Delete</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="affSubList"
      border
      stripe
      style="width:100%;"
      size="mini"
      highlight-current-row
      @selection-change="handleAffSubSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="Type" prop="type" align="center"></el-table-column>
      <el-table-column label="AffiliateId" prop="affiliateId" align="center"></el-table-column>
      <el-table-column label="SourceId" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toSourceDetailClick(scope.row)">{{
            scope.row.sourceId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Source Name" prop="sourceName" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" v-if="!scope.row.sourceName">undefined</span>
          <span class="cor337ab7" v-else @click="toSourceDetailClick(scope.row)">{{
            scope.row.sourceName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="AffSub" prop="affSub" align="center"></el-table-column>
      <el-table-column label="Description" prop="description" align="center"></el-table-column>
      <el-table-column
        label="Operator"
        prop="operator"
        min-width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Operate Time"
        prop="operateTime"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="affSubTotal"
      @handleSizeChange="handleAffSubSizeChange"
      @handleCurrentChange="handleAffSubCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delaffSubVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delaffSubVisible = false">cancel</el-button>
        <el-button type="primary" @click="delAffSubRow">confirm</el-button>
      </span>
    </el-dialog>
    <!-- affSub模块框 -->
    <el-dialog
      :visible.sync="affSubVisible"
      title="Source Sub"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="sourceSub" label-width="80px" ref="affSub" :rules="rules" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="Source" prop="sourceId">
              <el-select
                v-model="sourceSub.sourceId"
                reserve-keyword
                :loading="sourceLoading"
                clearable
                filterable
                class="w100"
              >
                <el-option-group
                  v-for="group in sourcesList"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="AffSub" prop="affSub">
              <el-input v-model="sourceSub.affSub" placeholder="affSub"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Type" prop="type">
              <el-select v-model="sourceSub.type" class="w100">
                <el-option
                  v-for="item in affSubtype"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Description" label-width="100px" prop="description">
              <el-input v-model="sourceSub.description" placeholder="description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" align="right">
            <el-form-item label-width="0px" class="mb0">
              <el-button @click="affSubVisible = false">取 消</el-button>
              <el-button type="primary" @click="addAffSub('affSub')">确 定</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Pagination from '@/components/pagination';

  import {
    getSourceSubWhiteList,
    addSourceSubWhiteList,
    deleteSourceSubWhiteList,
  } from 'api/affiliate/detail';
  let clickTimer = null;
  const defaultSourceSub = {
    sourceId: '',
    affSub: '',
    description: '',
  };
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        filter: {},
        affSubTotal: null,
        currentPage: 1,
        pageSize: 20,
        delaffSubVisible: false,
        sourceSub: {
          type: 'IN-AFF',
        },
        rules: {
          sourceId: [{ required: true, message: 'please write sourceId', trigger: 'blur' }],
          affSub: [{ required: true, message: 'please write affSub', trigger: 'blur' }],
          type: [
            {
              required: true,
              message: 'please select type',
              trigger: 'blur',
            },
          ],
        },
        affSubtype: [
          {
            label: 'IN-AFF',
            value: 'IN-AFF',
          },
          {
            label: 'IN-ALL',
            value: 'IN-ALL',
          },
        ],
        affSubSelectionList: [],
        delArr: [],
        affSubList: [],
        affSubVisible: false,
      };
    },
    computed: {
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      this.getSourceIdsList();
      this.getAffSubSourceWhiteList();
    },
    methods: {
      ...mapActions('source', ['getSourceIdsList']),
      //子渠道白名单
      getAffSubSourceWhiteList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.filter.sourceIds) {
          param.sourceIds = this.filter.sourceIds;
        }
        if (this.filter.affSub) {
          param.affSub = this.filter.affSub;
        }
        getSourceSubWhiteList(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.affSubList = response.result;
            this.affSubTotal = response.total;
          }
        });
      },
      addAffSubClick() {
        this.sourceSub = Object.assign({}, defaultSourceSub);
        this.affSubVisible = true;
      },
      deAffSubClick() {
        const length = this.affSubSelectionList.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.delaffSubVisible = true;
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.affSubSelectionList[i].id);
        }
      },
      delAffSubRow() {
        const ids = this.delArr.join(',');
        deleteSourceSubWhiteList(this.affiliateId, ids).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getAffSubSourceWhiteList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delArr = [];
        this.delaffSubVisible = false;
      },
      addAffSub(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let param = {
              ...this.sourceSub,
            };
            addSourceSubWhiteList(this.affiliateId, param).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: 'Add Success',
                  type: 'success',
                });
                this.getAffSubSourceWhiteList();
              } else {
                this.$message.error('Add Error:' + response.message);
              }
            });
            this.affSubVisible = false;
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      handleAffSubSelectionChange(val) {
        this.affSubSelectionList = val;
      },
      handleAffSubSizeChange(val) {
        this.pageSize = val;
        this.handleAffSubCurrentChange(1);
      },
      handleAffSubCurrentChange(val) {
        this.currentPage = val;
        this.getAffSubSourceWhiteList();
      },
      toSourceDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        clickTimer = window.setTimeout(function() {
          this.$nextTick(() => {
            const { href } = this.$router.resolve({
              path: '/source/detail',
              query: {
                sourceId: row.sourceId,
                sourceName: row.sourceName,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
    },
  };
</script>

<style></style>
