import request from 'utils/request';

export const api = {
  QUERY_POSTBACKURL_BYPAGE: '/api/postback/manage/queryPostbackUrlList',
  ADD_POSTBACKURL: '/api/postback/manage/add',
  UPDATE_POSTBACKURL: '/api/postback/manage/update',
  DELETE_POSTBACKURL: '/api/postback/manage/delete',
  TEST_POSTBACKURL_STATUS: '/api/postback/manage/urlPing',
};

export function queyPostbackUrlByPage(data) {
  return request({
    url: api.QUERY_POSTBACKURL_BYPAGE,
    method: 'post',
    data,
  });
}

export function addPostBackUrl(data) {
  return request({
    url: api.ADD_POSTBACKURL,
    method: 'post',
    data,
  });
}

export function updatePostBackUrl(data) {
  return request({
    url: api.UPDATE_POSTBACKURL,
    method: 'put',
    data,
  });
}

export function deletePostBackUrl(id) {
  return request({
    url: api.DELETE_POSTBACKURL,
    method: 'delete',
    params: {
      id,
    },
  });
}

export function testPostUrlStatus(data) {
  return request({
    url: api.TEST_POSTBACKURL_STATUS,
    method: 'post',
    data,
  });
}
