import {
  getAccountList,
  insertAccount,
  updateAccount,
  deleteAccount,
  getRoleList,
  bindRole,
  unlinkValidator,
} from 'api/account/index';
import Pagination from '@/components/pagination';
import { myMixin } from '@/mixins/mixins.js';

const defaultAccount = {
  username: '',
  password: '',
  description: '',
  roles: [],
  isOpenAllAdv: 0,
  isOpenAllAff: 0,
};
export default {
  mixins: [myMixin],
  components: {
    Pagination,
  },
  data() {
    return {
      pageSize: 100,
      accountId: null,
      delVisible: false,
      updateAccountVisible: false,
      unLinkVisible: false,
      isOpenAllAffOptions: [
        {
          label: 'yes',
          value: 1,
        },
        {
          label: 'no',
          value: 0,
        },
      ],
      isOpenAllAdvOptions: [
        {
          label: 'yes',
          value: 1,
        },
        {
          label: 'no',
          value: 0,
        },
      ],
      isOpenAllSourceOptions: [
        {
          label: 'open all source',
          value: 1,
        },
        {
          label: 'no',
          value: 0,
        },
        {
          label: 'open domestic source',
          value: 3,
        },
        {
          label: 'open overseas source',
          value: 2,
        },
      ],
      currentPage: 1,
      roleOptions: [],
      consoleAccount: {
        accountId: null,
        username: null,
      },
      total: null,
      listLoading: false,
      userNameValue: '',
      statusOptions: [
        {
          label: 'ENABLE',
          value: 'ENABLE',
        },
        {
          label: 'DISABLE',
          value: 'DISABLE',
        },
      ],
      consoleAccountList: [],
      account: Object.assign({}, defaultAccount),
      updateRole: {
        roleId: [],
      },
      dialogType: 'new',
      dialogVisible: false,
      updateVisible: false,
      id: '',
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    searchListClick(page) {
      this.listLoading = true;
      let param = {};
      if (this.consoleAccount.accountId != null) {
        param['accountId'] = this.consoleAccount.accountId;
      }
      if (this.consoleAccount.accountName != null) {
        param['accountName'] = this.consoleAccount.accountName;
      }
      if (page) {
        this.currentPage = 1;
      }
      param['page'] = this.currentPage;
      param['pageSize'] = this.pageSize;
      getAccountList(param).then((response) => {
        this.listLoading = false;
        if (response.code == 200) {
          this.consoleAccountList = response.result;
          this.total = response.total;
        } else {
          this.$message.error('Error' + response.message);
        }
      });
    },
    getDataList() {
      let param = {};
      getRoleList(param).then((response) => {
        if (response.code === 200) {
          let array = response.result;
          let rolesMap = [];
          array.forEach((item) => {
            rolesMap.push({
              value: item.id,
              label: item.roleName,
            });
          });
          this.roleOptions = rolesMap;
        } else {
          this.$message.error(response.message);
        }
      });
    },
    addClick() {
      this.account = Object.assign({}, defaultAccount);
      this.dialogType = 'new';
      this.dialogVisible = true;
    },
    updateAccountClick(row) {
      console.log('fxdfgdg');
      this.account = {
        id: row.accountId,
        description: row.description,
        isOpenAllAdv: row.isOpenAllAdv,
        isOpenAllAff: row.isOpenAllAff,
        username: row.username,
      };
      this.dialogType = 'edit';
      this.dialogVisible = true;
    },
    delAccountClick(row) {
      this.accountId = row.accountId;
      this.delVisible = true;
    },
    unlinkClick(row) {
      this.accountId = row.accountId;
      this.unLinkVisible = true;
    },
    delRow() {
      deleteAccount(this.accountId).then((response) => {
        if (response.code == 200) {
          this.$message({
            message: 'Delete Success',
            type: 'success',
          });
          this.searchListClick();
          this.delVisible = false;
        } else {
          this.$message.error('Error' + response.message);
        }
      });
    },
    confirmClick() {
      const isEdit = this.dialogType === 'edit';
      if (!isEdit) {
        insertAccount({ ...this.account }).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.searchListClick();
            this.dialogVisible = false;
          } else {
            this.$message.error('Error' + response.message);
          }
        });
      } else {
        updateAccount({ ...this.account }).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.searchListClick();
            this.dialogVisible = false;
          } else {
            this.$message.error('Error' + response.message);
          }
        });
      }
    },
    updateClick(row) {
      this.updateRole.roleId = [];
      this.accountId = row.accountId;
      for (var i = 0; i < row.roles.length; i++) {
        this.updateRole.roleId.push(row.roles[i].id);
      }
      this.updateVisible = true;
    },
    confirmUpdateClick() {
      if (this.updateRole.roleId.length == 0) {
        this.$message.error('一个用户至少拥有一个角色');
        return;
      }
      bindRole(this.accountId, [...this.updateRole.roleId]).then((response) => {
        if (response.code === 200) {
          this.$message({
            message: response.message,
            type: 'success',
          });
          this.searchListClick();
          this.updateVisible = false;
        } else {
          this.$message.error(response.message);
        }
      });
    },

    unlinkValidatorCode() {
      let user = { accountId: this.accountId };
      unlinkValidator(user).then((response) => {
        if (response.code === 200) {
          this.$message({
            message: response.message,
            type: 'success',
          });
          this.searchListClick();
          this.unLinkVisible = false;
        } else {
          this.$message.error(response.message);
        }
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.searchListClick();
    },
  },
};
