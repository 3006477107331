import Router from 'vue-router';
import Vue from 'vue';
import store from '@/store/';
import NProgress from 'nprogress'; //进度条
import 'nprogress/nprogress.css';
Vue.use(Router);

// 路由懒加载
// const getComponent = (name, component) => () =>
//   import(`@/views/${name}/${component}.vue`);

import Home from '@/components/common/Home.vue';

const myRouter = new Router({
  routes: [
    {
      path: '/',
      component: Home,
      children: [
        {
          path: '/Dashboard',
          component: () => import('@/views/dashboard/index.vue'),
          meta: {
            title: 'Dashboard',
          },
        },
        {
          path: '/affiliate/overall',
          component: () => import('@/views/affiliate/AffiliateOverall.vue'),
          meta: {
            title: 'Affiliate Overall',
          },
        },
        {
          path: '/affiliate/detail',
          meta: {
            title: 'Affiliate Detail',
          },
          component: () => import('@/views/affiliate/AffiliateDetail.vue'),
        },
        {
          path: '/affiliate/tag',
          component: () => import('@/views/affiliate/Tag.vue'),
          meta: {
            title: 'Affiliate Tag',
          },
        },
        {
          path: '/api/adv/dynamicAdjustReport',
          component: () => import('@/views/affiliate/AffiliateAdjustReport.vue'),
          meta: {
            title: 'dynamic adjust Report',
          },
        },
        {
          path: '/api/AffiliateAdjustBlackOffer',
          component: () => import('@/views/affiliate/adjustBlacklist.vue'),
          meta: {
            title: 'Adjust Black Offer',
          },
        },
        {
          path: '/source/add',
          meta: {
            title: 'Add',
          },
          component: () => import('@/views/dashboard/index.vue'),
        },
        {
          path: '/source/overall',
          component: () => import('@/views/source/sourceOverall.vue'),
          meta: {
            title: 'Source Overall',
          },
        },
        {
          path: '/source/detail',
          meta: {
            title: 'Source Detail',
          },
          component: () => import('@/views/source/sourceDetail.vue'),
        },
        {
          path: '/offer/add',
          component: () => import('@/views/product/Add.vue'),
          meta: {
            title: 'Add',
          },
        },
        {
          path: '/product/overall',
          component: () => import('@/views/product/ProductOverall.vue'),
          meta: {
            title: 'Product Overall',
          },
        },
        {
          path: '/product/detail',
          component: () => import('@/views/product/ProductDetail.vue'),
          meta: {
            title: 'Product Detail',
          },
        },
        {
          path: '/product/cat/',
          component: () => import('@/views/source/productCat.vue'),
          meta: {
            title: 'product cat ',
          },
        },
        {
          path: '/offer/top',
          component: () => import('@/views/product/Ranking&search.vue'),
          meta: {
            title: 'Ranking search',
          },
        },
        {
          path: '/offer/detail',
          component: () => import('@/views/offer/OfferDetail.vue'),
          meta: {
            title: 'Offer Detail',
          },
        },
        {
          path: '/offer/offerLogAdv',
          component: () => import('@/views/offer/OfferlogAdv.vue'),
          meta: {
            title: 'OfferLogAdv',
          },
        },
        {
          path: '/api/bidOfferAffMix',
          component: () => import('@/views/offer/bidOfferAffMix.vue'),
          meta: {
            title: 'BidOfferAffMix',
          },
        },
        {
          path: '/offer/smartLink',
          component: () => import('@/views/offer/OfferSmartLink.vue'),
          meta: {
            title: 'OfferSmartLink',
          },
        },
        {
          path: '/offer/subrule',
          component: () => import('@/views/product/subrule.vue'),
          meta: {
            title: 'Sub Rule List',
          },
        },
        {
          path: '/offer/subrule/edit',
          component: () => import('@/views/product/subrule/edit.vue'),
          meta: {
            title: 'Sub Rule List edit',
          },
        },
        {
          path: '/offer/subrule/add',
          component: () => import('@/views/product/subrule/add.vue'),
          meta: {
            title: 'Sub Rule List add',
          },
        },
        {
          path: '/offer/close/compare',
          component: () => import('@/views/product/CompareRuleList.vue'),
          meta: {
            title: 'Compare Rule List',
          },
        },
        {
          path: '/offer/close/compare/add',
          component: () => import('@/views/product/compareRuleList/addCompare'),
          meta: {
            title: 'compare Rule List add',
          },
        },
        {
          path: '/offer/close/compare/edit',
          component: () => import('@/views/product/compareRuleList/editCompare'),
          meta: {
            title: 'compare Rule List edit',
          },
        },
        {
          path: '/offer/close/rule',
          component: () => import('@/views/product/CloseRuleList.vue'),
          meta: {
            title: 'Close Rule List',
          },
        },
        {
          path: '/offer/close/rule/add',
          component: () => import('@/views/product/closeRuleList/add.vue'),
          meta: {
            title: 'Close Rule List add',
          },
        },
        {
          path: '/offer/close/rule/edit',
          component: () => import('@/views/product/closeRuleList/edit.vue'),
          meta: {
            title: 'Close Rule List edit',
          },
        },
        {
          path: '/offer/change/log',
          component: () => import('@/views/product/OfferChangeLog.vue'),
          meta: {
            title: 'Offer Change log',
          },
        },
        {
          path: '/offer/OperationLog',
          component: () => import('@/views/product/OperationLog.vue'),
          meta: {
            title: 'Operation Log',
          },
        },
        {
          path: '/report/index',
          component: () => import('@/views/report/blockReport.vue'),
          meta: {
            title: 'full Report',
          },
        },
        {
          path: '/report/hot',
          component: () => import('@/views/report/hotReport.vue'),
          meta: {
            title: 'Hot Report',
          },
        },
        {
          path: '/report/adv',
          component: () => import('@/views/report/AdvReport.vue'),
          meta: {
            title: 'Adv/Source Report',
          },
        },
        {
          path: '/report/aff',
          component: () => import('@/views/report/BillReport.vue'),
          meta: {
            title: 'Aff Report',
          },
        },
        {
          path: '/api/report/listRejectReport',
          component: () => import('@/views/report/RejectReport.vue'),
          meta: {
            title: 'Reject Report',
          },
        },
        {
          path: '/report/postbackReport',
          component: () => import('@/views/report/PostbackReport.vue'),
          meta: {
            title: 'Postback Report',
          },
        },
        {
          path: '/report/postbackWh',
          component: () => import('@/views/report/PostbackReportNew.vue'),
          meta: {
            title: 'New Postback Report',
          },
        },
        {
          path: '/report/conversionReport',
          component: () => import('@/views/report/ConversionReport.vue'),
          meta: {
            title: 'Conversion Report',
          },
        },
        {
          path: '/report/defaultTrcffic',
          component: () => import('@/views/report/DefaultTrafficReport.vue'),
          meta: {
            title: 'Default Traffic Report',
          },
        },
        {
          path: '/report/trackSource',
          component: () => import('@/views/report/trackSource.vue'),
          meta: {
            title: 'TrackSource',
          },
        },
        {
          path: '/report/appSource',
          component: () => import('@/views/report/AppSourceReport.vue'),
          meta: {
            title: 'TrackSource',
          },
        },
        {
          path: '/report/block',
          component: () => import('@/views/report/blockReport.vue'),
          meta: {
            title: 'full Report',
          },
        },
        {
          path: '/report/protect360',
          component: () => import('@/views/report/protect360.vue'),
          meta: {
            title: 'Block Report',
          },
        },
        {
          path: '/clickMixReport',
          component: () => import('@/views/report/ClickMixReport.vue'),
          meta: {
            title: 'Click Mix Report',
          },
        },
        {
          path: '/mgn/console-account',
          component: () => import('@/views/account/ConsoleAccount.vue'),
          meta: {
            title: 'Account',
          },
        },
        {
          path: '/permit/role-page',
          component: () => import('@/views/account/RoleRightsSetting.vue'),
          meta: {
            title: 'Role Setting',
          },
        },
        {
          path: '/permit/user-aff-source',
          component: () => import('@/views/account/AccountAff.vue'),
          meta: {
            title: 'User-Aff Setting',
          },
        },
        {
          path: '/Callback',
          component: () => import('@/views/integration/index.vue'),
          meta: {
            title: 'Callback Test / Check',
          },
        },
        {
          path: '/Confusion',
          component: () => import('@/views/confusion/index.vue'),
          meta: {
            title: 'Confusion',
          },
        },
        {
          path: '/AffsubLevel',
          component: () => import('@/views/affSubLevel/index.vue'),
          meta: {
            title: 'AffSub Level',
          },
        },
        {
          path: '/affiliateBilling',
          component: () => import('@/views/billing/AffiliateBilling.vue'),
          meta: {
            title: 'Affiliate Billing',
          },
        },
        {
          path: 'postbackBilling',
          component: () => import('@/views/billing/PostbackBilling.vue'),
          meta: {
            title: 'Postback Buckle Billing',
          },
        },
        {
          path: '/postback/manage',
          component: () => import('@/views/manage/index.vue'),
          meta: {
            title: 'Postback Manage',
          },
        },

        // setting
        {
          path: '/setting/whitelist',
          component: () => import('@/views/setting/whitelist.vue'),
          meta: {
            title: 'Reject Check WhiteList',
          },
        },
        // SourceProdWeight
        {
          path: '/setting/SourceProdWeight',
          component: () => import('@/views/setting/sourceProdWeight.vue'),
          meta: {
            title: 'source prod weight',
          },
        },
        // CountryProdCvr
        {
          path: '/setting/countryProdWright',
          component: () => import('@/views/setting/countryProdWeight.vue'),
          meta: {
            title: 'country prod cvr',
          },
        },
        {
          path: '/setting/supplementList',
          component: () => import('@/views/setting/supplementList.vue'),
          meta: {
            title: 'Sub Slow Black List',
          },
        },
        {
          path: '/versionLog',
          component: () => import('@/views/setting/versionLog.vue'),
          meta: {
            title: 'Version Log',
          },
        },
        {
          path: '/setting/adjustPB',
          component: () => import('@/views/setting/adjustPB.vue'),
          meta: {
            title: 'adjustPB List',
          },
        },
        {
          path: '/setting/adjustBlacklist',
          component: () => import('@/views/setting/adjustBlacklist.vue'),
          meta: {
            title: 'Adjust BlackList',
          },
        },
        {
          path: '/setting/algStrategy',
          component: () => import('@/views/setting/algStrategy.vue'),
          meta: {
            title: 'algStrategy List',
          },
        },
        {
          path: '/setting/offerPidNum',
          component: () => import('@/views/setting/offerPidNum.vue'),
          meta: {
            title: 'Offer Pid Number',
          },
        },
        {
          path: '/api/finance/accountForPayment',
          component: () => import('@/views/finance/accountForPayment'),
          meta: {
            title: 'Account For Payment',
          },
        },
        {
          path: '/api/finance/affiliate',
          component: () => import('@/views/finance/financeAffiliate'),
          meta: {
            title: 'Finance Affiliate',
          },
        },
        {
          path: '/api/finance/affiliateBill',
          component: () => import('@/views/finance/financeAffiliateBill'),
          meta: {
            title: 'Affiliate Bill',
          },
        },
        {
          path: '/api/finance/operation',
          component: () => import('@/views/finance/operationProcess'),
          meta: {
            title: 'Finance Operation',
          },
        },
        {
          path: '/api/finance/bussiness',
          component: () => import('@/views/finance/bussinessProcess'),
          meta: {
            title: 'Finance BD',
          },
        },
        {
          path: '/api/finance/contract',
          component: () => import('@/views/finance/contract'),
          meta: {
            title: 'Finance Contract',
          },
        },
        {
          path: '/api/finance/adv',
          component: () => import('@/views/finance/advInfo'),
          meta: {
            title: 'Finance Billing',
          },
        },
        {
          path: '/api/finance/Finance',
          component: () => import('@/views/finance/finance'),
          meta: {
            title: 'Finance Received',
          },
        },
        {
          path: '/api/finance/financeOverdue',
          component: () => import('@/views/finance/financeOverdue'),
          meta: {
            title: 'Finance Overdue',
          },
        },
        {
          path: '/api/finance/Approval',
          component: () => import('@/views/finance/financeApproval'),
          meta: {
            title: 'Finance Approval',
          },
        },
        {
          path: '/api/finance/affiliateApproval',
          component: () => import('@/views/finance/affiliateApproval'),
          meta: {
            title: 'Affiliate Approval',
          },
        },
        {
          path: '/api/finance/bdStatusApproval',
          component: () => import('@/views/finance/financeBDStatusApproval'),
          meta: {
            title: 'BD Status Approval',
          },
        },
        {
          path: '/api/finance/summary',
          component: () => import('@/views/finance/financeSummary'),
          meta: {
            title: 'Finance Summary',
          },
        },
        {
          path: '/api/finance/compare',
          component: () => import('@/views/finance/comparePrices'),
          meta: {
            title: 'compare prices',
          },
        },
        {
          path: '/click/offer',
          component: () => import('@/views/DDJ/offer/list.vue'),
          meta: {
            title: 'Offer',
          },
        },
        {
          path: '/click/offer/edit',
          component: () => import('@/views/DDJ/offer/edit.vue'),
          meta: {
            title: 'Offer Edit',
          },
        },
        {
          path: '/click/offer/add',
          component: () => import('@/views/DDJ/offer/add.vue'),
          meta: {
            title: 'Offer Edit',
          },
        },
        {
          path: '/click/task',
          component: () => import('@/views/DDJ/task/list.vue'),
          meta: {
            title: 'Task',
          },
        },
        {
          path: '/click/task/add',
          component: () => import('@/views/DDJ/task/add.vue'),
          meta: {
            title: 'AddTask',
          },
          hidden: true,
        },
        {
          path: '/click/task/edit',
          component: () => import('@/views/DDJ/task/edit.vue'),
          meta: {
            title: 'EditTask',
          },
          hidden: true,
        },
        {
          path: '/click/task/supplement',
          component: () => import('@/views/DDJ/task/supplement.vue'),
          meta: {
            title: 'Task补量',
          },
        },
        {
          path: '/click/offerClickCount',
          component: () => import('@/views/DDJ/clickCount.vue'),
          meta: {
            title: 'offerClickCount',
          },
        },
        {
          path: '/click/offerClickCount/supplement',
          component: () => import('@/views/DDJ/clickCountSupplement.vue'),
          meta: {
            title: '补量点击',
          },
        },
        {
          path: '/click/simpleStatistics',
          component: () => import('@/views/DDJ/simpleStatistics.vue'),
          meta: {
            title: 'offerClickCount',
          },
        },
        {
          path: '/click/postback',
          component: () => import('@/views/DDJ/postback.vue'),
          meta: {
            title: 'postback',
          },
        },
        {
          path: '/click/advertiser',
          component: () => import('@/views/DDJ/advertiser/list.vue'),
          meta: {
            title: 'advertiser',
          },
        },
        {
          path: '/click/advertiser/edit',
          component: () => import('@/views/DDJ/advertiser/edit.vue'),
          meta: {
            title: 'advertiserEdit',
          },
        },
        {
          path: '/click/advertiser/add',
          component: () => import('@/views/DDJ/advertiser/add.vue'),
          meta: {
            title: 'advertiserEdit',
          },
        },
        {
          path: '/click/emu',
          component: () => import('@/views/DDJ/emu/list.vue'),
          meta: {
            title: 'emu',
          },
        },
        {
          path: '/click/emu/add',
          component: () => import('@/views/DDJ/emu/add.vue'),
          meta: {
            title: 'emu Add',
          },
        },
        {
          path: '/click/emu/edit',
          component: () => import('@/views/DDJ/emu/edit.vue'),
          meta: {
            title: 'emu Edit',
          },
        },
        {
          path: '/click/emu/task',
          component: () => import('@/views/DDJ/emu/task.vue'),
          meta: {
            title: 'EMU Task',
          },
        },
        {
          path: '/click/country',
          component: () => import('@/views/DDJ/country/list.vue'),
          meta: {
            title: 'country List',
          },
        },
        {
          path: '/click/country/edit',
          component: () => import('@/views/DDJ/country/edit.vue'),
          meta: {
            title: 'country edit',
          },
        },
        {
          path: '/click/country/add',
          component: () => import('@/views/DDJ/country/add.vue'),
          meta: {
            title: 'country add',
          },
        },
        {
          path: '/click/data/filter',
          component: () => import('@/views/DDJ/data/filter.vue'),
          meta: {
            title: 'Data filter',
          },
        },
        {
          path: '/click/data/uploadList',
          component: () => import('@/views/DDJ/data/uploadList.vue'),
          meta: {
            title: 'upload List',
          },
        },
        {
          path: '/click/data/uploadList/edit',
          component: () => import('@/views/DDJ/data/edit.vue'),
          meta: {
            title: 'Data edit',
          },
        },
        {
          path: '/click/data/searchList',
          component: () => import('@/views/DDJ/data/searchList.vue'),
          meta: {
            title: 'search List',
          },
        },
        {
          path: '/click/data/search/view',
          component: () => import('@/views/DDJ/data/searchView.vue'),
          meta: {
            title: 'search view',
          },
        },
        {
          path: '/click/offer/v3',
          component: () => import('@/views/DDJ/v3/offer/list.vue'),
          meta: {
            title: 'Offer',
          },
        },
        {
          path: '/click/offer/edit/v3',
          component: () => import('@/views/DDJ/v3/offer/edit.vue'),
          meta: {
            title: 'Offer Edit',
          },
        },
        {
          path: '/click/offer/add/v3',
          component: () => import('@/views/DDJ/v3/offer/add.vue'),
          meta: {
            title: 'Offer Edit',
          },
        },
        {
          path: '/click/task/v3',
          component: () => import('@/views/DDJ/v3/task/list.vue'),
          meta: {
            title: 'Task',
          },
        },
        {
          path: '/click/task/add/v3',
          component: () => import('@/views/DDJ/v3/task/add.vue'),
          meta: {
            title: 'AddTask',
          },
          hidden: true,
        },
        {
          path: '/click/task/edit/v3',
          component: () => import('@/views/DDJ/v3/task/edit.vue'),
          meta: {
            title: 'EditTask',
          },
          hidden: true,
        },
        {
          path: '/click/task/supplement/v3',
          component: () => import('@/views/DDJ/v3/task/supplement.vue'),
          meta: {
            title: 'Task补量',
          },
        },
        {
          path: '/click/offerClickCount/v3',
          component: () => import('@/views/DDJ/v3/clickCount.vue'),
          meta: {
            title: 'offerClickCount',
          },
        },
        {
          path: '/click/offerClickCount/supplement/v3',
          component: () => import('@/views/DDJ/v3/clickCountSupplement.vue'),
          meta: {
            title: '补量点击',
          },
        },
        {
          path: '/click/simpleStatistics/v3',
          component: () => import('@/views/DDJ/v3/simpleStatistics.vue'),
          meta: {
            title: 'offerClickCount',
          },
        },
        {
          path: '/click/postback/v3',
          component: () => import('@/views/DDJ/v3/postback.vue'),
          meta: {
            title: 'postback',
          },
        },
        {
          path: '/click/advertiser/v3',
          component: () => import('@/views/DDJ/v3/advertiser/list.vue'),
          meta: {
            title: 'advertiser',
          },
        },
        {
          path: '/click/advertiser/edit/v3',
          component: () => import('@/views/DDJ/v3/advertiser/edit.vue'),
          meta: {
            title: 'advertiserEdit',
          },
        },
        {
          path: '/click/advertiser/add/v3',
          component: () => import('@/views/DDJ/v3/advertiser/add.vue'),
          meta: {
            title: 'advertiserEdit',
          },
        },
        {
          path: '/click/bundle/v3',
          component: () => import('@/views/DDJ/v3/amsBundle/list.vue'),
          meta: {
            title: 'AMS Bundle',
          },
        },
        {
          path: '/click/emu/v3',
          component: () => import('@/views/DDJ/v3/emu/list.vue'),
          meta: {
            title: 'emu',
          },
        },
        {
          path: '/click/emu/add/v3',
          component: () => import('@/views/DDJ/v3/emu/add.vue'),
          meta: {
            title: 'emu Add',
          },
        },
        {
          path: '/click/emu/edit/v3',
          component: () => import('@/views/DDJ/v3/emu/edit.vue'),
          meta: {
            title: 'emu Edit',
          },
        },
        {
          path: '/click/emu/task/v3',
          component: () => import('@/views/DDJ/v3/emu/task.vue'),
          meta: {
            title: 'EMU Task',
          },
        },
        {
          path: '/click/country/v3',
          component: () => import('@/views/DDJ/v3/country/list.vue'),
          meta: {
            title: 'country List',
          },
        },
        {
          path: '/click/country/edit/v3',
          component: () => import('@/views/DDJ/v3/country/edit.vue'),
          meta: {
            title: 'country edit',
          },
        },
        {
          path: '/click/country/add/v3',
          component: () => import('@/views/DDJ/v3/country/add.vue'),
          meta: {
            title: 'country add',
          },
        },
        {
          path: '/click/data/filter/v3',
          component: () => import('@/views/DDJ/v3/data/filter.vue'),
          meta: {
            title: 'Data filter',
          },
        },
        {
          path: '/click/data/uploadList/v3',
          component: () => import('@/views/DDJ/v3/data/uploadList.vue'),
          meta: {
            title: 'upload List',
          },
        },
        {
          path: '/click/data/uploadList/edit/v3',
          component: () => import('@/views/DDJ/v3/data/edit.vue'),
          meta: {
            title: 'Data edit',
          },
        },
        {
          path: '/click/data/searchList/v3',
          component: () => import('@/views/DDJ/v3/data/searchList.vue'),
          meta: {
            title: 'search List',
          },
        },
        {
          path: '/click/data/search/view/v3',
          component: () => import('@/views/DDJ/v3/data/searchView.vue'),
          meta: {
            title: 'search view',
          },
        },
        {
          path: '/report/device/active',
          component: () => import('@/views/DDJ/v3/deviceActive.vue'),
          meta: {
            title: 'Device active',
          },
        },
        {
          path: '/report/device/overlap',
          component: () => import('@/views/DDJ/v3/deviceOverlap.vue'),
          meta: {
            title: 'Device Overlap Rate',
          },
        },

        {
          path: '/click/dmp/filter',
          component: () => import('@/views/DDJ/v3/data/dmpFilter.vue'),
          meta: {
            title: 'DMP数据包过滤',
          },
        },
        {
          path: '/click/dmp/upload/list',
          component: () => import('@/views/DDJ/v3/data/dmpUploadList.vue'),
          meta: {
            title: 'DMP数据包列表',
          },
        },

        {
          path: '/click/allpb/report',
          component: () => import('@/views/DDJ/v3/allpbReport.vue'),
          meta: {
            title: '全回调数据统计',
          },
        },

        {
          path: '/click/dmp/upload/edit',
          component: () => import('@/views/DDJ/v3/data/dmpFilterEdit.vue'),
          meta: {
            title: 'DMP数据包修改',
          },
        },

        {
          path: '/click/holo/filter',
          component: () => import('@/views/DDJ/v3/data/holoFilter.vue'),
          meta: {
            title: 'Holo数据包过滤',
          },
        },

        {
          path: '/click/holo/upload/edit',
          component: () => import('@/views/DDJ/v3/data/holoFilterEdit.vue'),
          meta: {
            title: 'Holo数据包修改',
          },
        },

        {
          path: '/click/holo/upload/list',
          component: () => import('@/views/DDJ/v3/data/holoUploadList.vue'),
          meta: {
            title: 'Holo数据包列表',
          },
        },

        {
          path: '/click/alg/upload/list',
          component: () => import('@/views/DDJ/v3/data/algUploadList.vue'),
          meta: {
            title: '算法数据包列表',
          },
        },

        {
          path: '/pid/click/cap/list',
          component: () => import('@/views/setting/pidClickCapList.vue'),
          meta: {
            title: 'PID click cap 列表',
          },
        },

        {
          path: '/setting/repalceAutoRule',
          component: () => import('@/views/setting/replaceAutoMixRule.vue'),
          meta: {
            title: 'replace AutoMix rule',
          },
        },

        {
          path: '/setting/subCloseSetting',
          component: () => import('@/views/setting/subCloseSetting.vue'),
          meta: {
            title: 'Sub Slow Close Setting',
          },
        },
        {
          path: '/setting/algorithmSetting',
          component: () => import('@/views/setting/algorithmSetting.vue'),
          meta: {
            title: 'Algorithm Setting',
          },
        },

        {
          path: '/report/ctit',
          component: () => import('@/views/report/CTITReport.vue'),
          meta: {
            title: 'CTIT Report',
          },
        },
        {
          path: '/report/appsflyer',
          component: () => import('@/views/report/AppsflyerReport.vue'),
          meta: {
            title: 'Appsflyer Report',
          },
        },
        {
          path: '/report/appsflyer/pa',
          component: () => import('@/views/report/AppsflyerPaReport.vue'),
          meta: {
            title: 'Appsflyer pa',
          },
        },
        {
          path: '/404',
          component: () => import('@/views/errorPage/404.vue'),
          meta: {
            title: '404',
          },
        },
        {
          path: '/403',
          component: () => import('@/views/errorPage/403.vue'),
          meta: {
            title: '403',
          },
        },
      ],
    },
    {
      path: '/login',
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});

export const setTitle = function(title) {
  if (title == 'undefined') {
    window.document.title = 'Management Console';
    return;
  }
  // title = title ? `${title}` : 'Management Console'
  window.document.title = title;
};

myRouter.beforeEach((to, from, next) => {
  NProgress.start();
  const browserHeaderTitle = to.meta.title;
  sessionStorage.setItem('store', browserHeaderTitle);
  store.commit('app/SET_BROWSERHEADERTITLE', {
    browserHeaderTitle: browserHeaderTitle,
  });
  next();
});

myRouter.afterEach(() => {
  NProgress.done(); // 结束Progress
  setTimeout(() => {
    // const browserNewHeaderTitle = store.getters.browserHeaderTitle
    setTitle(sessionStorage.getItem('store'));
  }, 0);
});
export default myRouter;
