import FingerprintJS from '@fingerprintjs/fingerprintjs';

const state = {
  fingerprint: null,
};

const mutations = {
  SET_FINGERPRINT(state, fingerprint) {
    state.fingerprint = fingerprint;
  },
};

const actions = {
  async fetchFingerprint({ commit }) {
    try {
      // 初始化 FingerprintJS
      const fpPromise = FingerprintJS.load();

      // 获取指纹
      const fp = await fpPromise;
      const result = await fp.get();

      // 提交指纹到 Vuex
      commit('SET_FINGERPRINT', result.visitorId); // 使用 visitorId 或其他需要的信息
      console.log('Fingerprint:', result.visitorId);
    } catch (error) {
      console.error('Error fetching fingerprint:', error);
    }
  },
};

const getters = {
  getFingerprint: (state) => state.fingerprint,
};

export default {
  namespaced: true, // 启用命名空间以避免名称冲突
  state,
  mutations,
  actions,
  getters,
};
