<template>
  <div>
    <el-form label-width="70px" :model="filter" size="mini">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="SourceIds">
            <group-select
              v-model="filter.sourceIds"
              :data-source="sourcesList"
              multiple
              reserve-keyword
              :loading="sourceLoading"
              clearable
              filterable
              size="mini"
              class="w100"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="AffSub">
            <el-input v-model="filter.affSub"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="getSourceSubBlacklist(1)">Search</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form label-width="70px" :model="affSubSourceBlack" size="mini">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="SourceId">
            <group-select
              v-model="affSubSourceBlack.sourceId"
              :data-source="sourcesList"
              reserve-keyword
              :loading="sourceLoading"
              clearable
              filterable
              class="w100"
              size="mini"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="AffSub">
            <el-input v-model="affSubSourceBlack.affSub"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Description" label-width="100px">
            <el-input v-model="affSubSourceBlack.description"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="addSourceSubBlacklist">Add</el-button>
            <el-button type="primary" @click="delBlackSubClick">Delete Batch</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="affSubSourceBlackList"
      border
      stripe
      style="width:100%;"
      size="mini"
      @selection-change="handleaffSubSourceSelectionChange"
      highlight-current-row
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>
      <el-table-column label="SourceIds" width="100" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toSourceDetailClick(scope.row)">{{
            scope.row.sourceId
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Source Name" width="150" align="center">
        <template slot-scope="scope">
          <span class="cor337ab7" @click="toSourceDetailClick(scope.row)">{{
            scope.row.sourceName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="AffSub" prop="affSub" align="center"></el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Operator" prop="operator" align="center"></el-table-column>
      <el-table-column
        label="OperateTime"
        prop="operateTime"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="blackAffSubtotal"
      @handleSizeChange="handleblackAffSubSizeChange"
      @handleCurrentChange="handleblackAffSubCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <el-dialog title="Delete" width="40%" :visible.sync="delBlackSubSourceVisible" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delBlackSubSourceVisible = false">Cancel</el-button>
        <el-button type="primary" @click="delBlackSubSource">Delete</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import {
    getSourceSubBlacklist,
    addSourceSubBlacklist,
    deleteSourceSubBlacklist,
  } from 'api/affiliate/detail';

  let clickTimer = null;
  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        filter: {},
        affSubSourceBlack: {},
        affSubSourceBlackList: [],
        blackAffSubtotal: null,
        currentPage: 1,
        pageSize: 20,
        delBlackSubSourceVisible: false,
        blackAffSubSelectionList: [],
        delArr: [],
      };
    },
    computed: {
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      this.getSourceIdsList();
      this.getSourceSubBlacklist();
    },
    methods: {
      ...mapActions('source', ['getSourceIdsList']),
      getSourceSubBlacklist() {
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.filter.sourceIds) {
          param.sourceIds = this.filter.sourceIds.join(',');
        }
        if (this.filter.affSub) {
          param.affSub = this.filter.affSub;
        }
        getSourceSubBlacklist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.affSubSourceBlackList = response.result;
            this.blackAffSubtotal = response.total;
          }
        });
      },
      addSourceSubBlacklist() {
        const param = {
          ...this.affSubSourceBlack,
        };
        addSourceSubBlacklist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getSourceSubBlacklist();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },

      delBlackSubClick() {
        const length = this.blackAffSubSelectionList.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.delBlackSubSourceVisible = true;
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.blackAffSubSelectionList[i].id);
        }
      },
      delBlackSubSource() {
        const ids = this.delArr.join(',');
        deleteSourceSubBlacklist(this.affiliateId, ids).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getSourceSubBlacklist();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delBlackSubSourceVisible = false;
        this.delArr = [];
      },
      handleaffSubSourceSelectionChange(val) {
        this.blackAffSubSelectionList = val;
      },
      handleblackAffSubSizeChange(val) {
        this.pageSize = val;
        this.handleblackAffSubCurrentChange(1);
      },
      handleblackAffSubCurrentChange(val) {
        this.currentPage = val;
        this.getSourceSubBlacklist();
      },
      toSourceDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        clickTimer = window.setTimeout(() => {
          this.$nextTick(() => {
            const { href } = this.$router.resolve({
              path: '/source/detail',
              query: {
                sourceId: row.sourceId,
                sourceName: row.sourceName,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
    },
  };
</script>

<style></style>
