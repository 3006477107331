<template>
  <div class="consolAccount">
    <!-- <div class="main-Title bgff"><h2>Account</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form label-width="120px" :model="consoleAccount" size="mini">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Account ID" class="mb0" label-width="90px">
                <el-input
                  v-model="consoleAccount.accountId"
                  placeholder="account id regex here ..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Account Name" class="mb0">
                <el-input
                  v-model="consoleAccount.accountName"
                  placeholder="account name regex here ..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item class="mb0" label-width="0px">
                <el-button type="primary" :loading="listLoading" @click="searchListClick(1)"
                  >In Browser</el-button
                >
                <el-button type="primary" icon="el-icon-plus" @click="addClick">Create</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          style="width:100%;"
          size="mini"
          stripe
          border
          :data="consoleAccountList"
          highlight-current-row
        >
          <el-table-column type="expand">
            <template slot-scope="scopes">
              <el-table :data="scopes.row.roles" border stripe>
                <el-table-column label="Role Id" prop="id" align="center"></el-table-column>
                <el-table-column label="Role Name" prop="roleName" align="center"></el-table-column>
                <el-table-column label="Role Desc" prop="roleDesc" align="center"></el-table-column>
                <!-- <el-table-column label="createTime" prop="Create Time"></el-table-column> -->
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="Account ID" prop="accountId" align="center"></el-table-column>
          <el-table-column
            label="Account Name"
            prop="username"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column label="Open Source" prop="isOpenAllAdv" align="center" min-width="70">
            <template slot-scope="scope">
              <span v-if="scope.row.isOpenAllAdv === 1">open all source</span>
              <span v-else-if="scope.row.isOpenAllAdv === 2">open overseas source</span>
              <span v-else-if="scope.row.isOpenAllAdv === 3">open domestic source</span>
              <span v-else>No</span>
            </template>
          </el-table-column>
          <el-table-column label="Open Affiliate" prop="isOpenAllAff" align="center" min-width="70">
            <template slot-scope="scope">
              <span v-if="scope.row.isOpenAllAff > 0">Yes</span>
              <span v-else>No</span>
            </template>
          </el-table-column>
          <el-table-column label="Description" prop="description" align="center"></el-table-column>
          <el-table-column label="Action" align="center">
            <template slot-scope="scope">
              <el-dropdown placement="right-start">
                <el-button type="primary" size="small">
                  More Action<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    ><el-button
                      type="primary"
                      size="small"
                      :style="{ width: '100%', marginTop: '5px' }"
                      @click="updateClick(scope.row)"
                      >Add Or Update Role</el-button
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><el-button
                      type="primary"
                      size="small"
                      :style="{ width: '100%', marginTop: '5px' }"
                      @click="updateAccountClick(scope.row)"
                      >Update</el-button
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><el-button
                      type="danger"
                      size="small"
                      :style="{ width: '100%', marginTop: '5px' }"
                      @click="delAccountClick(scope.row)"
                      >Delete</el-button
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><el-button
                      type="danger"
                      size="small"
                      :style="{ width: '100%', marginTop: '5px' }"
                      @click="unlinkClick(scope.row)"
                      >unlinkValidator</el-button
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageSize"
          :currentPage="currentPage"
        ></pagination>
      </el-card>
    </div>
    <!-- 模块框 -->
    <el-dialog
      :title="dialogType === 'new' ? 'Create Account' : 'Update Account'"
      width="60%"
      :visible.sync="dialogVisible"
    >
      <el-form :model="account" label-width="120px" size="small">
        <template v-if="dialogType === 'new'">
          <el-row>
            <el-col :span="12">
              <el-form-item label="Account Name">
                <el-input
                  v-model="account.username"
                  placeholder="affiliate name here ..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Password">
                <el-input
                  v-model="account.password"
                  placeholder="affiliate password here ..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Description">
                <el-input
                  v-model="account.description"
                  placeholder="description here ..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Account Role">
                <el-select v-model="account.roles" multiple class="w100">
                  <el-option
                    v-for="item in roleOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Open Sources">
                <el-select v-model="account.isOpenAllAdv" placeholder="Sources" class="w100">
                  <el-option
                    v-for="item in isOpenAllSourceOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Open Affiliates">
                <el-select v-model="account.isOpenAllAff" placeholder="Affiliates" class="w100">
                  <el-option
                    v-for="item in isOpenAllAffOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <el-row v-if="dialogType === 'edit'">
          <el-col :span="12">
            <el-form-item label="Description">
              <el-input v-model="account.description" placeholder="description here..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Open Sources">
              <el-select v-model="account.isOpenAllAdv" placeholder="Sources" class="w100">
                <el-option
                  v-for="item in isOpenAllSourceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Open Affiliates">
              <el-select v-model="account.isOpenAllAff" placeholder="Affiliates" class="w100">
                <el-option
                  v-for="item in isOpenAllAffOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Account Name">
              <el-input v-model="account.username" placeholder="affiliate name here ..."></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" align="right">
            <el-form-item label-width="0px" class="mb0">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="confirmClick">确 定</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 修改角色模块框 -->
    <el-dialog title="Update Role" width="40%" :visible.sync="updateVisible">
      <el-form :model="updateRole" label-position="left" label-width="100px" size="small">
        <el-row>
          <el-col :span="24">
            <el-form-item label="Account Role" label-width="100px">
              <el-select v-model="updateRole.roleId" class="w100" multiple>
                <el-option
                  v-for="item in roleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" align="right">
            <el-form-item label-width="0px" class="mb0">
              <el-button @click="updateVisible = false">取 消</el-button>
              <el-button type="primary" @click="confirmUpdateClick">确 定</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delVisible" width="300px" center append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">cancel</el-button>
        <el-button type="primary" @click="delRow">confirm</el-button>
      </span>
    </el-dialog>
    <!-- 解绑身份验证器 -->
    <el-dialog title="unLink" :visible.sync="unLinkVisible" width="300px" center append-to-body>
      <div class="del-dialog-cnt">确认解绑身份验证器？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="unLinkVisible = false">cancel</el-button>
        <el-button type="primary" @click="unlinkValidatorCode">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import ConsoleAccountCon from '../controllers/account/ConsoleAccount';
  export default {
    name: 'ConsoleAccount',
    ...ConsoleAccountCon,
  };
</script>
<style lang="scss" scoped></style>
