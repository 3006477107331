<template>
  <div>
    <el-form :model="sources" size="mini">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="SourceId" label-width="70px" style="margin-left:4px;">
            <group-select
              v-model="sources.sourceId"
              :data-source="sourcesList"
              :loading="sourceLoading"
              clearable
              filterable
              class="w100"
              placeholder="请选择"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="PerOfferCapDaily" label-width="120px">
            <el-input v-model="sources.perOfferCapDaily"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="ConvDailyCap" label-width="100px">
            <el-input v-model="sources.conversionCapDaily"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="AccountDailyCap" label-width="120px">
            <el-input v-model="sources.accountCapDaily"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <el-form-item>
            <el-button type="primary" @click="addSourceWhiteClick">Add</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form size="mini">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="SourceIds" label-width="70px">
            <group-select
              v-model="sourceIds"
              :data-source="sourcesList"
              multiple
              :loading="sourceLoading"
              clearable
              reserve-keyword
              filterable
              class="w100"
              size="mini"
              placeholder="请选择"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="getSourceWhiteList(1)">Search</el-button>
            <el-button type="primary" @click="delClick">Delete</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      border
      stripe
      :data="whiteList"
      highlight-current-row
      style="width: 100%"
      size="mini"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="SourceId" prop="sourceId" align="center"></el-table-column>
      <el-table-column label="SourceName" prop="sourceName" align="center"></el-table-column>
      <el-table-column
        label="PerOfferCapDaily (Per Offer)"
        prop="perOfferCapDaily"
        align="center"
      ></el-table-column>
      <el-table-column label="ConvDailyCap (Total Per Offer)" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.conversionCapDaily }}</span>
        </template>
      </el-table-column>
      <el-table-column label="AccountDailyCap (Total Source)" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.accountCapDaily }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Operator" prop="operator" align="center"></el-table-column>
      <el-table-column label="Operate Time" prop="operateTime" align="center"></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="sourceWhiteTotal"
      @handleSizeChange="handlesourceWhiteSizeChange"
      @handleCurrentChange="handlesourceWhiteCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- scourceDelClick删除模块框 -->
    <el-dialog title="Delete" :visible.sync="delSourceVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delSourceVisible = false">cancel</el-button>
        <el-button type="primary" @click="delScource">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import { mapState, mapActions } from 'vuex';
  import {
    getSourceWhiteList,
    addSourceWhiteList,
    deletSourceWhiteList,
  } from 'api/affiliate/detail';
  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        sources: {},
        sourceId: null,
        sourceIds: [],
        whiteList: [],
        sourceWhiteTotal: null,
        currentPage: 1,
        pageSize: 20,
        delSourceVisible: false,
        selectIds: [],
      };
    },
    computed: {
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      this.getSourceIdsList();
      this.getSourceWhiteList();
    },
    methods: {
      ...mapActions('source', ['getSourceIdsList']),
      getSourceWhiteList(page) {
        if (page) {
          this.currentPage = page;
        }
        const param = {
          sourceIds: this.sourceIds.join(','),
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getSourceWhiteList(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.whiteList = response.result;
            this.sourceWhiteTotal = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      addSourceWhiteClick() {
        const param = {
          ...this.sources,
        };
        addSourceWhiteList(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getSourceWhiteList();
          } else {
            this.$message.error(response.message + '(检查是否有该 SourceId)');
          }
        });
      },
      scourceDelClick(row) {
        this.sourceId = row.sourceId;
        this.delSourceVisible = true;
      },
      delClick() {
        if (this.selectIds.length <= 0) {
          this.$message.warning('你还没有选择要删除的SourceId哦~');
          return;
        }
        this.delSourceVisible = true;
      },
      delScource() {
        const sourceIds = this.selectIds.join(',');
        deletSourceWhiteList(this.affiliateId, sourceIds).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getSourceWhiteList();
            this.sourceIds = [];
            this.delSourceVisible = false;
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      handleSelectionChange(val) {
        this.selectIds = val.map((item) => item.sourceId);
      },
      handlesourceWhiteSizeChange(val) {
        this.pageSize = val;
        this.handlesourceWhiteCurrentChange(1);
      },
      handlesourceWhiteCurrentChange(val) {
        this.currentPage = val;
        this.getSourceWhiteList();
      },
    },
  };
</script>

<style></style>
