<template>
  <div>
    <div>
      <el-select v-model="date" @change="dateChange" size="mini" class="mb10">
        <el-option
          v-for="item in dateTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <div id="topOffercharts">
      <div id="offerContainer" ref="offerContainer" style="weight:700px;height:600px;"></div>
    </div>
  </div>
</template>

<script>
  import echarts from '@/setup/echarts';
  import { reloadTopOfferGeos } from '@/api/affiliate/detail';

  export default {
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        date: 'Recent-24-hours',
        dateTypeOptions: [
          {
            label: 'Recent-24-hours',
            value: 'Recent-24-hours',
          },
          {
            label: 'Today',
            value: 'Today',
          },
          {
            label: 'Yesterday',
            value: 'Yesterday',
          },
          {
            label: 'Recent-3-days',
            value: 'Recent-3-days',
          },
          {
            label: 'Recent-7-days',
            value: 'Recent-7-days',
          },
        ],
      };
    },
    mounted() {
      this.reloadTopOfferGeos();
    },
    methods: {
      drawtopofferChart(data) {
        let myChart = echarts.init(this.$refs.offerContainer);
        myChart.showLoading();
        let maxOfferSize = 10;
        for (const key in data.offerCategory) {
          const t = data.offerCategory[key];
          if (t > maxOfferSize) maxOfferSize = t;
        }
        let maxCountrySize = 10;
        for (const key in data.countryCategory) {
          const t = data.countryCategory[key];
          if (t > maxCountrySize) maxCountrySize = t;
        }
        myChart.setOption({
          title: [
            {
              text: 'Top offers with category',
              /* subtext: '总计 ' + builderJson.all, */
              x: '25%',
              textAlign: 'center',
            },
            {
              text: 'Categories',
              subtext:
                '总计 ' +
                Object.keys(data.category ?? {}).reduce(function(all, key) {
                  return all + data.category[key];
                }, 0),
              x: '75%',
              textAlign: 'center',
            },
            {
              text: 'Countries',
              subtext:
                '总计 ' +
                Object.keys(data.country ?? {}).reduce(function(all, key) {
                  return all + data.country[key];
                }, 0),
              x: '75%',
              y: '52%',
              textAlign: 'center',
            },
          ],
          tooltip: {},
          grid: [
            {
              top: 50,
              width: '50%',
              bottom: '45%',
              left: 10,
              containLabel: true,
            },
            {
              top: '60%',
              width: '50%',
              bottom: 34,
              left: 10,
              containLabel: true,
            },
          ],
          xAxis: [
            {
              type: 'value',
              max: maxOfferSize,
              splitLine: {
                show: false,
              },
            },
            {
              type: 'value',
              max: maxCountrySize,
              gridIndex: 1,
              splitLine: {
                show: false,
              },
            },
          ],
          yAxis: [
            {
              type: 'category',
              data: Object.keys(data.offerCategory ?? {}),
              axisLabel: {
                interval: 0,
                rotate: 30,
              },
              splitLine: {
                show: false,
              },
            },
            {
              gridIndex: 1,
              type: 'category',
              data: Object.keys(data.countryCategory ?? {}),
              axisLabel: {
                interval: 0,
                rotate: 30,
              },
              splitLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              type: 'bar',
              stack: 'chart',
              z: 3,
              label: {
                normal: {
                  position: 'right',
                  show: true,
                },
              },
              data: Object.keys(data.offerCategory ?? {}).map(function(key) {
                return data.offerCategory[key];
              }),
            },
            {
              type: 'bar',
              stack: 'chart',
              silent: true,
              itemStyle: {
                normal: {
                  color: '#eee',
                },
              },
              data: Object.keys(data.offerCategory ?? {}).map(function(key) {
                return 10000 - data.offerCategory[key];
              }),
            },
            {
              type: 'bar',
              stack: 'component',
              xAxisIndex: 1,
              yAxisIndex: 1,
              z: 3,
              label: {
                normal: {
                  position: 'right',
                  show: true,
                },
              },
              data: Object.keys(data.countryCategory ?? {}).map(function(key) {
                return data.countryCategory[key];
              }),
            },
            {
              type: 'bar',
              stack: 'component',
              silent: true,
              xAxisIndex: 1,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: '#eee',
                },
              },
              data: Object.keys(data.countryCategory ?? {}).map(function(key) {
                return 10000 - data.countryCategory[key];
              }),
            },
            {
              type: 'pie',
              radius: [0, '30%'],
              center: ['75%', '25%'],
              data: Object.keys(data.category ?? {}).map(function(key) {
                return {
                  name: key.replace('.js', ''),
                  value: data.category[key],
                };
              }),
            },
            {
              type: 'pie',
              radius: [0, '30%'],
              center: ['75%', '75%'],
              data: Object.keys(data.country ?? {}).map(function(key) {
                return {
                  name: key.replace('.js', ''),
                  value: data.country[key],
                };
              }),
            },
          ],
        });

        myChart.hideLoading();
      },
      reloadTopOfferGeos() {
        reloadTopOfferGeos(this.affiliateId, this.date).then((response) => {
          if (response.code === 200) {
            const result = response.result;
            if (Object.keys(result).length !== 0) {
              this.drawtopofferChart(result);
            }
          }
        });
      },
      dateChange(val) {
        this.reloadTopOfferGeos(val);
      },
    },
  };
</script>

<style lang="scss">
  #topOffercharts {
    width: 96%;
    height: 640px;
    background: $base-white;
    padding-top: 20px;
    -webkit-box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);
  }
</style>
