<template>
  <div class="login-container">
    <div class="loginBox">
      <h3 class="login_title"><span>login</span><br />{{ title }}</h3>
      <p>welcome back<br />please login to your acount</p>
      <el-form
        label-position="left"
        :model="loginForm"
        :rules="loginRules"
        ref="loginForm"
        class="loginForm"
      >
        <div class="inputBox">
          <el-form-item prop="username" class="mb0" label="Username">
            <!-- <span class="svg-container svg-container_login">
              <svg-icon icon-class="user" />
            </span> -->
            <el-input
              name="username"
              type="text"
              v-model="loginForm.username"
              placeholder="Whrite your username"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" class="mb0" label="Password">
            <!-- <span class="svg-container">
              <svg-icon icon-class="password" />
            </span> -->
            <el-input
              name="password"
              :type="pwdType"
              @keyup.enter.native="login('loginForm')"
              v-model="loginForm.password"
              placeholder="Whrite your password"
            ></el-input>
            <span class="show-pwd" @click="showPwd">
              <svg-icon icon-class="eye" />
            </span>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            class="login_button"
            :loading="loading"
            @click.native.prevent="isSecretKeyButton('loginForm')"
            >Login
          </el-button>
        </el-form-item>
        <div class="tips">{{ company }}</div>
      </el-form>
      <!-- 二维码绑定窗口 -->
      <el-dialog
        :visible.sync="showQRCodeDialog"
        :title="'用户绑定'"
        :close-on-press-escape="false"
        :show-close="false"
        :close-on-click-modal="false"
        append-to-body
        width="30%"
      >
        <div style="text-align: left;">
          请用手机上的Microsoft Authenticator，请扫描完成进行下一步，否则将无法登录。扫码绑定:
          <div id="divQrCode" name="divQrCodeName" class="divQrCodeCss"></div>
          <img
            style="cursor: pointer; height: 250px; width: 250px; vertical-align: middle"
            :src="beinetCodeBase64"
            alt="二维码"
          />
          <div> <p>请下载并安装 Microsoft Authenticator。</p></div>
          <div>
            <a
              href="https://www.microsoft.com/zh-cn/security/mobile-authenticator-app"
              target="_blank"
              style="color: #007eed"
            >
              https://www.microsoft.com/zh-cn/security/mobile-authenticator-app
            </a>
          </div>
        </div>
        <div class="dialog-footer" slot="footer">
          <el-button @click="showOTPCodeWin" icon="el-icon-success" type="primary"
            >已扫码完成
          </el-button>
        </div>
      </el-dialog>

      <!-- OTPCode输入窗口 -->
      <el-dialog
        :visible.sync="showOTPCodeDialog"
        :title="'输入验证码'"
        :close-on-press-escape="false"
        :show-close="false"
        :close-on-click-modal="false"
        append-to-body
        width="25%"
        center
      >
        <el-row>
          <el-col>
            <el-input
              @keyup.enter.native="login('loginForm')"
              placeholder="请输入 Authentication code"
              v-model="loginForm.code"
              autofocus
            ></el-input>
          </el-col>
        </el-row>
        <div class="dialog-footer" slot="footer">
          <el-button
            @click="login('loginForm')"
            :loading="loading"
            icon="el-icon-success"
            type="primary"
            >验 证
          </el-button>
          <el-button @click="showOTPCodeDialog = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import { getMenuList, generate_mfa, validate_mfa, isSecretKey } from '@/api/user';
  import FingerprintJS from '@fingerprintjs/fingerprintjs';

  export default {
    data() {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.loginForm.checkPass !== '') {
            this.$refs.loginForm.validateField('checkPass');
          }
          callback();
        }
      };
      const validateName = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入用户名'));
        } else {
          callback();
        }
      };
      return {
        title: 'Management Console',
        company: '',
        loading: false,
        showQRCodeDialog: false,
        showOTPCodeDialog: false,
        pwdType: 'password',
        beinetCodeBase64: '/favicon.ico',
        loginForm: {
          username: '',
          password: '',
          code: '',
          fingerprint: '',
        },
        loginRules: {
          password: [{ validator: validatePass, trigger: 'blur' }],
          username: [{ validator: validateName, trigger: 'blur' }],
        },
      };
    },
    mounted() {
      this.getFingerpring();
    },
    methods: {
      showPwd() {
        if (this.pwdType === 'password') {
          this.pwdType = '';
        } else {
          this.pwdType = 'password';
        }
      },
      async getFingerpring() {
        const fpPromise = FingerprintJS.load();
        // 获取指纹
        const fp = await fpPromise;
        const result = await fp.get();
        this.loginForm.fingerprint = result.visitorId;
      },
      async getURl() {
        const promise = new Promise((resolve) => {
          getMenuList.securityService.getMenu({}, (response) => {
            if (response.status.code === 0) {
              const menuList = response.data.data;
              if (Array.isArray(menuList[0].subs) && menuList[0].subs.length > 0) {
                resolve(menuList[0].subs[0]);
              } else {
                resolve(menuList[0]);
              }
            } else {
              resolve({ index: '/DashBoard', title: 'DashBoard' });
            }
          });
        });
        return promise;
      },
      async isSecretKeyButton(formName) {
        var that = this;
        that.$refs[formName].validate((valid) => {
          if (valid) {
            const userInfo = {
              username: this.loginForm.username,
              password: this.loginForm.password,
            };
            this.loading = true;
            isSecretKey(userInfo)
              .then(
                (res) => {
                  if (res.code === 203) {
                    generate_mfa(userInfo)
                      .then((res) => {
                        this.showQRCodeDialog = true;
                        let qrCodeUrl = URL.createObjectURL(res);
                        this.beinetCodeBase64 = qrCodeUrl;
                      })
                      .catch((e) => {
                        this.$message.error(e);
                      });
                  } else if (res.code === 200) {
                    this.showOTPCodeDialog = true;
                    this.loginForm.code = '';
                  } else {
                    this.$message.error(res.message);
                  }

                  this.loading = false;
                  sessionStorage.setItem('imgUrl', res.logo);
                },
                (error) => {
                  this.loading = false;
                  this.$message.error(error);
                  return Promise.reject(error);
                }
              )
              .then(
                (res) => {
                  console.log(res);
                },
                (error) => {
                  console.log(error);
                }
              );
          } else {
            that.$message.error('请输入账号或密码');
            return false;
          }
        });
      },
      async login(formName) {
        var that = this;
        that.$refs[formName].validate((valid) => {
          if (valid) {
            const userInfo = {
              username: this.loginForm.username,
              password: this.loginForm.password,
              code: this.loginForm.code,
              fingerprint: this.loginForm.fingerprint,
            };
            this.loading = true;
            this.$store
              .dispatch('user/login', userInfo)
              .then(
                (res) => {
                  this.loading = false;
                  sessionStorage.setItem('imgUrl', res.logo);
                  sessionStorage.setItem('userName', this.loginForm.username);
                  sessionStorage.setItem('password', this.loginForm.password);
                  return this.$store.dispatch('app/getMenuList', true);
                },
                (error) => {
                  this.loading = false;
                  this.$message.error(error);
                  return Promise.reject(error);
                }
              )
              .then(
                (res) => {
                  console.log(res);
                  this.$router.push(res[0].index);
                },
                (error) => {
                  console.log(error);
                }
              );
          } else {
            that.$message.error('请输入验证码');
            return false;
          }
        });
      },
      showOTPCodeWin: function() {
        this.showQRCodeDialog = false;
        this.showOTPCodeDialog = true;
      },
      validateCode: function() {
        const userInfo = {
          username: this.loginForm.username,
          password: this.loginForm.password,
          code: this.loginForm.code,
        };
        validate_mfa(userInfo)
          .then((res) => {
            if (res === true) {
              this.showOTPCodeDialog = false;
              this.loading = false;
              sessionStorage.setItem('userName', this.loginForm.username);
              sessionStorage.setItem('password', this.loginForm.password);
              sessionStorage.setItem('imgUrl', res.logo);
              return this.$store.dispatch('app/getMenuList', true);
            }
          })
          .catch((e) => {
            this.$message.error(e);
          });
      },
    },
  };
</script>

<style rel="stylesheet/scss" lang="scss">
  $bg: #2d3a4b;
  $light_gray: #666;
  /* reset element-ui css */
  .login-container {
    .el-input {
      display: inline-block;
      height: 35px;
      width: 90%;
      background: rgba(255, 255, 255, 0.1) !important;

      input {
        background: transparent !important;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 0;
        color: $light_gray !important;
        height: 35px;

        &:-webkit-autofill {
          box-shadow: 0px 0px 0px 1000px #499de7 inset;
          -webkit-text-fill-color: #fff !important;
        }

        &:-webkit-input-placeholder {
          color: #fff !important;
        }
      }
    }

    .el-form-item {
      .el-form-item__label {
        line-height: 30px;
      }

      .el-form-item__error {
        color: #f56c6c !important;
        font-size: 12px !important;
        line-height: 1;
        padding-top: 0;
        position: absolute;
        font-weight: bold;
        top: 60%;
        left: 70%;
      }
    }
  }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
  $bg: #2196f3;
  $dark_gray: #889aa4;
  $light_gray: #eee;
  .login-container {
    position: fixed;
    height: 100%;
    width: 100%;
    background: url(../assets/img/login_bg.jpg) no-repeat left center;
    background-size: cover;
    display: table;

    .loginBox {
      height: 448px;
      padding: 0 0 0 20.3125%;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 10px;

      p {
        font-size: 0.9375vw;
        line-height: 20px;
        color: #919297;
        margin-bottom: 40px;
      }

      .loginForm {
        width: 365px;

        .inputBox {
          box-shadow: 0px 10px 47px rgba(0, 0, 0, 0.16);
          opacity: 1;
          border-radius: 10px;
          overflow: hidden;
          padding: 10px 19px;
          margin-bottom: 30px;
        }
      }
    }

    .login_button {
      width: 100%;
      height: 47px;
      background: #1890ff;
      border: 1px solid #007eed;
      opacity: 1;
      border-radius: 10px;
      color: #fff;

      &:hover {
        box-shadow: -2px -2px 10px 6px rgba(0, 0, 0, 0.1);
      }
    }

    .tips {
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;
      clear: both;
      text-align: center;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .svg-container {
      color: $light_gray;
      vertical-align: middle;
      padding-left: 10px;
      width: 30px;
      display: inline-block;

      &_login {
        font-size: 20px;
      }
    }

    .login_title {
      font-size: 2.60416vw;
      color: #000;
      margin: 0px auto 10px auto;
      text-align: left;
      font-weight: 700;
      line-height: 1;

      span {
        color: #919297;
      }
    }

    .show-pwd {
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 16px;
      color: $dark_gray;
      cursor: pointer;
      user-select: none;
    }

    .fontcontainer {
      color: #889aa4;
      padding-left: 10px;
    }
  }
</style>
