<template>
  <div>
    <div class="button_group">
      <el-form :model="filter" size="mini">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="4">
            <el-form-item label="OfferId(s):" label-width="70px">
              <el-input v-model="filter.offerIds" placeholder="offerId(s) here"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="4">
            <el-form-item label="offerName:" label-width="80px">
              <el-input v-model="filter.offerName" placeholder="offerName here"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="4">
            <el-form-item label="SourceId" label-width="80px">
              <el-input v-model="filter.sourceIds" placeholder="sourceIds here"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="4">
            <el-form-item label="Prod" label-width="50px">
              <el-input v-model="filter.prods" placeholder="prods here"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
            <el-form-item label="Status" label-width="65px">
              <el-select v-model="filter.status" class="w100">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="5">
            <el-form-item label="CreateTime" label-width="90px">
              <el-date-picker
                v-model="startToEndDate"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="daterange"
                class="w100"
                align="center"
                unlink-panels
                range-separator="-"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="24">
            <el-form-item>
              <el-button type="primary" @click="getOfferWhiteList(1)">Search</el-button>
              <el-button type="primary" @click="delOfferClick">Delete</el-button>
              <el-button type="primary" @click="deleteAll">DeleteAll</el-button>
              <!-- addVisible= true -->
              <!-- 上传文件自动匹配单子添加到offer白名单 -->
              <!-- <el-button type="primary" @click="addOfferWhiteClick">Add</el-button> -->
              <el-button type="primary" @click="exportOfferWhiteList">Excel Download</el-button>
              <upload
                size="mini"
                class="uploadmini"
                :action="doUpload"
                :on-success="uploadFileSuccess"
                style="display: inline-block;"
              ></upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table
      :data="whiteList"
      border
      size="mini"
      style="width:100%;"
      @selection-change="handleOfferWhiteSelectionChange"
      @select-all="handleOfferWhiteSelectionChange"
      :row-class-name="tableRowClassName"
      highlight-current-row
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="OfferId" prop="offerId" width="100" align="center"></el-table-column>
      <el-table-column
        label="offerName"
        prop="offerName"
        min-width="200"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Status" prop="status" align="center"></el-table-column>
      <el-table-column
        label="Daily Cap"
        prop="dailyCap"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        label="OriginPayout"
        prop="originPayout"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Payout Value"
        prop="payoutValue"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Payout Percent"
        width="130"
        prop="payoutPercent"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Event Name"
        width="130"
        prop="eventName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Description"
        min-width="230"
        prop="description"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="Operator"
        prop="operator"
        width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="OperateTime"
        prop="operateTime"
        width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="offerWhiteTotal"
      @handleSizeChange="handleofferWhiteSizeChange"
      @handleCurrentChange="handleofferWhiteCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delOfferVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delOfferVisible = false">cancel</el-button>
        <el-button type="primary" @click="delOffer">confirm</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Add offer" :visible.sync="addVisible">
      <el-form :model="offers" label-width="120px" size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="OfferId">
              <el-input v-model="offers.offerId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="DailyCap">
              <el-input v-model="offers.dailyCap"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Payout Value">
              <el-input v-model="offers.payoutValue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Payout Percent">
              <el-input v-model="offers.payoutPercent"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Description">
              <el-input v-model="offers.description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" align="right">
            <el-form-item label-width="0px" class="mb0">
              <el-button @click="addVisible = false">取 消</el-button>
              <el-button type="primary" @click="checkConfirm">确 定</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getOfferWhiteList,
    addOfferWhiteList,
    deleteOfferWhiteList,
    deleteAllOfferWhiteList,
    exportOfferWhite,
  } from 'api/affiliate/detail';
  import { confirmTraffic } from '@/api/affiliate/detail';

  import Pagination from '@/components/pagination';
  import Upload from '@/components/FileUpload';
  import { filterObject } from 'utils/utils';
  import { myMixin } from '@/mixins/mixins.js';

  const defaultOffer = {
    offerId: '',
    dailyCap: '',
    payoutValue: '',
    payoutPercent: '',
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      Upload,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        filter: {},
        statusOptions: [
          {
            label: 'active',
            value: 'active',
          },
          {
            label: 'paused',
            value: 'paused',
          },
        ],
        offerWhiteSelectionList: [],
        whiteList: [],
        offerWhiteTotal: null,
        currentPage: 1,
        pageSize: 20,
        delOfferVisible: false,
        addVisible: false,
        offers: {
          offerId: null,
          dailyCap: null,
        },
        startToEndDate: [],
      };
    },
    computed: {
      doUpload() {
        return `/api/affiliate/overall/${this.affiliateId}/uploadOfferWhite`;
      },
    },
    mounted() {
      this.getOfferWhiteList();
    },
    methods: {
      getOfferWhiteList(page) {
        if (page) {
          this.currentPage = page;
        }
        const param = filterObject({
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
        });
        if (this.startToEndDate?.length === 2) {
          [param.fromDate, param.toDate] = this.startToEndDate;
        }
        getOfferWhiteList(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.whiteList = response.result;
            this.offerWhiteTotal = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      exportOfferWhiteList(page) {
        if (page) {
          this.currentPage = page;
        }
        const param = filterObject({
          ...this.filter,
          page: 1,
          pageSize: this.pageSize,
        });
        if (this.startToEndDate?.length === 2) {
          [param.fromDate, param.toDate] = this.startToEndDate;
        }
        let getParam = this.parseParams(param);
        console.log('getparam:' + getParam);
        exportOfferWhite(this.affiliateId, getParam);
      },
      deleteAll() {
        this.$confirm('确定要删除清空Offer White List吗？', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            deleteAllOfferWhiteList(this.affiliateId).then((response) => {
              if (response.code === 200) {
                this.$message.success('Offer White List已清空');
                this.getOfferWhiteList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      parseParams(data) {
        try {
          var tempArr = [];
          for (var i in data) {
            var key = encodeURIComponent(i);
            var value = encodeURIComponent(data[i]);
            tempArr.push(key + '=' + value);
          }
          var urlParamsStr = tempArr.join('&');
          return urlParamsStr;
        } catch (err) {
          return '';
        }
      },
      addOfferWhiteClick() {
        this.offers = Object.assign({}, defaultOffer);
        this.addVisible = true;
      },
      handleOfferWhiteSelectionChange(val) {
        this.offerWhiteSelectionList = val.map((item) => item.id);
      },
      tableRowClassName(row) {
        if (row.row.offerStatus == 'hold') {
          return 'gray-row';
        } else {
          return '';
        }
      },
      delOfferClick() {
        if (this.offerWhiteSelectionList.length === 0) {
          this.$message.warning('你还没有输入offerIds~');
          return;
        }
        this.delOfferVisible = true;
      },
      delOffer() {
        const ids = this.offerWhiteSelectionList.join(',');
        deleteOfferWhiteList(ids).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.filter.offerId = '';
            this.getOfferWhiteList();
            this.delOfferVisible = false;
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      checkConfirm() {
        const offerIds = this.offers.offerId.split(',');
        confirmTraffic(offerIds).then((resp) => {
          if (resp.code === 200) {
            if (resp.result && resp.result.tips) {
              this.$confirm(resp.result.tips, 'Tip', {
                customClass: 'err-tip',
              }).then(() => {
                this.addClick();
              });
              return;
            } else {
              this.addClick();
            }
          }
        });
      },
      addClick() {
        const param = {
          ...this.offers,
        };
        addOfferWhiteList(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add success',
              type: 'success',
            });
            this.getOfferWhiteList();
            this.addVisible = false;
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      handleofferWhiteSizeChange(val) {
        this.pageSize = val;
        this.handleofferWhiteCurrentChange(1);
      },
      handleofferWhiteCurrentChange(val) {
        this.currentPage = val;
        this.getOfferWhiteList();
      },
      uploadFileSuccess(response) {
        if (response.code == 200) {
          this.getOfferWhiteList();
        } else {
          this.$message.error('Error:' + response.message);
        }
      },
    },
  };
</script>
<style>
  .uploadmini .el-button {
    padding: 7px 15px;
    margin-left: 10px;
  }
</style>
<style lang="scss">
  .el-table .gray-row {
    background-color: gray !important;
  }
  .err-tip .el-message-box__message {
    color: red;
    font-weight: bolder;
  }
</style>
