<template>
  <div class="whiteList">
    <!-- <div class="main-Title bgff"><h2>White List</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form v-model="filterForm" size="small" label-width="60px">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="13" :lg="13" :xl="13">
              <el-form :model="filterForm" inline>
                <el-form-item label="Strategy" class="mb5">
                  <el-select v-model="filterForm.strategy" clearable class="w100">
                    <el-option
                      v-for="it in strategyList"
                      :key="it.id"
                      :label="it.dataStrategyCode"
                      :value="it.dataStrategyCode"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="pkg" class="mb5">
                  <el-input v-model="filterForm.pkg" clearable></el-input>
                </el-form-item>
                <el-form-item label="Country" prop="country">
                  <el-select
                    v-model="filterForm.country"
                    placeholder="Please select"
                    style="width: 100%"
                    filterable
                    clearable
                  >
                    <el-option label="全部" value="" />
                    <el-option
                      v-for="item in options.country"
                      :key="item.countryCode"
                      :label="item.countryCode"
                      :value="item.countryCode"
                    />
                  </el-select>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label-width="0" class="mb0">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search
                </el-button>
                <el-button type="primary" @click="openDialog('add')">Create</el-button>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <p
                >1.
                策略覆盖，目前覆盖到的策略有S3,S4,S6,S7;S1默认覆盖所有的pkg_cc维度，作为兜底策略。</p
              >
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-table
              v-loading="loading.list"
              ref="tableRef"
              style="width:100%;min-height: 65vmin;"
              stripe
              border
              :data="list"
              highlight-current-row
              aria-setsize="mini"
              size="mini"
              class="mb10"
            >
              <el-table-column label="id" prop="id" align="center"></el-table-column>
              <el-table-column label="Strategy" prop="strategy" align="center"></el-table-column>
              <el-table-column label="pkg" prop="pkg" align="center"></el-table-column>
              <el-table-column label="country" prop="country" align="center"></el-table-column>
              <el-table-column
                label="createTime"
                prop="createTime"
                align="center"
              ></el-table-column>
              <el-table-column
                label="updateTime"
                prop="updateTime"
                align="center"
              ></el-table-column>
              <el-table-column label="owner" prop="owner" align="center"></el-table-column>
              <el-table-column
                label="remark"
                prop="remark"
                show-overflow-tooltip
                align="center"
              ></el-table-column>
              <el-table-column label="operation" align="center" width="200">
                <template slot-scope="scope">
                  <div style="display: flex; justify-content: center; gap: 3px;">
                    <!--                    <el-button type="warning" size="mini" @click="openDialog('edit', scope.row)"-->
                    <!--                      >update</el-button-->
                    <!--                    >-->
                    <el-button type="danger" size="mini" @click="deletesV2(scope.row)"
                      >Delete</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="30%">
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postDataForm"
            label-width="80px"
            :rules="rules"
          >
            <el-form-item label="Strategy" class="mb25" prop="strategy">
              <el-select v-model="postData.strategy" clearable class="w100">
                <el-option
                  v-for="it in strategyList"
                  :key="it.id"
                  :label="it.dataStrategyCode"
                  :value="it.dataStrategyCode"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="pkg" class="mb25" prop="pkg">
              <el-input v-model="postData.pkg" clearable></el-input>
            </el-form-item>
            <el-form-item label="Country" prop="country">
              <el-select
                v-model="postData.country"
                size="small"
                placeholder="Please select"
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in options.country"
                  :key="item.countryCode"
                  :label="item.countryCode"
                  :value="item.countryCode"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="remark" class="mb25" prop="remark">
              <el-input type="textarea" v-model="postData.remark"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog('postDataForm')">取 消</el-button>
            <el-button
              type="primary"
              @click="submitDialog('postDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import { getAlgStrategyList } from '@/api/setting';
  import {
    ccPkgConfigAdd,
    ccPkgConfigDelete,
    ccPkgConfigPage,
    ccPkgConfigDeleteV2,
  } from '@/api/algorithmSetting';
  import Pagination from '@/components/pagination';
  import { selectProds } from 'api/product/overall';
  import { fetchCountryList } from '@/api/DDJ/v3/country';

  export default {
    components: {
      Pagination,
    },
    data() {
      return {
        filterForm: {
          page: 1,
          pageSize: 20,
        },
        total: 0,
        postData: {},
        list: [],
        pkgList: [],
        strategyList: [],
        options: {
          country: [{ label: '中国', value: 'zn' }],
        },
        loading: {
          list: false,
          submitBtn: false,
        },
        dialog: {
          visible: false,
          title: '--',
          type: '',
        },
        rules: {
          strategy: [{ required: true, message: 'strategy不能为空' }],
          pkg: [{ required: true, message: 'pkg不能为空' }],
          country: [{ required: true, message: 'country不能为空' }],
        },
      };
    },
    computed: {
      isDisabled() {
        // This computed property will return true if dialog.type is 'add', disabling the select input.
        return this.dialog.type === 'edit';
      },
    },
    mounted() {
      this.getList();
      this.getCountry();
      this.getAlgStrategy();
    },
    methods: {
      getAlgStrategy() {
        getAlgStrategyList().then((res) => {
          this.strategyList = res.result;
        });
      },
      // 获取列表
      getList(page) {
        if (page) {
          this.filterForm.page = page;
        }
        this.loading.list = true;
        ccPkgConfigPage(this.filterForm)
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.list = res.result.records;
              this.total = res.result.total;
            } else {
              this.$message.error(res.message);
            }
            this.loading.list = false;
          })
          .catch((e) => {
            this.loading.list = false;
            this.$message.error(e);
          });
      },
      // 获取prods
      prodsList() {
        selectProds().then((res) => {
          this.pkgList = res.result;
        });
      },
      getCountry() {
        fetchCountryList().then((res) => {
          this.options.country = res.result;
        });
      },
      // 删除
      deletes(id) {
        this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            ccPkgConfigDelete(id).then((res) => {
              if (res.code === 200) {
                this.$message.success('删除成功！');
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },

      // 删除
      deletesV2(data) {
        this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            ccPkgConfigDeleteV2(data).then((res) => {
              if (res.code === 200) {
                this.$message.success('删除成功！');
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      // 打开弹窗
      openDialog(type, row) {
        this.dialog.type = type;
        this.dialog.title = type === 'add' ? '添加' : '编辑';
        this.dialog.visible = true;
        if (row) {
          this.postData = Object.assign({}, row);
        }
        if (this.dialog.type === 'add') {
          this.postData = {};
        }
      },
      // 提交弹窗
      submitDialog(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading.submitBtn = true;
            ccPkgConfigAdd(this.postData)
              .then((res) => {
                if (res.success === true) {
                  this.$message.success('操作成功!');
                  this.loading.submitBtn = false;
                  this.closeDialog(formName);
                  this.getList();
                } else {
                  this.loading.submitBtn = false;
                  this.$message.error(res.message);
                }
              })
              .catch((e) => {
                this.loading.list = false;
                this.loading.submitBtn = false;
                this.$message.error(e);
              });
          }
        });
      },
      // 关闭弹窗
      closeDialog(formName) {
        this.$refs[formName].resetFields();
        this.dialog.visible = false;
      },
      handleSizeChange(size) {
        this.filterForm.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.filterForm.page = currentPage;
        this.getList();
      },
    },
  };
</script>

<style></style>
