import Vue from 'vue';
import Vuex from 'vuex';
//引入vuex 数据持久化插件
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

const modulesFiles = require.context('./modules', true, /\.js$/);

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
export default new Vuex.Store({
  modules,
  plugins: [
    createPersistedState({
      reducer(val) {
        return {
          // 只储存state中的token
          token: val.token,
          roles: val.roles,
          tagsList: val.tagsList,
          breadList: val.breadList,
          affiliatesGroupList: val.affiliatesGroupList,
          loading: val.loading,
          sourceLoading: val.sourceLoading,
          sourcesList: val.sourcesList,
          fingerprint: val.fingerprint?.fingerprint,
        };
      },
    }),
  ],
});
