<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="source:" class="mb5">
                <group-select
                  v-model="filterForm.sourceIdList"
                  :data-source="sourcesList"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select Source"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="BD:" class="mb5">
                <group-select
                  v-model="filterForm.businessList"
                  :data-source="busineies"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select BD"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="status" class="mb5">
                <el-select v-model="filterForm.status" multiple clearable style="width: auto">
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="advertisers:" class="mb5">
                <el-select
                  v-model="filterForm.advertisers"
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option v-for="item in contractList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="contracts:" class="mb5">
                <el-select
                  v-model="filterForm.contracts"
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option v-for="item in contracts" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="legalAdv:" class="mb5">
                <el-select
                  v-model="filterForm.advertisersLegalName"
                  filterable
                  allow-create
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option
                    v-for="item in advLegalNameList"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="BD Group:" class="mb5">
                <el-select v-model="filterForm.businessType" clearable filterable>
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
              <el-form-item label="Billing Revenue:" class="mb5" label-width="120px">
                <el-select v-model="filterForm.billingRevenue" clearable style="width: auto">
                  <el-option :key="0" :value="0" label="0"></el-option>
                  <el-option :key="1" :value="1" label="不等于0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="6">
              <el-form-item label="period:" class="mb5">
                <el-date-picker
                  v-model="filterForm.dateRange"
                  type="monthrange"
                  value-format="yyyy-MM"
                  range-separator="to"
                  start-placeholder="start"
                  end-placeholder="end"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="11" :sm="11" :md="11" :lg="11" :xl="11" align="left" class="mr10">
              <el-form-item label-width="0" class="mb5">
                <span :xs="4" :sm="3" :md="3" :lg="4" :xl="3" class="ml10 font-16 cor337ab7"
                  >billing:[{{ sumInfo.billingRevenue | numberToCurrency }}]</span
                >
                <span :xs="4" :sm="3" :md="3" :lg="3" :xl="3" class="ml10 font-16 cor337ab7"
                  >invoice confirm: [{{ sumInfo.confirmRevenue | numberToCurrency }}]</span
                >
                <span class="progress-label ml10 font-16 cor337ab7"
                  >revenue(%):
                  <el-progress
                    :percentage="sumInfo.billingRatio"
                    status="success"
                    :text-inside="true"
                    :stroke-width="20"
                  >
                  </el-progress
                ></span>
              </el-form-item>
              <el-form-item label-width="0" class="mb5">
                <span :xs="4" :sm="3" :md="3" :lg="4" :xl="3" class="ml10 font-16 cor337ab7"
                  >billingCount:[{{ sumInfo.billingCount | numberToCurrency }}]</span
                >
                <span :xs="4" :sm="3" :md="3" :lg="3" :xl="3" class="ml10 font-16 cor337ab7"
                  >invoice confirmCount: [{{ sumInfo.confirmCount | numberToCurrency }}]</span
                >
                <span class="progress-label ml10 font-16 cor337ab7"
                  >count( %):
                  <el-progress
                    :percentage="sumInfo.countRatio"
                    status="success"
                    :text-inside="true"
                    :stroke-width="20"
                  >
                  </el-progress
                ></span>
              </el-form-item>
            </el-col>
            <el-col :xs="11" :sm="11" :md="11" :lg="11" :xl="11" align="right">
              <el-form-item label-width="0" class="mb5">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="info" :loading="loading.list" @click="downloadFie"
                  >download</el-button
                >
                <el-button type="danger" @click="mergeAdv">merge ADV</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%"
          stripe
          border
          height="780"
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          size="mini"
          class="mb10"
          :row-style="{ height: '50px' }"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column label="SourceName" prop="sourceName" align="center"></el-table-column>
          <el-table-column label="BD Group" prop="businessType" align="center"></el-table-column>
          <el-table-column label="Contract" prop="contract" align="center"></el-table-column>
          <el-table-column label="Advertisers" prop="advertisers" align="center"></el-table-column>
          <el-table-column
            label="Advertiser Legal name"
            prop="advertisersLegalName"
            align="center"
          ></el-table-column>
          <el-table-column label="BD" prop="business" align="center"></el-table-column>
          <el-table-column
            label="Billing Revenue Usd"
            prop="billingRevenue"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              {{ scope.row.billingRevenue | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Invoce No" prop="invoice" align="center">
            <template slot-scope="scope">
              <span @click="openFile(scope.row)" class="cor337ab7">{{ scope.row.invoice }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Creat time"
            prop="invoiceRaisingDate"
            align="center"
          ></el-table-column>
          <el-table-column label="Period" prop="financeCycle" align="center"></el-table-column>
          <el-table-column label="Invoice time" prop="updateTime" align="center">
            <template slot-scope="scope">
              {{ formatOperationTime(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column label="Status" prop="status" align="center">
            <template slot-scope="scope">
              {{ formatValue(scope.row.status) }}
            </template>
          </el-table-column>
          <el-table-column label="Action" align="center" fixed="right">
            <template slot-scope="scope">
              <div class="button-group">
                <el-button
                  type="primary"
                  icon="el-icon-circle-plus"
                  circle
                  size="mini"
                  v-if="scope.row.id"
                  :disabled="scope.row.status != '0'"
                  @click="creatInovice(scope.row.id)"
                  title="generate word"
                ></el-button>
                <el-upload
                  class="upload-demo"
                  action="/api/finance/uploadAdvFile"
                  v-if="scope.row.id"
                  :before-upload="(file) => beforeUpload(file, scope.row)"
                  :on-success="handleUploadSuccess"
                  :auto-upload="true"
                  :data="{ id: scope.row.id }"
                  :show-file-list="false"
                >
                  <el-button
                    type="info"
                    size="mini"
                    icon="el-icon-upload"
                    circle
                    :disabled="scope.row.status != '0'"
                    title="upload word"
                  ></el-button>
                </el-upload>
                <el-button
                  type="warning"
                  icon="el-icon-circle-check"
                  circle
                  size="mini"
                  v-if="scope.row.id"
                  :disabled="scope.row.status != '0'"
                  @click="createToPdf(scope.row.id)"
                  title="generate pdf"
                ></el-button>
                <el-button
                  v-if="
                    (accountRoles.indexOf('FinanceTeam') > -1 || accountRoles.indexOf('BM') > -1) &&
                      scope.row.id
                  "
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="mini"
                  @click="deleteAdv(scope.row.id)"
                  :disabled="scope.row.status != '0'"
                  title="delete adv"
                ></el-button>
                <el-button
                  v-if="accountRoles.indexOf('FinanceTeam') > -1 && scope.row.id"
                  type="danger"
                  icon="el-icon-circle-close"
                  circle
                  size="mini"
                  @click="cancelAdv(scope.row.id)"
                  :disabled="scope.row.status != '1'"
                  title="cancel adv"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-s-promotion"
                  circle
                  size="mini"
                  v-if="scope.row.id"
                  @click="sendToEmail(scope.row.id)"
                  :disabled="scope.row.status != '0'"
                  title="send email"
                ></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import advInfo from '../controllers/finance/advInfo';
  export default {
    name: 'advInfo',
    ...advInfo,
  };
</script>

<style scoped>
  .table-row {
    height: 50px;
  }
  .row {
    display: flex;
  }
  /* .button-group {
    display: flex;
    justify-content: space-between;
    align-items: left;
  } */
  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* 将 align-items 改为 flex-start，使按钮顶部对齐 */
    flex-wrap: wrap; /* 添加 flex-wrap 属性，使按钮换行显示 */
    width: 100%; /* 设置容器的宽度为100% */
  }
  .button-group .el-button {
    margin-bottom: 5px;
    margin-left: 1px;
  }
  .el-progress {
    width: 35%;
  }
  .progress-label {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
  }
</style>
