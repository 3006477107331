import {
  remove,
  save,
  edit,
  fetchPage,
  selectContractType,
  selectAdvLegalNameAll,
} from 'api/finance/contract';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { getPermissionAllList, selectGroupBusiness } from 'api/account/permission';
import { mapState, mapActions } from 'vuex';
import { filterObject } from 'utils/utils';
import qs from 'querystring';

export default {
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
      },
      total: 0,
      postData: {},
      list: [],
      loading: {
        list: false,
        submitBtn: false,
      },
      contractData: {
        contract: '',
        business: '',
        sourceId: '',
        advertisers: '',
        advertisersLegalName: '',
        businessCycle: '',
        paymentCycle: '',
        accountsReceivable: '',
        paymentType: '',
        signTime: '',
        fileUrl: '',
        email: '',
        address: '',
        emails: [
          {
            value: '',
          },
        ],
        notAutoMerge: false,
      },
      editData: {
        id: 0,
        contract: '',
        business: '',
        sourceId: '',
        advertisers: '',
        advertisersLegalName: '',
        businessCycle: '',
        paymentCycle: '',
        accountsReceivable: '',
        paymentType: '',
        signTime: '',
        fileUrl: '',
        email: '',
        address: '',
        emails: [
          {
            value: '',
          },
        ],
        remark: '',
      },
      dialog: {
        visible: false,
        title: '编辑',
        type: '',
      },
      addDialog: {
        visible: false,
        title: '新增',
        type: '',
      },
      businessTypes: [
        {
          label: 'overseas',
          value: 'overseas',
        },
        {
          label: 'domestic',
          value: 'domestic',
        },
      ],
      rules: {
        business: [{ required: true, message: '请选择', trigger: 'blur' }],
        sourceId: [{ required: true, message: '请选择', trigger: 'blur' }],
        advertisers: [
          { required: true, message: '请填写', trigger: 'blur' },
          { min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur' },
        ],
        advertisersLegalName: [
          { required: true, message: '请填写', trigger: 'blur' },
          { min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur' },
        ],
        businessCycle: [{ required: true, message: '请选择', trigger: 'blur' }],
        paymentCycle: [{ required: true, message: '请选择', trigger: 'blur' }],
        accountsReceivable: [{ required: true, message: '请选择', trigger: 'blur' }],
        contract: [{ required: true, message: '请选择', trigger: 'blur' }],
        paymentType: [{ required: true, message: '请选择', trigger: 'blur' }],
        email: [{ required: true, message: '请填写', trigger: 'blur' }],
        address: [{ required: true, message: '请填写', trigger: 'blur' }],
        signTime: [{ required: true, message: '请选择', trigger: 'blur' }],
        notAutoMerge: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      uploadUrl: '/api/finance/import/file',
      fileList: [],
      contractList: [],
      businessCycleList: [],
      paymentCycleList: [],
      accountList: [],
      typeList: [],
      bidAccountList: [],
      busineies: [],
      advLegalNameList: [],
      isUploadSuccess: false,
    };
  },
  mounted() {
    this.getList();
    this.getSourceIdsList();
    this.getContractType();
    this.getManagers();
    this.getBusiness();
    this.getAdvLegalNameList();
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
  },
  methods: {
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      let param = { ...this.filterForm };
      if (this.filterForm.sourceIdList) {
        param.sourceList = this.filterForm.sourceIdList.toString();
      }
      if (this.filterForm.businessList) {
        param.businessList = this.filterForm.businessList.toString();
      }
      this.loading.list = true;
      fetchPage(param)
        .then((res) => {
          if (res.code === 200) {
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 打开弹窗
    openDialog(row) {
      this.fileList = [];
      row.emails = JSON.parse(row.email);
      this.editData = { ...row };
      this.dialog.visible = true;
    },
    getAdvLegalNameList() {
      selectAdvLegalNameAll().then((res) => {
        this.advLegalNameList = res.result;
      });
    },
    getBusiness() {
      selectGroupBusiness().then((res) => {
        // let values = res.result.map((item) => item.username);
        this.busineies = res.result;
      });
    },
    // 下载excel表格
    downloadFie() {
      this.$confirm('最多下载10万条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let param = { ...this.filterForm };
          if (this.filterForm.sourceIdList) {
            param.sourceList = this.filterForm.sourceIdList.toString();
          }
          if (this.filterForm.businessList) {
            param.businessList = this.filterForm.businessList.toString();
          }
          param = filterObject(param);
          let strParam = qs.stringify(param);
          let url = '/api/finance/contract/download?' + strParam;
          console.log(url);
          window.open(url, '_blank');
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: error.message,
          });
        });
    },
    // 打开弹窗
    openAddDialog() {
      this.fileList = [];
      this.addDialog.visible = true;
    },
    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
    // 批量新增上月数据
    add() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          this.loading.submitBtn = true;
          save(this.contractData)
            .then((res) => {
              if (res.code === 200) {
                this.$message.success('新增成功！');
                this.getList();
                this.addDialog.visible = false;
                this.loading.submitBtn = false;
                // 清空表单数据
                this.contractData = {
                  contract: '',
                  business: '',
                  sourceId: '',
                  advertisers: '',
                  advertisersLegalName: '',
                  businessCycle: '',
                  paymentCycle: '',
                  accountsReceivable: '',
                  fileUrl: '',
                  email: '',
                  emails: [
                    {
                      value: '',
                    },
                  ],
                  remark: '',
                };
              } else {
                this.loading.submitBtn = false;
                this.$message.error(res.message);
              }
            })
            .cached((e) => {
              this.loading.submitBtn = false;
              this.$message.error(e);
            });
        } else {
          this.loading.submitBtn = false;
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 上传之前
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 20;
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过20M!');
        return false;
      }
    },
    // 上传成功
    handleUploadSuccess(response) {
      if (response.result) {
        this.contractData.fileUrl = response.result;
      } else {
        this.$message.error('上传失败！');
      }
    },
    handleUploadEdit(response) {
      if (response.result) {
        this.editData.fileUrl = response.result;
        // 文件上传成功
        this.isUploadSuccess = true;
      } else {
        this.$message.error('上传失败！');
      }
    },
    // deleteItem(row) {
    //   remove(row.id).then((res) => {
    //     if (res.success === true) {
    //       this.$message.success('remove success');
    //       this.getList();
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    // },
    deleteItem(row) {
      this.$confirm('此操作将 remove?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          remove(row.id).then((res) => {
            if (res.success === true) {
              this.$message.success('remove success');
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    getContractType() {
      selectContractType().then((res) => {
        if (res.success === true) {
          this.contractList = res.result['Subject of contract'];
          this.businessCycleList = res.result['Cycle length of business'];
          this.paymentCycleList = res.result['Net/Payment period'];
          this.accountList = res.result['Account for payment'];
          this.typeList = res.result['Type'];
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getManagers() {
      getPermissionAllList().then((res) => {
        const values = res.result.map((item) => item.username);
        this.bidAccountList = values;
      });
    },
    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          edit({ ...this.editData }).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.getList();
              this.dialog.visible = false;
            } else {
              this.$message.error('Update Error:' + response.message);
            }
          });
        } else {
          console.log('error editForm!!');
          return false;
        }
      });
    },
    openFile(row) {
      window.open(row.fileUrl, '_blank');
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    addDomain(data) {
      if (data === 'add') {
        this.contractData.emails.push({
          value: '',
          key: Date.now(),
        });
      } else {
        this.editData.emails.push({
          value: '',
          key: Date.now(),
        });
      }
      console.log(this.editData.emails);
    },
    removeDomain(item) {
      var index = this.contractData.emails.indexOf(item);
      if (index !== -1) {
        this.contractData.emails.splice(index, 1);
      }
    },
    removeEditDomain(item) {
      console.log(item);
      var index = this.editData.emails.indexOf(item);
      if (index !== -1) {
        this.$set(this.editData.emails, index, undefined);
        this.editData.emails.splice(index, 1);
        // this.$forceUpdate();
      }
    },
    formatEmail(arr) {
      let list = JSON.parse(arr);
      const values = list.map((obj) => obj.value);
      return values;
    },
    // 排序监听
    sortChange(column) {
      if (column.prop !== null && column.order !== null) {
        this.filterForm.sorting = column.prop;
        this.filterForm.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.filterForm.sorting = 'mmp_revenue';
        this.filterForm.sortType = 'desc';
      }
      this.getList();
    },
    handleSourceChange(val) {
      for (var i = 0; i < this.sourcesList.length; i++) {
        for (var j = 0; j < this.sourcesList[i].children.length; j++) {
          if (this.sourcesList[i].children[j].value == val) {
            if (
              this.sourcesList[i].children[j].business !== 'unKnown' &&
              this.sourcesList[i].children[j].business !== 'unKnow' &&
              this.sourcesList[i].children[j].business !== 'unknown'
            ) {
              this.contractData.business = this.sourcesList[i].children[j].business;
            } else {
              this.contractData.business = null;
            }
          }
        }
      }
    },
    handleSourceEditChange(val) {
      for (var i = 0; i < this.sourcesList.length; i++) {
        for (var j = 0; j < this.sourcesList[i].children.length; j++) {
          if (this.sourcesList[i].children[j].value == val) {
            if (
              this.sourcesList[i].children[j].business !== 'unKnown' &&
              this.sourcesList[i].children[j].business !== 'unKnow' &&
              this.sourcesList[i].children[j].business !== 'unknown'
            ) {
              this.editData.business = this.sourcesList[i].children[j].business;
            } else {
              this.editData.business = null;
            }
          }
        }
      }
    },
  },
};
