import request from 'utils/request';

const api = {
  LOGIN: '/api/login',
  LOGOUT: '/api/logout',
  GET_MENU_LIST: '/api/security/account/menu',
  chang_password: '/api/changeUserAndWord',
  get_roles: '/api/userRole',
  GET_ACCOUNT_ROLES: '/api/getAccountRoles',
  GET_USERNAME: '/api/userName',
  MFA_AUTH: '/api/auth/generate',
  MFA_VALIDATE: '/api/auth/validate/key',
  IS_SECRET_KEY: '/api/isSecretKey',
};

export function login(data) {
  return request({
    url: api.LOGIN,
    method: 'post',
    data,
  });
}

export function logout() {
  return request({
    url: api.LOGOUT,
    method: 'get',
  });
}

export function getMenuList() {
  return request({
    url: api.GET_MENU_LIST,
    method: 'get',
  });
}

export function changPassword(data) {
  return request({
    url: api.chang_password,
    method: 'put',
    data,
  });
}

export function select_roles() {
  return request({
    url: api.get_roles,
    method: 'get',
  });
}

export function select_account_roles() {
  return request({
    url: api.GET_ACCOUNT_ROLES,
    method: 'get',
  });
}

export function get_username() {
  return request({
    url: api.GET_USERNAME,
    method: 'get',
  });
}

export function generate_mfa(query) {
  return request({
    url: api.MFA_AUTH,
    method: 'get',
    params: query,
    responseType: 'blob',
  });
}

export function validate_mfa(query) {
  return request({
    url: api.MFA_VALIDATE,
    method: 'get',
    params: query,
  });
}

export function isSecretKey(data) {
  return request({
    url: api.IS_SECRET_KEY,
    method: 'post',
    data,
  });
}
