import {
  fetchPage,
  creatInovicePdf,
  sendEmail,
  createPdf,
  mergeAdv,
  deleteAdv,
  cancelAdv,
} from 'api/finance/adv';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { selectContractType, selectAdvertisersAll } from 'api/finance/contract';
import { mapState, mapActions } from 'vuex';
import { getPermissionAllList, selectGroupBusiness } from 'api/account/permission';
import { filterObject } from 'utils/utils';
import qs from 'querystring';
import { selectAdvLegalNameAll } from '@/api/finance/contract';

export default {
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
        dateRange: [],
      },
      total: 0,
      postData: {},
      list: [],
      multipleSelection: [],
      copyArr: [],
      loading: {
        list: false,
        submitBtn: false,
      },
      editData: {
        id: 0,
        appName: '',
        mmpRevenue: 0,
        confirmNum: 0,
        notes: '',
      },
      sumInfo: {
        billingRevenue: 0,
        confirmRevenue: 0,
        billingCount: 0,
        confirmCount: 0,
        billingRatio: 0,
        countRatio: 0,
      },
      dialog: {
        visible: false,
        title: '编辑',
        type: '',
      },
      contractList: [],
      contracts: [],
      bidAccountList: [],
      busineies: [],
      advLegalNameList: [],
      statusList: [
        {
          label: 'created',
          value: '0',
        },
        {
          label: 'confirm',
          value: '1',
        },
        {
          label: 'merged',
          value: '2',
        },
        {
          label: 'cancel',
          value: '3',
        },
      ],
      businessTypes: [
        {
          label: 'overseas',
          value: 'overseas',
        },
        {
          label: 'domestic',
          value: 'domestic',
        },
      ],
    };
  },
  mounted() {
    this.getDefaultTime();
    this.getList();
    this.getContractType();
    // this.getManagers();
    this.getBusiness();
    this.getSourceIdsList();
    this.selectAccountRoles();
    this.getAdvLegalNameList();
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    ...mapState('user', {
      accountRoles: (state) => state.roles,
    }),
  },
  methods: {
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    // get roles
    ...mapActions('user', ['selectAccountRoles']),
    getDefaultTime() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 2); // 设置日期为上上月
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const formattedMonth = month < 10 ? `0${month}` : month;
      let ddr = `${year}-${formattedMonth}`;
      this.filterForm.dateRange = [ddr, ddr]; // 设置默认起始日期
    },
    getAdvLegalNameList() {
      selectAdvLegalNameAll().then((res) => {
        this.advLegalNameList = res.result;
      });
    },
    formatOperationTime(row) {
      if (row.status === '0') {
        return row.createTime;
      } else {
        return row.updateTime;
      }
    },
    getBusiness() {
      selectGroupBusiness().then((res) => {
        // let values = res.result.map((item) => item.username);
        this.busineies = res.result;
      });
    },
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      let param = { ...this.filterForm };
      let rangeTime = this.filterForm.dateRange;
      if (Array.isArray(rangeTime) && rangeTime.length > 0) {
        param.startTime = this.filterForm.dateRange[0];
        param.endTime = this.filterForm.dateRange[1];
        // 删除多余的属性
        delete param.dateRange;
      }
      if (this.filterForm.status) {
        param.statusList = this.filterForm.status.toString();
      }
      if (this.filterForm.sourceIdList) {
        param.sourceList = this.filterForm.sourceIdList.toString();
      }
      if (this.filterForm.businessList) {
        param.businessList = this.filterForm.businessList.toString();
      }
      this.loading.list = true;
      fetchPage(param)
        .then((res) => {
          if (res.code === 200) {
            this.sumInfo = res.result.pop();
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 下载excel表格
    downloadFie() {
      this.$confirm('最多下载10万条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let param = { ...this.filterForm };
          let rangeTime = this.filterForm.dateRange;
          if (Array.isArray(rangeTime) && rangeTime.length > 0) {
            param.startTime = this.filterForm.dateRange[0];
            param.endTime = this.filterForm.dateRange[1];
            // 删除多余的属性
            delete param.dateRange;
          }
          if (this.filterForm.status) {
            param.statusList = this.filterForm.status.toString();
          }
          if (this.filterForm.businessList) {
            param.businessList = this.filterForm.businessList.toString();
          }
          param = filterObject(param);
          let strParam = qs.stringify(param);
          let url = '/api/finance/adv/download?' + strParam;
          console.log(url);
          window.open(url, '_blank');
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: error.message,
          });
        });
    },

    getManagers() {
      getPermissionAllList().then((res) => {
        let values = res.result.map((item) => item.username);
        this.bidAccountList = values;
      });
    },
    // 打开弹窗
    openDialog(row) {
      this.editData = { ...row };
      this.dialog.visible = true;
    },
    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
    creatInovice(id) {
      creatInovicePdf(id).then((response) => {
        if (response.success === true) {
          this.$message({
            message: 'creatInovice Success',
            type: 'success',
          });
          this.getList();
        } else {
          this.$message.error('Update  Error:' + response.message);
        }
      });
    },
    sendToEmail(id) {
      this.$confirm('此操作将 send email?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.loading.list = true;
          sendEmail(id).then((response) => {
            if (response.success === true) {
              this.loading.list = false;
              this.$message({
                message: 'sendEmail Success',
                type: 'success',
              });
              this.getList();
            } else {
              this.loading.list = false;
              this.$message.error('Update  Error:' + response.message);
            }
          });
        })
        .catch(() => {
          this.loading.list = false;
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    deleteAdv(id) {
      this.$confirm('此操作将 delete  adv?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          deleteAdv(id).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'delete adv Success',
                type: 'success',
              });
              this.getList();
            } else {
              this.$message.error('Update  Error:' + response.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    cancelAdv(id) {
      this.$confirm('此操作将 cancel adv?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          cancelAdv(id).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'cancel adv Success',
                type: 'success',
              });
              this.getList();
            } else {
              this.$message.error('Update  Error:' + response.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    createToPdf(id) {
      createPdf(id).then((response) => {
        if (response.success === true) {
          this.$message({
            message: 'createPdf Success',
            type: 'success',
          });
          this.getList();
        } else {
          this.$message.error('Update  Error:' + response.message);
        }
      });
    },
    mergeAdv() {
      this.$confirm('This operation merges the selected ADVs into one?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let targetList = this.copyArr.slice();
          mergeAdv(targetList).then((response) => {
            if (response.success === true) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.getList();
            } else {
              this.$message.error('Update  Error:' + response.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    // 排序监听
    sortChange(column) {
      if (column.prop !== null && column.order !== null) {
        this.filterForm.sorting = 'billing_revenue';
        this.filterForm.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      }
      this.getList();
    },
    formatValue(val) {
      if (val === '0') {
        return 'create';
      } else if (val === '1') {
        return 'confirm';
      } else if (val === '2') {
        return 'merged';
      } else if (val === '3') {
        return 'cancel';
      } else {
        return val;
      }
    },
    openFile(row) {
      window.open(row.fileUrl, '_blank');
    },
    // 上传之前
    beforeUpload(file, row) {
      const isLt1M = file.size / 1024 / 1024 < 2;
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过2M!');
        return false;
      }
      console.log(row.id);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('id', row.id);
      return formData;
    },
    // 上传成功
    handleUploadSuccess(response) {
      if (response.result) {
        this.getList();
        this.$message('上传成功！');
      } else {
        this.$message.error('上传失败:' + response.message);
      }
    },
    getContractType() {
      selectContractType().then((res) => {
        if (res.success === true) {
          this.contracts = res.result['Subject of contract'];
        } else {
          this.$message.error(res.message);
        }
      });
      selectAdvertisersAll().then((res) => {
        if (res.success === true) {
          this.contractList = res.result;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.copyArr = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        let idFinance = this.multipleSelection[i].id;
        if (idFinance) {
          this.copyArr.push(this.multipleSelection[i].id);
        }
      }
    },
  },
};
