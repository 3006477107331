import request from 'utils/request';
const api = {
  LIST_FINANCES: '/api/finance/listFinances',
  UPDATE_FINANCE: '/api/finance/updateFinance',
  SAVE_FINANCE: '/api/finance/saveFinance',
  DELETE_FINANCE: '/api/finance/deleteFinance/',
  UPDATE_ACCOUNT_FOR_PAYMENT_FINANCE: '/api/finance/updateAccountForPaymentFinance',

  APPROVAL_TODOLIST: '/api/finance/listTODO',
  APPROVAL_AGREE: '/api/finance/agreeApproval',
  APPROVAL_REJECT: '/api/finance/rejectApproval',
  APPROVAL_AGREE_BATCH: '/api/finance/agreeApprovalBatch',
  APPROVAL_REJECT_BATCH: '/api/finance/rejectApprovalBatch',
  APPROVAL_DETAIL: '/api/finance/searchApproval',
  APPROVAL_DONELIST: '/api/finance/searchDoneList',
  APPROVAL_APPLYLIST: '/api/finance/listApproval',
  APPROVAL_CCLIST: '/api/finance/listCCApproval',

  STATUS_APPROVAL_TODOLIST: '/api/finance/bdStatus/listTODO',
  STATUS_APPROVAL_AGREE: '/api/finance/bdStatus/agreeApproval',
  STATUS_APPROVAL_REJECT: '/api/finance/bdStatus/rejectApproval',
  STATUS_APPROVAL_AGREE_BATCH: '/api/finance/bdStatus/agreeApprovalBatch',
  STATUS_APPROVAL_REJECT_BATCH: '/api/finance/bdStatus/rejectApprovalBatch',
  STATUS_APPROVAL_DETAIL: '/api/finance/bdStatus/searchApproval',
  STATUS_APPROVAL_DONELIST: '/api/finance/bdStatus/searchDoneList',
  STATUS_APPROVAL_APPLYLIST: '/api/finance/bdStatus/listApproval',
  STATUS_APPROVAL_CCLIST: '/api/finance/bdStatus/listCCApproval',
};

/**
 * 获取 Finance 对账流水（分页）
 * @param params
 * @returns {*}
 */
export function fetchPage(params) {
  return request({
    url: api.LIST_FINANCES,
    method: 'get',
    params,
  });
}

export function save(data) {
  return request({
    url: api.SAVE_FINANCE,
    method: 'post',
    data,
  });
}

export function update(data) {
  return request({
    url: api.UPDATE_FINANCE,
    method: 'post',
    data,
  });
}

export function updateAccountForPayment(data) {
  return request({
    url: api.UPDATE_ACCOUNT_FOR_PAYMENT_FINANCE,
    method: 'post',
    data,
  });
}

export function deleteFiance(id) {
  return request({
    url: api.DELETE_FINANCE + id,
    method: 'delete',
  });
}

export function todoList(params) {
  return request({
    url: api.APPROVAL_TODOLIST,
    method: 'get',
    params,
  });
}
export function doneList(params) {
  return request({
    url: api.APPROVAL_DONELIST,
    method: 'get',
    params,
  });
}

export function agreeApproval(data) {
  return request({
    url: api.APPROVAL_AGREE,
    method: 'post',
    data,
  });
}

export function rejectApproval(data) {
  return request({
    url: api.APPROVAL_REJECT,
    method: 'post',
    data,
  });
}

export function agreeApprovalBatch(data) {
  return request({
    url: api.APPROVAL_AGREE_BATCH,
    method: 'post',
    data,
  });
}

export function rejectApprovalBatch(data) {
  return request({
    url: api.APPROVAL_REJECT_BATCH,
    method: 'post',
    data,
  });
}

export function getApprovalDetail(params) {
  return request({
    url: api.APPROVAL_DETAIL,
    method: 'get',
    params,
  });
}

export function applyList(params) {
  return request({
    url: api.APPROVAL_APPLYLIST,
    method: 'get',
    params,
  });
}
export function ccList(params) {
  return request({
    url: api.APPROVAL_CCLIST,
    method: 'get',
    params,
  });
}

export function todoListBDStatus(params) {
  return request({
    url: api.STATUS_APPROVAL_TODOLIST,
    method: 'get',
    params,
  });
}
export function doneListBDStatus(params) {
  return request({
    url: api.STATUS_APPROVAL_DONELIST,
    method: 'get',
    params,
  });
}

export function agreeApprovalBDStatus(data) {
  return request({
    url: api.STATUS_APPROVAL_AGREE,
    method: 'post',
    data,
  });
}

export function rejectApprovalBDStatus(data) {
  return request({
    url: api.STATUS_APPROVAL_REJECT,
    method: 'post',
    data,
  });
}

export function agreeApprovalBatchBDStatus(data) {
  return request({
    url: api.STATUS_APPROVAL_AGREE_BATCH,
    method: 'post',
    data,
  });
}

export function rejectApprovalBatchBDStatus(data) {
  return request({
    url: api.STATUS_APPROVAL_REJECT_BATCH,
    method: 'post',
    data,
  });
}

export function getApprovalDetailBDStatus(params) {
  return request({
    url: api.STATUS_APPROVAL_DETAIL,
    method: 'get',
    params,
  });
}

export function applyListBDStatus(params) {
  return request({
    url: api.STATUS_APPROVAL_APPLYLIST,
    method: 'get',
    params,
  });
}
export function ccListBDStatus(params) {
  return request({
    url: api.STATUS_APPROVAL_CCLIST,
    method: 'get',
    params,
  });
}
