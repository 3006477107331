<template>
  <div>
    <el-form v-model="filterData" size="small" label-width="70px">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <el-form-item label="PID" label-width="45px">
            <el-input clearable v-model="filterData.pid"></el-input>
            <!-- <el-select
              v-model="filterData.pids"
              filterable
              multiple
              clearable
              placeholder="Please select"
              class="w100"
            >
              <el-option v-for="item in option.pidList" :key="item" :label="item" :value="item">
              </el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :span="24" align="right">
          <el-form-item label-width="0">
            <el-button
              type="primary"
              icon="el-icon-search"
              :loading="loading.list"
              @click="getList(1)"
              >Search
            </el-button>
            <el-button type="success" icon="el-icon-plus" :loading="loading.list" @click="add"
              >Create
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      v-loading="loading.list"
      ref="tableRef"
      class="w100"
      height="70vmin"
      stripe
      border
      :data="list"
      size="mini"
      highlight-current-row
    >
      <el-table-column label="ID" prop="id" align="center" width="80"></el-table-column>
      <el-table-column label="PID" prop="pid" align="center" min-width="90"></el-table-column>
      <el-table-column
        label="Account"
        prop="account"
        align="center"
        min-width="90"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="Create Time"
        prop="createTime"
        align="center"
        min-width="100"
      ></el-table-column>
      <el-table-column
        label="Apps"
        prop="apps"
        align="center"
        show-overflow-tooltip
        min-width="180"
      ></el-table-column>
      <el-table-column label="status" prop="status" align="center">
        <template slot-scope="scope">{{ scope.row.status ? 'active' : 'inactive' }} </template>
      </el-table-column>
      <el-table-column label="signStatus" prop="signStatus" align="center">
        <template slot-scope="scope"
          >{{ formatValue(scope.row.signStatus) }}
          <i class="el-icon-edit" @click="viewSignStatus(scope.row.id, scope.row.signStatus)"></i>
        </template>
      </el-table-column>
      <el-table-column
        label="token"
        prop="token"
        align="center"
        show-overflow-tooltip
        min-width="180"
      ></el-table-column>
      <el-table-column label="Option" align="center" width="180">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            @click="
              edit(scope.row);
              getPidAppList(scope.row.pid);
            "
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            circle
            size="mini"
            @click="del(scope.row)"
          ></el-button>
          <el-button
            type="info"
            icon="el-icon-document"
            size="mini"
            circle
            :loading="loading.list"
            @click="downloadFie(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      class="appsflyer_account_dialog"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      :width="fullWidth < 750 ? '96%' : '60%'"
    >
      <el-form
        :model="postData"
        label-width="120px"
        :label-position="fullWidth < 768 ? 'top' : 'right'"
        :rules="rules"
        ref="postData"
        v-loading="loading.dialog"
      >
        <el-form-item label="PID" prop="pid">
          <el-input
            clearable
            v-model.trim="postData.pid"
            :disabled="dialog.title !== 'Add'"
          ></el-input>
        </el-form-item>
        <el-form-item label="Account" prop="account">
          <el-input clearable v-model="postData.account"></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <el-input clearable v-model="postData.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="token" prop="password">
          <el-input clearable v-model="postData.token" show-password></el-input>
        </el-form-item>
        <el-form-item label="status:" prop="status">
          <el-select v-model="postData.status" class="w100">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Apps" prop="apps">
          <el-select
            v-model="postData.apps"
            multiple
            collapse-tags
            clearable
            class="w100"
            placeholder="请选择"
          >
            <el-option v-for="item in appsList" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0px" class="align-r">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="submit('postData')">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="signStatus修改" :visible.sync="offerLinkType.visible" width="30%">
      <el-select v-model="offerLinkType.data.signStatus" placeholder="请选择" class="w100">
        <el-option
          v-for="item in signStatusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <div>
        <p>
          0. disabled（默认禁用）：AppsFlyer不验证点击签名。<br />
          1. report-only（仅报告）：AppsFlyer会验证点击签名并记录，但不会拦截签名无效的点击。<br />
          2. enabled（启用）：AppsFlyer会拦截签名无效或缺失的点击。<br />
          注意：disabled状态修改完直接同步到af; report-only,enabled
          定时任务每小时触发后，获取到密钥再同步状态到af。enabled 测试环境不会同步到af.
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offerLinkType.visible = false">取 消</el-button>
        <el-button type="primary" :loading="loading.dialog" @click="updateSignStatus()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    fetchAppsFlyerAccountDetails,
    fetchAppsFlyerAccountPage,
    getPidAppIdData,
    handleAppsFlyerAccountAdd,
    handleAppsFlyerAccountDel,
    handleAppsFlyerAccountUpdate,
    getAppsflyerAccountAllPidappid,
    handleAppsFlyerAccountUpdateStatus,
  } from '@/api/report';
  import { myMixin } from '@/mixins/mixins.js';

  let postDataObj = {
    pid: '',
    account: '',
    password: '',
    apps: [],
  };
  export default {
    name: 'AppsflyerAccount',
    mixins: [myMixin],
    data() {
      return {
        filterData: {
          pids: [],
        },
        option: {
          pidList: [],
          appIdList: [],
          pidAppidObject: {},
        },
        fullWidth: document.documentElement.clientWidth,
        list: [],
        loading: {
          list: false,
          dialog: false,
        },
        postData: {},
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        dialog: {
          title: 'Add',
          visible: false,
        },
        offerLinkType: {
          visible: false,
          data: {},
          loading: false,
        },
        rules: {
          pid: [{ required: true, message: 'Please enter pid', trigger: 'blur' }],
          account: [{ required: true, message: 'Please enter account', trigger: 'blur' }],
          password: [{ required: true, message: 'Please enter password', trigger: 'blur' }],
          apps: [{ required: false, message: 'Please enter apps', trigger: 'blur' }],
        },
        appsList: [],
        allPidAppid: [],
        statusList: [
          {
            label: 'inactive',
            value: false,
          },
          {
            label: 'active',
            value: true,
          },
        ],
        signStatusList: [
          {
            label: 'disabled',
            value: '0',
          },
          {
            label: 'report-only',
            value: '1',
          },
          {
            label: 'enabled',
            value: '2',
          },
        ],
      };
    },
    watch: {
      postData: {
        handler(form) {
          if (form.apps) {
            console.log('form.apps___', form.apps);
          }
        },
        deep: true,
      },
    },
    mounted() {
      this.getPidAppidList();
      this.getList();
      // this.getAllPidAppid();
    },
    methods: {
      formatValue(val) {
        const item = this.signStatusList.find((item) => item.value === val);
        return item ? item.label : val;
      },
      // 获取数据列表
      getList() {
        let param = {
          ...this.filterData,
        };
        this.loading.list = true;
        fetchAppsFlyerAccountPage(param)
          .then((res) => {
            this.list = res.result;
            this.loading.list = false;
          })
          .catch(() => {
            this.loading.list = false;
          });
      },
      downloadFie(id) {
        this.$confirm('下载af签名报表数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            let url = '/api/adv/afSignReportDownload?id=' + id;
            window.open(url, '_blank');
            this.loading.list = false;
          })
          .catch((error) => {
            this.loading.list = false;
            this.$message({
              type: 'info',
              message: error.message,
            });
          });
      },
      // 获取数据列表
      getPidAppList(pid) {
        getAppsflyerAccountAllPidappid(pid)
          .then((res) => {
            this.appsList = res.result;
          })
          .catch(() => {
            this.loading.list = false;
          });
      },
      // 获取pid
      getPidAppidList() {
        getPidAppIdData().then((response) => {
          let _this = this;
          this.option.pidList = [];
          this.option.appIdList = [];
          this.option.pidAppidObject = {};
          if (response.code === 200) {
            response.result.forEach((item, index) => {
              _this.option.pidAppidObject[item.pid] = item.appIds;
              _this.option.pidList.push(item.pid);
              const group1 = {
                id: index + 1,
                checked: false,
                label: item.pid,
                children: [],
              };
              item.appIds.forEach((item1) => {
                group1.children.push({
                  id: item1,
                  value: item1,
                  label: item1,
                });
              });
              _this.option.appIdList.push(group1);
            });
          }
        });
      },
      add() {
        this.dialog.title = 'Add';
        this.dialog.visible = true;
      },
      edit(row) {
        this.dialog.title = 'Update';
        this.dialog.visible = true;
        this.loading.dialog = true;
        fetchAppsFlyerAccountDetails(row.id)
          .then((res) => {
            this.postData = res.result;
            if (res.result.apps) {
              this.postData.apps = res.result.apps.split(',');
            }
            this.loading.dialog = false;
            const allPidAppidArr = this.allPidAppid;
            if (allPidAppidArr.length) {
              allPidAppidArr.forEach((el) => {
                if (el.pid === res.result.pid) {
                  this.appsList = el.app;
                }
              });
            }
          })
          .catch(() => {
            this.loading.dialog = false;
          });
      },
      close() {
        this.dialog.title = '';
        this.postData = Object.assign({}, postDataObj);
        this.dialog.visible = false;
      },
      del(row) {
        if (row.id === '') return;
        this.$confirm('Are you sure you want to delete?', 'tip', {
          confirmButtonText: 'yes',
          cancelButtonText: 'no',
          type: 'warning',
        }).then(() => {
          this.loading.list = true;
          handleAppsFlyerAccountDel(row.id)
            .then(() => {
              this.$message.success('Information remove successfully');
              this.getList();
              this.loading.list = false;
            })
            .catch(() => {
              this.$message.error('Information deletion failed');
              this.loading.list = false;
            });
        });
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.dialog.title === 'Add') {
              this.loading.dialog = true;
              const query = Object.assign({}, this.postData);
              if (this.postData.apps) {
                query.apps = this.postData.apps.join(',');
              }
              handleAppsFlyerAccountAdd(query)
                .then((res) => {
                  if (res.success === true) {
                    this.$message.success(res.message);
                    this.loading.dialog = false;
                    this.dialog.visible = false;
                    this.postData = Object.assign({}, postDataObj);
                    this.getList();
                  } else {
                    this.$message.error(res.message);
                    this.loading.dialog = false;
                    this.dialog.visible = false;
                    this.postData = Object.assign({}, postDataObj);
                    this.getList();
                  }
                })
                .catch(() => {
                  this.$message.error('Information added failed');
                  this.loading.dialog = false;
                });
            }
            if (this.dialog.title === 'Update') {
              const query = Object.assign({}, this.postData);
              // query.apps = this.postData.apps.join(',');
              this.loading.dialog = true;
              handleAppsFlyerAccountUpdate(query)
                .then((res) => {
                  this.loading.dialog = false;
                  if (res.code == 200) {
                    this.$message.success('Information updated successfully');
                    this.dialog.visible = false;
                    this.postData = Object.assign({}, postDataObj);
                    this.getList();
                  }
                })
                .catch(() => {
                  this.$message.error('Information updated failed');
                  this.loading.dialog = false;
                });
            }
          }
        });
      },
      viewSignStatus(id, signStatus) {
        this.offerLinkType.data = { id: id, signStatus: signStatus };
        this.offerLinkType.visible = true;
      },
      updateSignStatus() {
        this.loading.dialog = true;
        handleAppsFlyerAccountUpdateStatus(this.offerLinkType.data)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('更新成功！');
              this.offerLinkType.visible = false;
              this.loading.dialog = false;
              this.getList();
            } else {
              this.loading.dialog = false;
              this.$message.error('Update Error:' + res.message);
            }
          })
          .catch(() => {
            this.$message.error('SignStatus updated failed');
            this.loading.dialog = false;
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  .popover_icon {
    width: 60px;
  }

  .form_img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    .popover_icon {
      width: 140px;
    }
  }

  @media screen and (max-width: 768px) {
    .appsflyer_account_dialog {
      ::v-deep .el-form-item__label {
        padding-bottom: 0;
      }

      ::v-deep .el-dialog__body {
        padding-top: 10px;
      }
    }
  }
</style>
