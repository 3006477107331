<template>
  <div>
    <el-form size="mini">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="1. Offer Payout Setting" label-width="180px">
            <el-input v-model="offerIds" placeholder="search offerId"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="getOfferPayoutList(1)">Search</el-button>
            <el-button type="primary" @click="addOfferPayoutClick">Add</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="offerPayoutList" border stripe style="width:100%;" size="mini">
      <el-table-column label="Offer Id" prop="offerId"></el-table-column>
      <el-table-column label="Original Payout" prop="originPayout"></el-table-column>
      <el-table-column label="Payout" prop="payout"></el-table-column>
      <el-table-column label="Percent" prop="percent"></el-table-column>
      <el-table-column label="Operator" prop="operator" />
      <el-table-column label="Operate Time" prop="operateTime" />
      <el-table-column label="Operation" width="200">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="updatePayoutClick(scope.row)"
            >update</el-button
          >
          <el-button type="danger" size="mini" @click="delPayoutClick(scope.row)">delete</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handlePayoutSizeChange"
      @handleCurrentChange="handlesPayoutCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>

    <!-- 2.Source Pecent -->
    <el-form size="small">
      <el-row class="pt10" :gutter="10">
        <el-col :span="8">
          <el-form-item label="2. Source Pecent Setting" label-width="180px">
            <el-input v-model="pecentSourceIds" placeholder="search sourceId"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="getAffSourcePayout">Search</el-button>
            <el-button type="primary" @click="addSourcePecentClick">Add</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="sourcePayoutList"
      highlight-current-row
      border
      stripe
      style="width:100%;"
      size="mini"
    >
      <el-table-column label="Source Id" prop="sourceId"></el-table-column>
      <el-table-column label="Percent" prop="percent"></el-table-column>
      <el-table-column label="Operator" prop="operator"></el-table-column>
      <el-table-column label="Operate Time" prop="operateTime"></el-table-column>
      <el-table-column label="Operation">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            v-if="scope.row.edit !== true"
            @click="updateSourcePayoutClick(scope.row)"
            >update</el-button
          >
          <el-button
            type="danger"
            size="mini"
            v-if="scope.row.edit !== true"
            @click="delSourcepecentClick(scope.row)"
            >delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="sourcePayoutTotal"
      @handleSizeChange="handlesourcePayoutSizeChange"
      @handleCurrentChange="handlesourcePayoutCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- 3.Pecent Setting -->
    <el-form size="small">
      <el-row :gutter="10" class="pt10">
        <el-col :span="8">
          <el-form-item label="3.Pecent Setting" label-width="140px">
            <el-input
              v-model="newPayoutPecentValue"
              placeholder="example 0.9 is 90%,original*90%"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="changeAffPayout('add')">Add</el-button>
            <el-button type="primary" @click="changeAffPayout('edit')">Update</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="payoutSettingList"
      highlight-current-row
      border
      stripe
      style="width:100%;"
      size="mini"
    >
      <el-table-column label="Percent" prop="percent"></el-table-column>
      <el-table-column label="Operator" prop="operator"></el-table-column>
      <el-table-column label="Operate Time" prop="operateTime"></el-table-column>
      <el-table-column label="Operation">
        <template slot-scope>
          <el-button type="danger" @click="delAffPayoutClick">delete</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">cancel</el-button>
        <el-button type="primary" @click="delRow">confirm</el-button>
      </span>
    </el-dialog>

    <!-- add offerId模块框 -->
    <el-dialog
      :title="'Payout ' + (offerPayoutDialogType === 'add' ? 'Add' : 'Update')"
      :visible.sync="offerPayoutVisible"
      width="400px"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form label-position="right" label-width="70px" :model="offerPayout" size="small">
        <el-form-item label="Offer Id">
          <el-input v-model="offerPayout.offerId"></el-input>
        </el-form-item>
        <el-form-item label="Payout">
          <el-input v-model="offerPayout.payout"></el-input>
        </el-form-item>
        <el-form-item label="Percent">
          <el-input v-model="offerPayout.percent"></el-input>
        </el-form-item>
        <el-form-item label-width="0px" align="right">
          <el-button @click="offerPayoutVisible = false">取 消</el-button>
          <el-button type="primary" @click="changeOfferPayout">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- add sourceId模块框 -->
    <el-dialog
      :title="'Source ' + (sourcePayoutDialogType === 'add' ? 'Add' : 'Update')"
      :visible.sync="sourcePayoutVisible"
      :close-on-click-modal="false"
      width="400px"
      :append-to-body="true"
    >
      <el-form label-position="right" label-width="80px" :model="sourcePayout" size="small">
        <el-form-item label="Source Id">
          <el-input v-model="sourcePayout.sourceId"></el-input>
        </el-form-item>
        <el-form-item label="Percent">
          <el-input v-model="sourcePayout.percent"></el-input>
        </el-form-item>
        <el-form-item label-width="0px" class="mb0" align="right">
          <el-button @click="sourcePayoutVisible = false">取 消</el-button>
          <el-button type="primary" @click="changeSourcePayout">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getAffOfferPayoutList,
    addAffOfferPayout,
    updateAffOfferPayout,
    deleteAffOfferPayout,
    getAffSourcePayoutList,
    addAffSourcePayout,
    updateAffSourcePayout,
    deleteAffSourcePayout,
    getAffPayoutList,
    addAffPayout,
    updateAffPayout,
    deleteAffPayout,
  } from 'api/affiliate/detail';
  import { filterObject } from 'utils/utils';
  import Pagination from '@/components/pagination';

  const defaultOfferPayout = {
    offerId: '',
    payout: '',
    percent: '',
  };
  const defaultSourcePayout = {
    sourceId: '',
    percent: '',
  };
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: [Number, String],
        required: true,
      },
    },
    data() {
      return {
        offerIds: '', //payout_offerId
        offerPayoutList: [],
        offerPayout: Object.assign({}, defaultOfferPayout),
        offerPayoutVisible: false,
        offerPayoutDialogType: 'add',
        total: 0,
        currentPage: 1,
        pageSize: 20,
        pecentSourceIds: '',
        sourcePayoutList: [],
        sourcePayoutDialogType: 'add',
        sourcePayoutTotal: 0,
        newPayoutPecentValue: '',
        payoutSettingList: [],
        pecentValue: '',
        delVisible: false,
        sourcePayoutVisible: false,
        sourcePayout: {},
      };
    },
    mounted() {
      this.getOfferPayoutList();
      this.getAffSourcePayout();
      this.getAffPayoutList();
    },
    methods: {
      getOfferPayoutList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          page: this.currentPage,
          pageSize: 20,
        };

        if (this.offerIds) {
          param.offerIds = this.offerIds;
        }
        getAffOfferPayoutList(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.offerPayoutList = response.result;
            this.total = response.total;
          } else {
            this.offerPayoutList = [];
            this.total = 0;
          }
        });
        this.offerIds = '';
      },
      addOfferPayoutClick() {
        this.offerPayout = Object.assign({}, defaultOfferPayout);
        this.offerPayoutVisible = true;
        this.offerPayoutDialogType = 'add';
      },
      updatePayoutClick(row) {
        this.offerPayout = { ...row };
        this.offerPayoutVisible = true;
        this.offerPayoutDialogType = 'edit';
      },
      async changeOfferPayout() {
        const param = { ...filterObject(this.offerPayout) };
        const text = this.offerPayoutDialogType === 'add' ? 'Add' : 'Update';
        try {
          let response = null;
          if (this.offerPayoutDialogType === 'add') {
            response = await addAffOfferPayout(this.affiliateId, param);
          } else {
            response = await updateAffOfferPayout(this.affiliateId, param);
          }

          if (response.code === 200) {
            this.$message({
              message: `${text} Success`,
              type: 'success',
            });
            this.getOfferPayoutList();
          } else {
            this.$message.error(`${text} Error:` + response.message);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.offerPayoutVisible = false;
        }
      },
      delPayoutClick(row) {
        this.delPayoutflag = true;
        this.delVisible = true;
        this.delId = row.offerId;
      },
      getAffSourcePayout() {
        let param = {
          page: this.currentPage,
          pageSize: 5,
        };
        if (this.pecentSourceIds) {
          param['sourceIds'] = this.pecentSourceIds;
        }
        getAffSourcePayoutList(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.sourcePayoutList = response.result;
            this.sourcePayoutTotal = response.total;
          } else {
            this.sourcePayoutList = [];
            this.sourcePayoutTotal = 0;
          }
        });
      },
      addSourcePecentClick() {
        this.sourcePayout = Object.assign({}, defaultSourcePayout);
        this.sourcePayoutVisible = true;
        this.sourcePayoutDialogType = 'add';
      },
      updateSourcePayoutClick(row) {
        this.sourcePayout = { ...row };
        this.sourcePayoutVisible = true;
        this.sourcePayoutDialogType = 'edit';
      },
      submitUpdateClick(row) {
        let param = {
          id: row.affSourcePecentId,
          pecent: row.pecent,
        };
        updateAffSourcePayout(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getAffSourcePayout();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },

      cancelEditClick(row) {
        row.edit = false;
        console.log(row);
      },

      delSourcepecentClick(row) {
        this.delVisible = true;
        this.delId = row.sourceId;
      },
      submitPecentUpdateClick(row) {
        let param = {
          data: {
            id: row.affPecentId,
            pecent: this.pecentValue,
          },
        };

        updateAffPayout(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getAffPayoutList();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      cancelPecentEditClick(row) {
        row.edit = false;
      },
      async changeSourcePayout() {
        const param = { ...filterObject(this.sourcePayout) };
        try {
          let response = null;
          if (this.sourcePayoutDialogType === 'add') {
            response = await addAffSourcePayout(this.affiliateId, param);
          } else {
            response = await updateAffSourcePayout(this.affiliateId, param);
          }
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getAffSourcePayout();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.sourcePayoutVisible = false;
        }
      },
      getAffPayoutList() {
        getAffPayoutList(this.affiliateId).then((response) => {
          if (response.code === 200) {
            this.payoutSettingList = response.result ? [response.result] : [];
          } else {
            this.payoutSettingList = [];
          }
        });
      },
      async changeAffPayout(type) {
        if (this.newPayoutPecentValue == '') {
          this.$message.error('please write pecent first.');
          return;
        }
        if (this.newPayoutPecentValue > 2) {
          this.$message.error('the pecent is so hight...');
          return;
        }
        try {
          let response = null;
          const text = type === 'add' ? 'Add' : 'Update';
          if (type === 'add') {
            response = await addAffPayout(this.affiliateId, this.newPayoutPecentValue);
          } else {
            response = await updateAffPayout(this.affiliateId, this.newPayoutPecentValue);
          }
          if (response.code === 200) {
            this.$message({
              message: `${text} Success`,
              type: 'success',
            });
            this.getAffPayoutList();
          } else {
            this.$message.error(`${text} Error:` + response.message);
          }
        } catch (error) {
          console.log(error);
        }
      },
      delAffPayoutClick() {
        this.delPecentflag = true;
        this.delVisible = true;
        this.delId = '';
      },
      delRow() {
        if (this.delPayoutflag) {
          deleteAffOfferPayout(this.affiliateId, this.delId).then((response) => {
            if (response.code === 200) {
              this.$message({
                message: 'Delete Success',
                type: 'success',
              });
              this.getOfferPayoutList();
            } else {
              this.$message.error('Delete Error:' + response.message);
            }
          });
        } else if (this.delPecentflag) {
          deleteAffPayout(this.affiliateId).then((response) => {
            if (response.code === 200) {
              this.$message({
                message: 'Delete Success',
                type: 'success',
              });
              this.getAffPayoutList();
            } else {
              this.$message.error('Delete Error:' + response.message);
            }
          });
        } else {
          deleteAffSourcePayout(this.affiliateId, this.delId).then((response) => {
            if (response.code === 200) {
              this.$message({
                message: 'Delete Success',
                type: 'success',
              });
              this.getAffSourcePayout();
            } else {
              this.$message.error('Delete Error:' + response.message);
            }
          });
        }
        this.delVisible = false;
        this.delId = null;
      },
      handlePayoutSizeChange(val) {
        this.pageSize = val;
        this.handlesPayoutCurrentChange(1);
      },
      handlesPayoutCurrentChange(val) {
        this.currentPage = val;
        this.getOfferPayoutList();
      },
      handlesourcePayoutSizeChange(val) {
        this.pageSize = val;
        this.handlesourcePayoutCurrentChange(1);
      },
      handlesourcePayoutCurrentChange(val) {
        this.currentPage = val;
        this.getAffSourcePayout();
      },
    },
  };
</script>

<style></style>
