<template>
  <div>
    <el-form :model="adjustRates" label-width="80px" size="mini">
      <el-row :gutter="10">
        <el-col :span="3">
          <el-form-item label="OfferId" label-width="60px">
            <el-input v-model="adjustRates.offerId"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="AffiliateSub" label-width="90px">
            <el-input v-model="adjustRates.affiliateSub"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="Prod" label-width="50px">
            <el-input v-model="adjustRates.prod"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="SourceId">
            <el-input v-model="adjustRates.sourceId"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="Rate" label-width="50px">
            <el-input v-model="adjustRates.rate"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="Description" label-width="90px">
            <el-input v-model="adjustRates.description"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="addAdjustClick">Add</el-button>
            <el-button type="primary" @click="delBatchAdjustClick">Delete</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="adjustRatedataList"
      @selection-change="handleadjustRateSelectionChange"
      highlight-current-row
      border
      stripe
      style="width:100%;"
      size="mini"
    >
      <el-table-column type="selection" width="55" align="center" fixed></el-table-column>
      <el-table-column label="OfferId" prop="offerId" align="center"></el-table-column>
      <el-table-column
        label="OfferName"
        width="200"
        prop="offerName"
        align="center"
      ></el-table-column>
      <el-table-column label="AffiliateId" min-width="90" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.affiliateId }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="AffiliateSub"
        prop="affiliateSub"
        min-width="100"
        align="center"
      ></el-table-column>

      <el-table-column
        label="Prod"
        prop="prod"
        align="center"
        min-width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="SourceId"
        prop="sourceId"
        min-width="100"
        align="center"
      ></el-table-column>
      <el-table-column label="Rate" prop="rate" min-width="80" align="center"></el-table-column>
      <el-table-column
        label="Description"
        prop="description"
        min-width="170px"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="CreateTime"
        prop="createTime"
        min-width="170"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="adjustRateListTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="deladjustRateVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deladjustRateVisible = false">cancel</el-button>
        <el-button type="primary" @click="delAdjustRateRow">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAdjustRateList, addAdjustRate, deleteAdjustRate } from 'api/affiliate/detail';

  import Pagination from '@/components/pagination';
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        adjustRates: {
          affiliateSub: null,
          offerId: null,
          prod: null,
          sourceId: null,
          rate: null,
          description: null,
        },
        adjustRatedataList: [],
        deladjustRateVisible: false,
        adjustRateListTotal: 0,
        currentPage: 1,
        pageSize: 20,
        adjustRateSelectionList: [],
        delArr: [],
      };
    },
    mounted() {
      this.getAdjustRateList();
    },
    methods: {
      getAdjustRateList() {
        let param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getAdjustRateList(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.adjustRatedataList = response.result;
            this.adjustRateListTotal = response.total;
          }
        });
      },
      addAdjustClick() {
        const param = {
          ...this.adjustRates,
        };
        addAdjustRate(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Add Success',
              type: 'success',
            });
            this.getAdjustRateList();
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      delBatchAdjustClick() {
        const length = this.adjustRateSelectionList.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.deladjustRateVisible = true;
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.adjustRateSelectionList[i].id);
        }
      },
      delAdjustRateRow() {
        const ids = this.delArr.join(',');
        deleteAdjustRate(this.affiliateId, ids).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getAdjustRateList();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.deladjustRateVisible = false;
        this.delArr = [];
      },
      handleadjustRateSelectionChange(val) {
        this.adjustRateSelectionList = val;
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getAdjustRateList();
      },
    },
  };
</script>

<style></style>
