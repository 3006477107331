<template>
  <div>
    <el-form :model="sourceBlacklist" size="mini">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="SourceId" label-width="70px">
            <group-select
              v-model="sourceBlacklist.sourceId"
              :data-source="sourcesList"
              :loading="sourceLoading"
              clearable
              filterable
              class="w100"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Desc" label-width="60px">
            <el-input v-model="sourceBlacklist.description"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="addSourceBlacklist">Add</el-button>
            <el-button type="primary" @click="delClick">Delete</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form :model="filter" size="mini">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="SourceIds" label-width="70px">
            <group-select
              v-model="filter.sourceIds"
              :data-source="sourcesList"
              multiple
              reserve-keyword
              :loading="sourceLoading"
              clearable
              filterable
              class="w100"
              size="mini"
              placeholder="请选择"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="getSourceBlacklist(1)">Search</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="blackAffSourcesList"
      @selection-change="handleadSelectionChange"
      highlight-current-row
      border
      stripe
      style="width:100%;"
      size="mini"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="SourceId" prop="sourceId" align="center"></el-table-column>
      <el-table-column label="SourceName" prop="sourceName" align="center"></el-table-column>
      <el-table-column label="Desc" prop="description" align="center"></el-table-column>
      <el-table-column label="Operator" prop="operator" align="center"></el-table-column>
      <el-table-column label="Operate Time" prop="operateTime" align="center"></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <el-dialog title="Delete" :visible.sync="delVisible" width="300px" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">cancel</el-button>
        <el-button type="primary" @click="delSourceBlacklist">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import {
    getSourceBlacklist,
    addSourceBlacklist,
    deleteSourceBlacklist,
  } from 'api/affiliate/detail';

  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        sourceBlacklist: {},
        filter: {},
        blackAffSourcesList: [],
        total: 0,
        currentPage: 1,
        pageSize: 20,
        delVisible: false,
        selectionList: [],
        delArr: [],
      };
    },
    computed: {
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      this.getSourceIdsList();
      this.getSourceBlacklist();
    },
    methods: {
      ...mapActions('source', ['getSourceIdsList']),
      getSourceBlacklist(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.filter.sourceIds && this.filter.sourceIds.length > 0) {
          param.sourceIds = this.filter.sourceIds.join(',');
        }
        getSourceBlacklist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.blackAffSourcesList = response.result;
            this.total = response.total;
          }
        });
      },
      addSourceBlacklist() {
        const param = {
          ...this.sourceBlacklist,
        };
        addSourceBlacklist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message.success('Add Success');
            this.getSourceBlacklist();
            this.sourceBlacklist = {};
          } else {
            this.$message.error(response.message);
          }
        });
      },
      delClick() {
        const length = this.selectionList.length;
        if (length <= 0) {
          this.$message.warning('你还没有选择要删除的数据哦~');
          return;
        }
        this.delVisible = true;
        for (let i = 0; i < length; i++) {
          this.delArr.push(this.selectionList[i].sourceId);
        }
      },
      delSourceBlacklist() {
        const sourceIds = this.delArr.join(',');
        deleteSourceBlacklist(this.affiliateId, sourceIds).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.getSourceBlacklist();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
        this.delVisible = false;
        this.delArr = [];
      },
      handleadSelectionChange(val) {
        this.selectionList = val;
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getSourceBlacklist();
      },
    },
  };
</script>

<style></style>
