import { fetchPage, updateDelayReason } from '@/api/finance/financeOverdue';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { selectContractType, selectAdvLegalNameAll } from '@/api/finance/contract';
import { mapState, mapActions } from 'vuex';
import {
  getPermissionAllList,
  selectGroupBusiness,
  selectGroupAccount,
} from 'api/account/permission';
import { filterObject } from 'utils/utils';
import qs from 'querystring';

export default {
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
        dateRange: [],
        receiveRange: [],
        raisingRange: [],
        updateRange: [],
        showBusiness: true,
        delay: 'Y',
      },
      total: 0,
      postData: {},
      list: [],
      loading: {
        list: false,
        submitBtn: false,
      },
      editData: {
        id: 0,
        receivedDate: '',
        actualAmount: 0,
        commission: 0,
        tax: 0,
        delayReason: '',
        note: '',
      },
      option: {
        manager: [],
      },

      addData: {
        parentId: 0,
        receivedDate: '',
        actualAmount: 0,
        commission: 0,
        tax: 0,
        delayReason: '',
        note: '',
      },
      dialog: {
        visible: false,
        title: '编辑',
        type: '',
      },
      addDialog: {
        visible: false,
        title: '新增',
        type: '',
      },
      contractList: [],
      advLegalNameList: [],
      contracts: [],
      paymentList: [],
      bidAccountList: [],
      busineies: [],
      accounts: [],
      receiveStatusList: [
        {
          label: 'Y',
          value: 'Y',
        },
        {
          label: 'N',
          value: 'N',
        },
        {
          label: 'P',
          value: 'P',
        },
      ],
      businessTypes: [
        {
          label: 'overseas',
          value: 'overseas',
        },
        {
          label: 'domestic',
          value: 'domestic',
        },
      ],
      rules: {
        receivedDate: [{ required: true, message: 'receivedDate is null ', trigger: 'blur' }],
        actualAmount: [{ required: true, message: 'actualAmount is null', trigger: 'blur' }],
        commission: [{ required: true, message: 'commission is null', trigger: 'blur' }],
        tax: [{ required: true, message: 'tax is null', trigger: 'blur' }],
        note: [{ max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }],
        delayReason: [{ max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }],
      },
    };
  },
  mounted() {
    this.getDefaultTime();
    this.getList();
    this.getContractType();
    this.getBusiness();
    this.getSourceIdsList();
    this.selectAccountRoles();
    this.getAccounts();
    this.getAdvLegalNameList();
    this.managerList();
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    ...mapState('user', {
      accountRoles: (state) => state.roles,
    }),
    formatDate() {
      return (date) => {
        if (!date) {
          return ''; // 如果date为null或undefined，则返回空字符串
        }
        const d = new Date(date);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d
          .getDate()
          .toString()
          .padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    },
  },
  methods: {
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    // get roles
    ...mapActions('user', ['selectAccountRoles']),
    getDefaultTime() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 2); // 设置日期为上上月
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const formattedMonth = month < 10 ? `0${month}` : month;
      let ddr = `${year}-${formattedMonth}`;
      this.filterForm.dateRange = [ddr, ddr]; // 设置默认起始日期
    },
    getAdvLegalNameList() {
      selectAdvLegalNameAll().then((res) => {
        this.advLegalNameList = res.result;
      });
    },
    managerList() {
      getPermissionAllList().then((res) => {
        this.option.manager = res.result;
      });
    },
    getAccounts() {
      selectGroupAccount().then((res) => {
        this.accounts = res.result;
      });
    },
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      let param = this.handleParam();
      this.loading.list = true;
      fetchPage(param)
        .then((res) => {
          if (res.code === 200) {
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    handleParam() {
      let param = { ...this.filterForm };
      let rangeTime = this.filterForm.dateRange;
      if (Array.isArray(rangeTime) && rangeTime.length > 0) {
        param.startTime = this.filterForm.dateRange[0];
        param.endTime = this.filterForm.dateRange[1];
        // 删除多余的属性
        delete param.dateRange;
      }
      let receiveTime = this.filterForm.receiveRange;
      if (Array.isArray(receiveTime) && receiveTime.length > 0) {
        param.startReceive = this.filterForm.receiveRange[0];
        param.endReceive = this.filterForm.receiveRange[1];
        // 删除多余的属性
        delete param.receiveRange;
      }
      let raisingRange = this.filterForm.raisingRange;
      if (Array.isArray(raisingRange) && raisingRange.length > 0) {
        param.startRaisingTime = this.filterForm.raisingRange[0];
        param.endRaisingTime = this.filterForm.raisingRange[1];
        // 删除多余的属性
        delete param.raisingRange;
      }
      let updateRange = this.filterForm.updateRange;
      if (Array.isArray(updateRange) && updateRange.length > 0) {
        param.startUpdateTime = this.filterForm.updateRange[0];
        param.endUpdateTime = this.filterForm.updateRange[1];
        // 删除多余的属性
        delete param.updateRange;
      }
      if (this.filterForm.paymentList) {
        param.paymentList = this.filterForm.paymentList.toString();
      }
      if (this.filterForm.receiveStatusList) {
        param.receiveStatusList = this.filterForm.receiveStatusList.toString();
      }
      if (this.filterForm.businessList) {
        param.businessList = this.filterForm.businessList.toString();
      }
      if (this.filterForm.sourceIdList) {
        param.sourceList = this.filterForm.sourceIdList.toString();
        delete param.sourceIdList;
      }
      if (this.filterForm.createByList) {
        param.createByList = this.filterForm.createByList.toString();
      }
      this.loading.list = true;
      return param;
    },
    getBusiness() {
      selectGroupBusiness().then((res) => {
        // let values = res.result.map((item) => item.username);
        this.busineies = res.result;
        console.log(res.result);
      });
    },
    // 下载excel表格
    downloadFie() {
      this.$confirm('最多下载10万条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          let param = this.handleParam();
          param = filterObject(param);
          let strParam = qs.stringify(param);
          let url = '/api/finance/overdue/download?' + strParam;
          console.log(url);
          window.open(url, '_blank');
          this.loading.list = false;
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: error.message,
          });
        });
    },

    // 打开弹窗
    openDialog(row) {
      // this.editData = { ...row };
      this.editData.id = row.id;
      this.editData.receivedDate = row.receivedDate;
      this.editData.actualAmount = row.actualAmount;
      this.editData.amount = row.amount;
      this.editData.commission = row.commission;
      this.editData.tax = row.tax;
      this.editData.amount = row.amount;
      this.editData.note = row.note;
      this.editData.delayReason = row.delayReason;
      this.editData.paymentCycle = row.paymentCycle;
      this.editData.invoiceRaisingDate = row.invoiceRaisingDate;
      this.editData.parentId = row.parentId;
      this.dialog.visible = true;
    },
    // 打开弹窗
    openAddDialog(row) {
      if (this.$refs.addForm !== undefined) this.$refs.addForm.resetFields();
      this.addData.parentId = row.id;
      this.addData.sourceId = row.sourceId;
      this.addData.invoice = row.invoice;
      this.addData.financeCycle = row.financeCycle;
      this.addData.paymentCycle = row.paymentCycle;
      this.addData.invoiceRaisingDate = row.invoiceRaisingDate;
      this.addDialog.visible = true;
    },
    // 排序监听
    sortChange(column) {
      if (column.prop !== null && column.order !== null) {
        this.filterForm.sorting = column.prop;
        this.filterForm.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      }
      this.getList();
    },
    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
    computeActualRevenue(row) {
      return (row.actualAmount || 0) + (row.commission || 0) + (row.tax || 0);
    },
    computeUnReveice(row) {
      let actualRevenue = this.computeActualRevenue(row);
      return ((row.amount || 0) - actualRevenue).toFixed(4);
    },
    formatReceiveStatus(row) {
      let actualRevenue = (row.actualAmount || 0) + (row.commission || 0) + (row.tax || 0);
      if (actualRevenue === row.amount) {
        return 'Y';
      } else if (actualRevenue === 0) {
        return 'N';
      } else if (actualRevenue < row.amount) {
        return 'p';
      } else {
        return 'N';
      }
    },
    openFile(url) {
      window.open(url, '_blank');
    },
    // 上传之前
    beforeUpload(file, row) {
      const isLt1M = file.size / 1024 / 1024 < 10;
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过10M!');
        return false;
      }
      console.log(row.id);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('id', row.id);
      return formData;
    },
    // 上传成功
    handleUploadSuccess(response) {
      if (response.result) {
        this.getList();
        this.$message('上传成功！');
      } else {
        this.$message.error('上传失败:' + response.message);
      }
    },
    showParent(row) {
      if (row.parentId) {
        return false;
      } else {
        return true;
      }
    },
    getContractType() {
      selectContractType().then((res) => {
        if (res.success === true) {
          this.contractList = res.result['Subject of contract'];
          this.contracts = res.result['Subject of contract'];
          this.paymentList = res.result['Account for payment'];
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 更新DelayReason
    updateDelayReason(id, delayReason) {
      this.$prompt('请输入Reason for Delay', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: delayReason,
        closeOnClickModal: false,
        inputValidator: (v) => {
          if (v == '') {
            return '不能为空！';
          }
        },
        inputErrorMessage: '',
      })
        .then(({ value }) => {
          updateDelayReason({
            id: id,
            delayReason: value,
          })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message);
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((e) => {
              this.loading.list = false;
              this.$message.error(e);
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          });
        });
    },
  },
};
