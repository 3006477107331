<template>
  <div>
    <el-form :model="offerBlacklist" size="mini">
      <el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="OfferIds" label-width="65px">
              <el-input v-model="offerBlacklist.offerIds"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Desc" label-width="50px">
              <el-input v-model="offerBlacklist.description"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="0px">
              <el-button type="primary" @click="addOfferBliacklist">Add</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
    </el-form>
    <el-form size="mini">
      <el-row class="row_line" :gutter="10">
        <el-col :span="12">
          <el-form-item
            label="OfferId(show last 30 records,if not found,pls Search)"
            label-width="350px"
          >
            <el-input v-model="filter.offerId"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="searchOfferBlacklist(1)">Search</el-button>
            <el-button type="primary" @click="delOfferBlacklistClick">Delete</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-table
      :data="offerBlacklists"
      highlight-current-row
      border
      stripe
      style="width:100%;"
      size="mini"
      @selection-change="handleSelectionChange"
    >
      >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="offerId" prop="offerId" align="center"></el-table-column>
      <el-table-column
        label="offerName"
        prop="offerName"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="status" prop="status" align="center"></el-table-column>
      <el-table-column label="Desc" prop="description" align="center"></el-table-column>
      <el-table-column label="Operator" prop="operator" align="center"></el-table-column>
      <el-table-column
        label="Operate Time"
        prop="operateTime"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
    <!-- del_Action模块框 -->
    <el-dialog title="Delete" width="40%" :visible.sync="delActionVisible" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delActionVisible = false">Cancel</el-button>
        <el-button type="primary" @click="delOfferBlacklist">Delete</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getOfferBlacklist, addOfferBlacklist, deleteOfferBlacklist } from 'api/affiliate/detail';
  import Pagination from '@/components/pagination';
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        filter: {},
        offerBlacklist: {},
        offerBlacklists: [],
        delArr: [],
        total: 0,
        currentPage: 1,
        pageSize: 20,
        delActionVisible: false,
      };
    },
    mounted() {
      this.searchOfferBlacklist();
    },
    methods: {
      searchOfferBlacklist(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.filter.offerId) {
          param.offerId = this.filter.offerId;
        }
        getOfferBlacklist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.offerBlacklists = response.result;
            this.total = response.total;
          }
        });
      },
      addOfferBliacklist() {
        const param = {
          ...this.offerBlacklist,
        };
        if (param.offerIds) {
          param.offerIds = param.offerIds.split(',');
        }
        addOfferBlacklist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message.success('Add Success');
            this.searchOfferBlacklist();
            this.offerBlacklist = {};
          } else {
            this.$message.error(response.message);
          }
        });
      },
      delOfferBlacklistClick() {
        if (this.delArr.length === 0) {
          this.$message.warning('你还没选择要删除的数据');
          return;
        }
        this.delActionVisible = true;
      },
      delOfferBlacklist() {
        let offerIds = '';
        if (this.delArr.length > 0) {
          offerIds = this.delArr.join(',');
        }
        deleteOfferBlacklist(this.affiliateId, offerIds).then((response) => {
          if (response.code === 200) {
            this.$message.success('Delete Success');
            this.searchOfferBlacklist();
            this.delActionVisible = false;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      handleSelectionChange(val) {
        this.delArr = val.map((item) => item.offerId);
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.searchOfferBlacklist();
      },
    },
  };
</script>

<style></style>
