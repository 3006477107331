<template>
  <div class="affiliateBilling">
    <div class="title">
      <h2>Postback Manage</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form v-model="postbackUrlCondition" :inline="true" label-width="100px">
              <el-row>
                <el-col :lg="8" :md="20">
                  <label class="labelName">postbackUrlId</label>
                  <el-form-item>
                    <el-input
                      v-model="postbackUrlCondition.urlId"
                      placeholder="eg : 123"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :md="20">
                  <label class="labelName">EIP</label>
                  <el-form-item>
                    <el-input
                      v-model="postbackUrlCondition.ipAddress"
                      placeholder="eg : 127.0.0.1"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :md="20">
                  <label class="labelName">postbackUrl</label>
                  <el-form-item>
                    <el-input
                      v-model="postbackUrlCondition.postbackUrl"
                      placeholder="eg : www.qq.com"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="20" :offset="1">
                  <el-form-item>
                    <el-button type="primary" @click="submitData(1)">Submit</el-button>
                    <el-button type="primary" @click="addClick()">Add</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              style="width:100%;"
              height="70vmin"
              stripe
              border
              ref="tableRef"
              highlight-current-row
              :data="postbackUrlList"
            >
              <el-table-column
                label="postbackUrlId"
                prop="urlId"
                key="urlId"
                min-width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                label="paramPrefix"
                prop="paramPrefix"
                key="paramPrefix"
                min-width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                label="ipAddress"
                prop="ipAddress"
                key="ipAddress"
                min-width="60"
                align="center"
              ></el-table-column>
              <el-table-column
                label="postbackUrl"
                prop="postbackUrl"
                key="postbackUrl"
                min-width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                label="updateBy"
                prop="updateBy"
                key="updateBy"
                min-width="60"
                align="center"
              ></el-table-column>
              <el-table-column
                label="updateTime"
                prop="updateTime"
                key="updateTime"
                min-width="100"
                align="center"
              ></el-table-column>
              <el-table-column label="urlStatus" min-width="60" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.urlStatus == null">未知</span>
                  <span v-if="scope.row.urlStatus == 1">正常</span>
                  <span v-if="scope.row.urlStatus == 2">异常</span>
                </template>
              </el-table-column>
              <el-table-column label="Manage" align="center" min-width="180">
                <template slot-scope="scope">
                  <el-button type="primary" @click="editClick(scope.row)">update</el-button>
                  <el-button type="primary" @click="delAccountClick(scope.row)">delete</el-button>
                  <el-button type="primary" @click="pingPostbackUrl(scope.row)">test</el-button>
                </template>
              </el-table-column>
            </el-table>
            <pagination class="block pagePanel"></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 模块框 -->
    <el-dialog
      :title="dialogType === 'add' ? 'Add' : 'Update'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form
        v-if="dialogType === 'add'"
        label-position="left"
        label-width="120px"
        :model="postbackUrlEntity"
      >
        <el-row>
          <el-col :lg="14" :md="24">
            <el-form-item label="ParamPrefix">
              <el-input v-model="postbackUrlEntity.paramPrefix" placeholder></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="14" :md="24">
            <el-form-item label="EIP">
              <el-input v-model="postbackUrlEntity.ipAddress" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="16" :offset="1">
            <el-form-item label="PostbackUrl">
              <el-input v-model="postbackUrlEntity.postbackUrl" placeholder></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form
        v-if="dialogType === 'edit'"
        label-position="left"
        label-width="120px"
        :model="postbackUrlEntity"
      >
        <el-row>
          <el-col :lg="14" :md="24">
            <el-form-item label="postbackUrlId">
              <el-input disabled v-model="postbackUrlEntity.urlId" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="16" :offset="1">
            <el-form-item label="ParamPrefix">
              <el-input v-model="postbackUrlEntity.paramPrefix" placeholder></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="14" :md="24">
            <el-form-item label="EIP">
              <el-input v-model="postbackUrlEntity.ipAddress" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="16" :offset="1">
            <el-form-item label="PostbackUrl">
              <el-input v-model="postbackUrlEntity.postbackUrl" placeholder></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="newPostbackUrl">确定</el-button>
      </span>
    </el-dialog>
    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delVisible" width="300px" center append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">cancel</el-button>
        <el-button type="primary" @click="removePostbackUrl">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    queyPostbackUrlByPage,
    addPostBackUrl,
    updatePostBackUrl,
    deletePostBackUrl,
    testPostUrlStatus,
  } from 'api/manage/postbackurl';
  import Pagination from '@/components/pagination';
  // import dateUtils from '@/utils/dateutils.js';
  // import qs from 'querystring';
  import { filterObject } from 'utils/utils';
  import { mapActions } from 'vuex';
  import { cloneDeep } from 'lodash-es';
  const defaultPostbackUrl = {
    urlId: '',
    paramPrefix: '',
    ipAddress: '',
    postbackUrl: '',
  };
  export default {
    components: {
      Pagination,
    },
    data() {
      return {
        pageSize: 20,
        currentPage: 1,
        delVisible: false,
        listLoading: false,
        postbackUrlEntity: Object.assign({}, defaultPostbackUrl),
        total: null,
        id: null,
        maxUrlId: 1,
        dialogType: 'add',
        dialogVisible: false,
        postbackUrlCondition: {
          urlId: null,
          ipAddress: null,
          postbackUr: null,
        },
        postbackUrlList: [],
      };
    },
    mounted() {
      this.submitData(1);
    },
    methods: {
      ...mapActions('affiliate', ['queyPostbackUrlByPage']),
      getPostbackUrlCondition() {
        let param = {
          page: this.currentPage,
          pagesize: this.pageSize,
        };
        param = {
          ...this.postbackUrlCondition,
          ...param,
        };
        return param;
      },
      submitData(curPage) {
        this.listLoading = true;
        if (curPage) {
          this.currentPage = curPage;
        }
        let param = this.getPostbackUrlCondition();
        param = filterObject(param);
        queyPostbackUrlByPage(param).then((response) => {
          this.listLoading = false;
          if (response.code == 200) {
            this.postbackUrlList = response.result;
            if (this.postbackUrlList.length > 0) {
              this.maxUrlId = this.postbackUrlList[0].urlId + 1;
            }
            this.total = response.total || 0;
          } else {
            this.postbackUrlList = [];
            this.total = 0;
          }
        });
      },
      newPostbackUrl() {
        const params = { ...this.postbackUrlEntity };
        if (null == params.paramPrefix || params.paramPrefix.trim() == '') {
          this.$message.error('paramPrefix can not be empty');
          return;
        }
        if (null == params.ipAddress || params.ipAddress.trim() == '') {
          this.$message.error('ipAddress can not be empty');
          return;
        }
        if (null == params.postbackUrl || params.postbackUrl.trim() == '') {
          this.$message.error('postbackUrl can not be empty');
          return;
        }
        if (this.dialogType === 'add') {
          addPostBackUrl(params).then((response) => {
            if (response.code === 200) {
              this.$message({
                message: 'Add Success',
                type: 'success',
              });
              this.submitData(1);
              this.dialogVisible = false;
            } else {
              this.$message.error('Add Error:' + response.message);
            }
          });
        } else {
          updatePostBackUrl(params).then((response) => {
            if (response.code === 200) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.submitData(1);
              this.dialogVisible = false;
            } else {
              this.$message.error('Update Error:' + response.message);
            }
          });
        }
      },
      delAccountClick(row) {
        this.id = row.urlId;
        this.delVisible = true;
      },
      removePostbackUrl() {
        deletePostBackUrl(this.id).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.delVisible = false;
            this.submitData(1);
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      pingPostbackUrl(row) {
        const postbackUrlEntity = cloneDeep(row);
        testPostUrlStatus(postbackUrlEntity).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Test Success',
              type: 'success',
            });
            this.delVisible = false;
            this.submitData(1);
          } else {
            this.$message.error('Add Error:' + response.message);
          }
        });
      },
      addClick() {
        this.postbackUrlEntity = defaultPostbackUrl;
        this.postbackUrlEntity.urlId = this.maxUrlId;
        this.dialogType = 'add';
        this.dialogVisible = true;
      },
      editClick(row) {
        const postbackUrlEntity = cloneDeep(row);
        this.postbackUrlEntity = postbackUrlEntity;
        this.dialogType = 'edit';
        this.dialogVisible = true;
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.submitData();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
