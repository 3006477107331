<template>
  <div>
    <el-form :model="prodBlack" size="mini">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="ProdName" label-width="80px">
            <el-input v-model="prodBlack.prod"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="Desc" label-width="50px">
            <el-input v-model="prodBlack.desc"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="addProdBlacklist">Add</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form size="mini">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="Prod Ids" label-width="80px">
            <el-input v-model="prodIds" placeholder="Prod Ids"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="delProdClick">Delete</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form :model="filter" size="mini">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="ProdName" label-width="80px">
            <el-input v-model="filter.prod"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="getProdBlacklist(1)">Search</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="prodBlacklist"
      highlight-current-row
      border
      stripe
      style="width:100%;"
      size="mini"
    >
      <el-table-column label="ProdId" prop="prodId"></el-table-column>
      <el-table-column label="ProdName" prop="prod"></el-table-column>
      <el-table-column label="Desc" prop="desc"></el-table-column>
      <el-table-column label="Create" prop="createBy"></el-table-column>
      <el-table-column label="CreateTime" prop="createTime"></el-table-column>
      <el-table-column label="Option">
        <template slot-scope="scope">
          <el-button type="primary" @click="delClick(scope.row)">Delete</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- del_Action模块框 -->
    <el-dialog title="Delete" width="40%" :visible.sync="delActionVisible" append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delActionVisible = false">Cancel</el-button>
        <el-button type="primary" @click="delProdBlacklist">Delete</el-button>
      </span>
    </el-dialog>
    <pagination
      class="block pagePanel"
      :pageTotal="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :pageSize="pageSize"
      :currentPage="currentPage"
    ></pagination>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import { getProdBlacklist, addProdBlacklist, deleteProdBlacklist } from 'api/affiliate/detail';
  export default {
    components: {
      Pagination,
    },
    props: {
      affiliateId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        prodBlacklist: [],
        prodBlack: {},
        filter: {},
        total: 0,
        currentPage: 1,
        pageSize: 20,
        delActionVisible: false,
        prodIds: '',
      };
    },
    mounted() {
      this.getProdBlacklist();
    },
    methods: {
      getProdBlacklist(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (this.filter.prod) {
          param.prod = this.filter.prod;
        }
        getProdBlacklist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.prodBlacklist = response.result;
            this.total = response.total;
          }
        });
      },
      addProdBlacklist() {
        const param = {
          ...this.prodBlack,
        };
        addProdBlacklist(this.affiliateId, param).then((response) => {
          if (response.code === 200) {
            this.$message.success('Add Success');
            this.getProdBlacklist();
          } else {
            this.$message.error(response.message);
          }
        });
      },
      delClick(row) {
        this.delId = row.prodId;
        this.delActionVisible = true;
      },
      delProdClick() {
        if (!this.prodIds) {
          this.$message.warning('你还没有输入要删除的Prod Ids');
          return;
        }
        this.delActionVisible = true;
      },
      delProdBlacklist() {
        deleteProdBlacklist(this.affiliateId, this.prodIds).then((response) => {
          if (response.code === 200) {
            this.$message.success('Delete Success');
            this.getProdBlacklist();
            this.delActionVisible = false;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getProdBlacklist();
      },
    },
  };
</script>

<style></style>
